import { memo, useCallback, useEffect, useState } from 'react'
import {
  AtButton,
  AtButtonVariant,
  AtButtonState,
  AtButtonSize,
  AtControl,
} from '@curran-catalog/curran-atomic-library'

import { CustomizeFieldKey, RugBuilderActionTypes } from '@context/rug-builder'
import { useDebouncedCallback } from '@hooks/use-debounced-callback'
import { useForm } from '@hooks/use-form'
import { useRugBuilder } from '@hooks/use-rug-builder'
import { useRugBuilderPrices } from '@hooks/use-rug-builder-prices'
import { EditButton } from '@components/flooring-product-detail'
import { WeaveParametersSuccess } from '@services/prices'
import {
  CircularCmValues,
  CircularFtInValues,
  CircularRugSize,
  RectangularCmValues,
  RectangularFtInValues,
  RectangularRugSize,
  WallToWallRugSize,
  W2WImperialValues,
  W2WMetricValues,
} from '../customize-rug-types'
import { getSizeValue } from '../../order-rug-preview'
import { useRugTypeValidations } from '../customize-rug-types/hooks'
import { useValidatePreviewSteps } from './hooks'
import { getMeasureUnitImperialOptions, getMeasureUnitMetricOptions, MeasureUnitKey } from '@utils/measures'
import { CustomizeType } from 'types'

type RectangularRugSizeEditableProps = {
  onEdit: () => void
}

export const RectangularRugSizeEditable = ({ onEdit }: RectangularRugSizeEditableProps) => {
  const { state, dispatch } = useRugBuilder()
  const { customize, product, border } = state

  const { RectangularRugFtInSchema, RectangularRugCmSchema } = useRugTypeValidations()
  const { fetchRectangleAreaPrices } = useRugBuilderPrices()

  const [rectanglePricesLoading, setRectanglePricesLoading] = useState(false)
  const [rectanglePricesError, setRectanglePricesError] = useState<unknown>({})

  const debouncedHandler = useDebouncedCallback((name: string, value: string) => {
    dispatch({
      type: RugBuilderActionTypes.SET_CUSTOMIZE_SIZE_FIELDS,
      fieldKeyPayload: name as CustomizeFieldKey,
      fieldValuePayload: value,
    })
  }, 200)

  const initialFtInValues: RectangularFtInValues = {
    widthFt: customize.widthFt ?? '',
    widthIn: customize.widthIn ?? '',
    lengthFt: customize.lengthFt ?? '',
    lengthIn: customize.lengthIn ?? '',
  }

  const initialCmValues: RectangularCmValues = {
    widthCm: customize.widthCm ?? '',
    lengthCm: customize.lengthCm ?? '',
  }

  const cfMinSqFt =
    !!product && !!product.parameters && (product.parameters as WeaveParametersSuccess)['cf_min_sqft']
      ? (product.parameters as WeaveParametersSuccess)['cf_min_sqft'] ?? 0
      : 0

  const cfMaxSeams =
    !!product && !!product.parameters && (product.parameters as WeaveParametersSuccess)['cf_max_seams']
      ? (product.parameters as WeaveParametersSuccess)['cf_max_seams'] ?? 0
      : 0

  const rollWidth =
    !!product && !!product.parameters && (product.parameters as WeaveParametersSuccess)['roll_width']
      ? Number((product.parameters as WeaveParametersSuccess)['roll_width']) ?? 0
      : 0

  const submitHandler = async () =>
    fetchRectangleAreaPrices({
      weave: product?.CONTENTFUL_ID ?? '',
      isMetric: customize.isMetric,
      widthFt: Number(customize.widthFt ?? 0),
      widthIn: Number(customize.widthIn ?? 0),
      widthCm: Number(customize.widthCm ?? 0),
      lengthFt: Number(customize.lengthFt ?? 0),
      lengthIn: Number(customize.lengthIn ?? 0),
      lengthCm: Number(customize.lengthCm ?? 0),
      setLoading: setRectanglePricesLoading,
      setErrors: setRectanglePricesError,
      onSuccess: () => {
        if (border.borderMaterial) {
          dispatch({
            type: RugBuilderActionTypes.SET_BORDER_MATERIAL,
            borderMaterialPayload: undefined,
          })
        }

        dispatch({
          type: RugBuilderActionTypes.RESET_SUB_TOTAL_PRICE,
        })

        onEdit()
      },
      onFailure: () =>
        dispatch({
          type: RugBuilderActionTypes.HANDLE_API_ERRORS,
          hasApiErrorPayload: true,
        }),
    })

  const {
    errors: ftInErrors,
    handleBlur: ftInHandleBlur,
    handleChange: ftInHandleChange,
    handleSubmit: ftInHandleSubmit,
    values: ftInValues,
    resetValues: ftInResetValues,
  } = useForm(
    initialFtInValues,
    submitHandler,
    customize.isMetric ? undefined : RectangularRugFtInSchema({ cfMinSqFt, cfMaxSeams, rollWidth }),
    debouncedHandler,
  )

  const {
    errors: cmErrors,
    handleBlur: cmHandleBlur,
    handleChange: cmHandleChange,
    handleSubmit: cmHandleSubmit,
    values: cmValues,
    resetValues: cmResetValues,
  } = useForm(
    initialCmValues,
    submitHandler,
    customize.isMetric ? RectangularRugCmSchema({ cfMinSqFt, cfMaxSeams, rollWidth }) : undefined,
    debouncedHandler,
  )

  useEffect(() => {
    if (product) {
      cmResetValues()
      ftInResetValues()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product])

  return (
    <form onSubmit={customize.isMetric ? cmHandleSubmit : ftInHandleSubmit}>
      <RectangularRugSize
        isPreviewConfirm
        ftInValues={ftInValues}
        cmValues={cmValues}
        ftInErrors={ftInErrors}
        cmErrors={cmErrors}
        ftInHandleBlur={ftInHandleBlur}
        cmHandleBlur={cmHandleBlur}
        ftInHandleChange={ftInHandleChange}
        cmHandleChange={cmHandleChange}
        rectanglePricesLoading={rectanglePricesLoading}
        rectanglePricesError={rectanglePricesError}
      />

      <div className="py-4">
        <AtButton
          type="submit"
          variant={AtButtonVariant.SECONDARY}
          size={AtButtonSize.COMPACT}
          label={rectanglePricesLoading ? 'Loading' : 'Update'}
          icon={rectanglePricesLoading ? { type: 'spinner' } : undefined}
          disabled={rectanglePricesLoading}
          state={rectanglePricesLoading ? AtButtonState.DISABLED : AtButtonState.ENABLED}
        />
      </div>
    </form>
  )
}

type CircularRugSizeEditableProps = {
  onEdit: () => void
}

export const CircularRugSizeEditable = ({ onEdit }: CircularRugSizeEditableProps) => {
  const { state, dispatch } = useRugBuilder()
  const { customize, product, border } = state

  const { fetchCircleAreaPrices } = useRugBuilderPrices()
  const { CircularRugFtInSchema, CircularRugCmSchema } = useRugTypeValidations()

  const debouncedHandler = useDebouncedCallback((name: string, value: string) => {
    dispatch({
      type: RugBuilderActionTypes.SET_CUSTOMIZE_SIZE_FIELDS,
      fieldKeyPayload: name as CustomizeFieldKey,
      fieldValuePayload: value,
    })
  }, 200)

  const initialFtInValues: CircularFtInValues = {
    diameterFt: customize.diameterFt ?? '',
    diameterIn: customize.diameterIn ?? '',
  }

  const initialCmValues: CircularCmValues = {
    diameterCm: customize.diameterCm ?? '',
  }

  const cfMinDiameter =
    !!product && !!product.parameters && (product.parameters as WeaveParametersSuccess)['cf_min_diameter']
      ? (product.parameters as WeaveParametersSuccess)['cf_min_diameter'] ?? 0
      : 0

  const cfMaxSeams =
    !!product && !!product.parameters && (product.parameters as WeaveParametersSuccess)['cf_max_seams']
      ? (product.parameters as WeaveParametersSuccess)['cf_max_seams'] ?? 0
      : 0

  const rollWidth =
    !!product && !!product.parameters && (product.parameters as WeaveParametersSuccess)['roll_width']
      ? Number((product.parameters as WeaveParametersSuccess)['roll_width']) ?? 0
      : 0

  const [circlePricesLoading, setCirclePricesLoading] = useState(false)
  const [circlePricesError, setCirclePricesError] = useState<unknown>({})

  const submitHandler = async () =>
    fetchCircleAreaPrices({
      weave: product?.CONTENTFUL_ID ?? '',
      isMetric: customize.isMetric,
      diameterFt: Number(customize.diameterFt ?? 0),
      diameterIn: Number(customize.diameterIn ?? 0),
      diameterCm: Number(customize.diameterCm ?? 0),
      setLoading: setCirclePricesLoading,
      setErrors: setCirclePricesError,
      onSuccess: () => {
        if (border.borderMaterial) {
          dispatch({
            type: RugBuilderActionTypes.SET_BORDER_MATERIAL,
            borderMaterialPayload: undefined,
          })
        }
        dispatch({
          type: RugBuilderActionTypes.RESET_SUB_TOTAL_PRICE,
        })

        onEdit()
      },
      onFailure: () =>
        dispatch({
          type: RugBuilderActionTypes.HANDLE_API_ERRORS,
          hasApiErrorPayload: true,
        }),
    })

  const {
    errors: ftInErrors,
    handleBlur: ftInHandleBlur,
    handleChange: ftInHandleChange,
    handleSubmit: ftInHandleSubmit,
    values: ftInValues,
  } = useForm(
    initialFtInValues,
    submitHandler,
    customize.isMetric ? undefined : CircularRugFtInSchema({ cfMinDiameter, cfMaxSeams, rollWidth }),
    debouncedHandler,
  )

  const {
    errors: cmErrors,
    handleBlur: cmHandleBlur,
    handleChange: cmHandleChange,
    handleSubmit: cmHandleSubmit,
    values: cmValues,
  } = useForm(
    initialCmValues,
    submitHandler,
    customize.isMetric ? CircularRugCmSchema({ cfMinDiameter, cfMaxSeams, rollWidth }) : undefined,
    debouncedHandler,
  )

  return (
    <form onSubmit={customize.isMetric ? cmHandleSubmit : ftInHandleSubmit}>
      <CircularRugSize
        isPreviewConfirm
        ftInValues={ftInValues}
        cmValues={cmValues}
        ftInErrors={ftInErrors}
        cmErrors={cmErrors}
        ftInHandleBlur={ftInHandleBlur}
        cmHandleBlur={cmHandleBlur}
        ftInHandleChange={ftInHandleChange}
        cmHandleChange={cmHandleChange}
        circlePricesLoading={circlePricesLoading}
        circlePricesError={circlePricesError}
      />

      <div className="py-4">
        <AtButton
          type="submit"
          variant={AtButtonVariant.SECONDARY}
          size={AtButtonSize.COMPACT}
          label={circlePricesLoading ? 'Loading' : 'Update'}
          icon={circlePricesLoading ? { type: 'spinner' } : undefined}
          disabled={circlePricesLoading}
          state={circlePricesLoading ? AtButtonState.DISABLED : AtButtonState.ENABLED}
        />
      </div>
    </form>
  )
}

interface WallToWallRugSizeEditableProps {
  onEdit: () => void
}

export const WallToWallRugSizeEditable = memo(({ onEdit }: WallToWallRugSizeEditableProps) => {
  const { state, dispatch } = useRugBuilder()
  const { customize, product } = state

  const { fetchWallToWallAreaPrices } = useRugBuilderPrices()
  const { WallToWallRugImperialSchema, WallToWallRugMetricSchema } = useRugTypeValidations()

  const debouncedHandler = useDebouncedCallback((name: string, value: string) => {
    dispatch({
      type: RugBuilderActionTypes.SET_CUSTOMIZE_SIZE_FIELDS,
      fieldKeyPayload: name as CustomizeFieldKey,
      fieldValuePayload: value,
    })
  }, 200)

  const initialImperialValues: W2WImperialValues = {
    sqyd: customize.sqyd ?? '',
    sqft: customize.sqft ?? '',
    lft: customize.lft ?? '',
    lftIn: customize.lftIn ?? '',
  }

  const initialMetricValues: W2WMetricValues = {
    sqm: customize.sqm ?? '',
    lm: customize.lm ?? '',
  }

  const rollWidth =
    !!product && !!product.parameters && (product.parameters as WeaveParametersSuccess)['roll_width']
      ? Number((product.parameters as WeaveParametersSuccess)['roll_width']) ?? 0
      : 0

  const blMinLength =
    !!product && !!product.parameters && (product.parameters as WeaveParametersSuccess)['bl_min_length']
      ? (product.parameters as WeaveParametersSuccess)['bl_min_length'] ?? 0
      : 0

  const [w2wPricesLoading, setW2WPricesLoading] = useState(false)
  const [w2wPricesError, setW2WPricesError] = useState<unknown>({})

  const submitHandler = async () => {
    return fetchWallToWallAreaPrices({
      weave: product?.CONTENTFUL_ID ?? '',
      isMetric: customize.isMetric,
      unitMeasure: customize.unitMeasure?.value as MeasureUnitKey,
      sqYd: Number(customize.sqyd ?? 0),
      sqFt: Number(customize.sqft),
      lft: Number(customize.lft),
      lftIn: Number(customize.lftIn ?? 0),
      lm: Number(customize.lm ?? 0),
      sqM: Number(customize.sqm ?? 0),
      setLoading: setW2WPricesLoading,
      setErrors: setW2WPricesError,
      onSuccess: () => {
        dispatch({
          type: RugBuilderActionTypes.SET_SUB_TOTAL_PRICE,
        })

        dispatch({
          type: RugBuilderActionTypes.HANDLE_CUSTOMIZE_IS_COMPLETE,
          isCompletePayload: true,
        })

        onEdit()
      },

      onFailure: () =>
        dispatch({
          type: RugBuilderActionTypes.HANDLE_API_ERRORS,
          hasApiErrorPayload: true,
        }),
    })
  }

  const {
    errors: imperialErrors,
    handleBlur: handleImperialBlur,
    handleChange: handleImperialChange,
    handleSubmit: handleImperialSubmit,
    values: imperialValues,
  } = useForm(
    initialImperialValues,
    submitHandler,
    customize.isMetric
      ? undefined
      : WallToWallRugImperialSchema({
          measureUnit: customize.unitMeasure?.value as MeasureUnitKey,
          blMinLength,
          rollWidth,
        }),
    debouncedHandler,
  )

  const {
    errors: metricErrors,
    handleBlur: handleMetricBlur,
    handleChange: handleMetricChange,
    handleSubmit: handleMetricSubmit,
    values: metricValues,
  } = useForm(
    initialMetricValues,
    submitHandler,
    customize.isMetric
      ? WallToWallRugMetricSchema({
          measureUnit: customize.unitMeasure?.value as MeasureUnitKey,
          blMinLength,
          rollWidth,
        })
      : undefined,
    debouncedHandler,
  )

  return (
    <form onSubmit={customize.isMetric ? handleMetricSubmit : handleImperialSubmit}>
      <WallToWallRugSize
        isPreviewConfirm
        rollWidth={rollWidth}
        imperialValues={imperialValues}
        metricValues={metricValues}
        imperialErrors={imperialErrors}
        metricErrors={metricErrors}
        handleImperialBlur={handleImperialBlur}
        handleMetricBlur={handleMetricBlur}
        handleImperialChange={handleImperialChange}
        handleMetricChange={handleMetricChange}
        w2wPricesLoading={w2wPricesLoading}
        w2wPricesError={w2wPricesError}
      />

      <div className="py-4">
        <AtButton
          type="submit"
          variant={AtButtonVariant.SECONDARY}
          size={AtButtonSize.COMPACT}
          label={w2wPricesLoading ? 'Loading' : 'Update'}
          icon={w2wPricesLoading ? { type: 'spinner' } : undefined}
          disabled={w2wPricesLoading}
          state={w2wPricesLoading ? AtButtonState.DISABLED : AtButtonState.ENABLED}
        />
      </div>
    </form>
  )
})

export const CustomizeRugSizesEditable = memo(() => {
  const { state, dispatch } = useRugBuilder()
  const { customize, product } = state

  const rollWidth =
    !!product && !!product.parameters && (product.parameters as WeaveParametersSuccess)['roll_width']
      ? Number((product.parameters as WeaveParametersSuccess)['roll_width']) ?? 0
      : 0

  const { isOpenCollapse, handleCollapse, openNextCollapse } = useValidatePreviewSteps({
    steps: ['border', 'addons', 'notes'],
    currentStep: 'size',
    nextStep: customize.type === CustomizeType.WALL_TO_WALL ? 'addons' : 'border',
  })

  const handleMetricChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({
        type: RugBuilderActionTypes.SET_CUSTOMIZE_IS_METRIC,
      })

      if (e.target.checked) {
        dispatch({
          type: RugBuilderActionTypes.SELECT_UNIT_MEASURE,
          selectOptionPayload: getMeasureUnitMetricOptions()[0],
        })
      } else {
        dispatch({
          type: RugBuilderActionTypes.SELECT_UNIT_MEASURE,
          selectOptionPayload: getMeasureUnitImperialOptions()[0],
        })
      }
    },
    [dispatch],
  )

  return (
    <div className="border-b border-b-outlined-gray">
      <div className="py-4">
        <div className="flex flex-row justify-between items-center">
          <p className="uppercase text-sm text-secondary">Size</p>
          <EditButton edit={isOpenCollapse} handleEdit={handleCollapse} />
        </div>
        <span className="font-medium text-lg">{getSizeValue({ customize, rollWidth })}</span>
      </div>

      <div className={isOpenCollapse ? 'block' : 'hidden'}>
        <div className="flex flex-col gap-4 py-4">
          <div className="w-full flex justify-end">
            <div className="w-28">
              <AtControl
                name="customizeRugSizesEditable"
                label="Metric"
                type="control"
                checked={customize.isMetric}
                onChange={handleMetricChange}
              />
            </div>
          </div>

          {customize.type === CustomizeType.RECTANGULAR && (
            <RectangularRugSizeEditable
              onEdit={() => {
                dispatch({
                  type: RugBuilderActionTypes.HANDLE_PREVIEW_CONFIRM_COLLAPSE,
                  keyPreviewConfirmPayload: 'isBorderCollapseComplete',
                  valuePreviewConfirmPayload: true,
                })
                handleCollapse()
                openNextCollapse()
              }}
            />
          )}
          {customize.type === CustomizeType.CIRCULAR && (
            <CircularRugSizeEditable
              onEdit={() => {
                dispatch({
                  type: RugBuilderActionTypes.HANDLE_PREVIEW_CONFIRM_COLLAPSE,
                  keyPreviewConfirmPayload: 'isBorderCollapseComplete',
                  valuePreviewConfirmPayload: true,
                })
                handleCollapse()
                openNextCollapse()
              }}
            />
          )}
          {customize.type === CustomizeType.WALL_TO_WALL && (
            <WallToWallRugSizeEditable
              onEdit={() => {
                dispatch({
                  type: RugBuilderActionTypes.HANDLE_PREVIEW_CONFIRM_COLLAPSE,
                  keyPreviewConfirmPayload: 'isAddonsCollapseComplete',
                  valuePreviewConfirmPayload: true,
                })
                handleCollapse()
                openNextCollapse()
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
})
