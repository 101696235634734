import { getSupportedProductType } from '@components/rug-builder/order-rug/steps/addons-sections'
import { Customize } from '@context/rug-builder'
import { ObjFlooringWeave, WeaveParametersResponse, WeaveStartingAtPriceResponse } from '@services/prices'

export const getFlooringSupplies = (
  product: ObjFlooringWeave & { parameters: WeaveParametersResponse; startingAtPrice: WeaveStartingAtPriceResponse },
  customize: Customize,
) => {
  return product && product.addons && Array.isArray(product.addons) && product.addons.length > 0
    ? product.addons
        .map((addonOption) => {
          const validateAddonOption =
            Array.isArray(addonOption.supportedProductTypes) &&
            addonOption.supportedProductTypes.length > 0 &&
            addonOption.supportedProductTypes.some(
              (supportedRugType) => supportedRugType === getSupportedProductType(customize.type),
            )

          return validateAddonOption ? addonOption : null
        })
        .filter((filteredAddon) => !!filteredAddon)
    : []
}
