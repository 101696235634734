import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import {
  AtButton,
  AtButtonVariant,
  AtImage,
  MlFormField,
  MlRichText,
  OrModal,
} from '@curran-catalog/curran-atomic-library'

import { RugBuilderActionTypes } from '@context/rug-builder'
import { useRugBuilder } from '@hooks/use-rug-builder'
import { useRugBuilderTopScroll } from '@hooks/use-rug-builder-top-scroll'
import { formatPrice } from '@utils/price'
import { getBorderMaterialRichText } from '../border-sections/utils'
import { ContentfulObjFlooringRugpad, ContentType, OrderRugStepperIndexes, Status } from 'types'
import { RugPadResponse } from '@services/prices'

export const NoRugpadOptionID = 'NoRugpadOption'

const NoRugpadOption: ContentfulObjFlooringRugpad = {
  CONTENTFUL_ID: NoRugpadOptionID,
  name: 'None',
  CONTENT_TYPE: ContentType.OBJ_FLOORING_RUGPAD,
  UPDATED_AT: '',
  rugpadName: 'No rug pad',
  rugpadNameSlug: '',
  sku: '',
  status: Status.ACTIVE,
}

interface RugPadOptionsProps {
  rugpadOption: ContentfulObjFlooringRugpad
}

export const RugpadOptions = memo(({ rugpadOption }: RugPadOptionsProps) => {
  const { CONTENTFUL_ID, rugpadName, description, images } = rugpadOption

  const { state, dispatch } = useRugBuilder()
  const { activeStep, addons, border } = state

  const [isOpenModal, setIsOpenModal] = useState(false)

  const hasInfoButton = !!images && !!description

  const rugpadPrice = useMemo(
    () =>
      border.borderMaterial
        ? border.borderMaterial.borderMaterialPrice?.rugPads?.find(
            (rugpad) => rugpad?.reference === rugpadOption.CONTENTFUL_ID,
          ) ?? undefined
        : undefined,
    [border.borderMaterial, rugpadOption.CONTENTFUL_ID],
  )

  useEffect(() => {
    if (
      CONTENTFUL_ID === 'NoRugpadOption' &&
      !addons.rugpad &&
      (activeStep == OrderRugStepperIndexes.ADDONS || activeStep == OrderRugStepperIndexes.NOTES)
    ) {
      dispatch({
        type: RugBuilderActionTypes.SET_RUGPAD,
        rugpadPayload: { ...NoRugpadOption, rugpadPrice },
      })

      dispatch({
        type: RugBuilderActionTypes.HANDLE_ADDONS_IS_COMPLETE,
        isCompletePayload: true,
      })

      dispatch({
        type: RugBuilderActionTypes.SET_SUB_TOTAL_PRICE,
      })
    }
  }, [activeStep, CONTENTFUL_ID, addons.rugpad, dispatch, rugpadPrice])

  const handleChange = useCallback(
    (rugpadOptionSelected: ContentfulObjFlooringRugpad) => {
      if (activeStep === OrderRugStepperIndexes.ADDONS) {
        dispatch({
          type: RugBuilderActionTypes.HANDLE_ADDONS_IS_COMPLETE,
          isCompletePayload: true,
        })
      }
      dispatch({
        type: RugBuilderActionTypes.SET_RUGPAD,
        rugpadPayload: { ...rugpadOptionSelected, rugpadPrice },
      })

      dispatch({
        type: RugBuilderActionTypes.SET_SUB_TOTAL_PRICE,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeStep, addons.isComplete, dispatch, rugpadPrice],
  )

  return (
    <div className="flex flex-row gap-1">
      <OrModal isOpen={isOpenModal} title={`Rug Pad: ${rugpadName}`} handleCloseModal={() => setIsOpenModal(false)}>
        <div className="mt-4 w-full gap-4 flex flex-col">
          {images && <AtImage src={images[0].url} alt={`${rugpadName} image`} className="object-cover  w-full" />}
          <MlRichText text={getBorderMaterialRichText(description)} />
          <AtButton
            label="Close"
            className="w-full mt-2"
            variant={AtButtonVariant.SECONDARY}
            onClick={() => setIsOpenModal(false)}
          />
        </div>
      </OrModal>
      <MlFormField
        name={CONTENTFUL_ID}
        label={`${rugpadName} ${rugpadPrice ? `(${formatPrice(rugpadPrice.price)})` : ''}`}
        checked={
          CONTENTFUL_ID === addons.rugpad?.CONTENTFUL_ID || (CONTENTFUL_ID === 'NoRugpadOption' && !addons.rugpad)
        }
        helperMessage={description ? documentToPlainTextString(description) : ''}
        type="radio"
        value={rugpadOption.CONTENTFUL_ID}
        onChange={() => handleChange(rugpadOption)}
        hasInfoButton={hasInfoButton}
        onClickInfoHandler={() => setIsOpenModal(true)}
        // hasPopover={!!images && !!description}
        // popover={{
        //   title: rugpadName,
        //   description: { text: getBorderMaterialRichText(description) },
        //   image:
        //     images && images.length > 0
        //       ? { src: images[0].url, alt: `${rugpadName} image`, disableFadding: true }
        //       : undefined,
        //   price: rugpadPrice ? `+${formatPrice(rugpadPrice.price)}` : '',
        // }}
        // InfoButtonIcon="image"
      />
    </div>
  )
})

interface RugPadProps {
  isPreviewConfirm?: boolean
}

export const RugPad = memo(({ isPreviewConfirm = false }: RugPadProps) => {
  const { state, dispatch } = useRugBuilder()
  const { product, border, addons, activeStep } = state

  const addonsRef = useRef<HTMLDivElement>(null)

  useRugBuilderTopScroll({ ref: addonsRef, stepIndex: OrderRugStepperIndexes.ADDONS })

  const [includedRugpad, setIncludedRugpad] = useState<RugPadResponse | Record<string, never>>({})

  useEffect(() => {
    // Search for some included rugpad in the Area Pricing Response.
    if (border.borderMaterial && border.borderMaterial.borderMaterialPrice) {
      if (
        Array.isArray(border.borderMaterial.borderMaterialPrice.rugPads) &&
        border.borderMaterial.borderMaterialPrice.rugPads.length > 0
      ) {
        const foundBorderIncludedRugpad = border.borderMaterial.borderMaterialPrice.rugPads.find(
          (rugpad) => rugpad.included,
        )
        if (foundBorderIncludedRugpad) {
          setIncludedRugpad(foundBorderIncludedRugpad)
        }
      }
    }
  }, [border.borderMaterial])

  useEffect(() => {
    // Search for the included rugpad as a Contentful entry
    if (Object.keys(includedRugpad).length !== 0 && product) {
      // CF stands for Contentful
      const foundCFIncludedRugpad =
        product.rugpadGroup && Array.isArray(product.rugpadGroup.rugpads) && product.rugpadGroup.rugpads.length > 0
          ? product.rugpadGroup.rugpads.find((rugpad) => rugpad.CONTENTFUL_ID === includedRugpad.reference) ?? undefined
          : undefined

      const rugpadPriceIncluded = border.borderMaterial?.borderMaterialPrice?.rugPads?.find(
        (rugpad) => rugpad?.reference === includedRugpad.reference,
      )

      if (foundCFIncludedRugpad) {
        if (activeStep === OrderRugStepperIndexes.ADDONS) {
          dispatch({
            type: RugBuilderActionTypes.HANDLE_ADDONS_IS_COMPLETE,
            isCompletePayload: true,
          })
        }
        dispatch({
          type: RugBuilderActionTypes.SET_RUGPAD,
          rugpadPayload: { ...foundCFIncludedRugpad, rugpadPrice: rugpadPriceIncluded },
        })

        dispatch({
          type: RugBuilderActionTypes.SET_SUB_TOTAL_PRICE,
        })
      }
    }
  }, [activeStep, addons.isComplete, border.borderMaterial, dispatch, includedRugpad, product])

  return (
    <div className="py-4 flex flex-col gap-4">
      <h3 ref={addonsRef} className={isPreviewConfirm ? 'uppercase text-sm' : 'text-lg font-medium py-2'}>
        {Object.keys(includedRugpad).length === 0 ? 'Choose Rug Pad' : 'Rug Pad'}
      </h3>

      <div className="flex flex-col gap-6 flex-wrap">
        {Object.keys(includedRugpad).length === 0 ? (
          product &&
          product.rugpadGroup &&
          Array.isArray(product.rugpadGroup.rugpads) &&
          product.rugpadGroup.rugpads.length > 0 &&
          product.rugpadGroup.rugpads
            .map((pad) => ({
              ...pad,
              price: border.borderMaterial?.borderMaterialPrice?.rugPads?.find(
                (rugpad) => rugpad?.reference === pad.CONTENTFUL_ID,
              )?.price,
            }))
            .sort((a, b) => (b.price ?? 0) - (a.price ?? 0))
            .map((rugpadOption) => <RugpadOptions key={rugpadOption.CONTENTFUL_ID} rugpadOption={rugpadOption} />)
        ) : (
          <p>Your rug comes complete with a pad.</p>
        )}
        {Object.keys(includedRugpad).length === 0 && <RugpadOptions rugpadOption={NoRugpadOption} />}
      </div>
      <hr className="tw-text-outlined-gray mt-4" />
    </div>
  )
})
