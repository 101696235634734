import { memo, useCallback, useEffect, useState } from 'react'
import {
  AtButton,
  AtButtonVariant,
  AtImage,
  MlFormField,
  MlRichText,
  OrModal,
} from '@curran-catalog/curran-atomic-library'

import { RugBuilderActionTypes } from '@context/rug-builder'
import { useRugBuilder } from '@hooks/use-rug-builder'
import { BorderResponse } from '@services/prices'
import { getBorderMaterialRichText } from './utils'
import { ContentfulObjFlooringBorder, CustomizeType } from 'types'

interface BorderMateriaOptionsProps {
  borderItem: ContentfulObjFlooringBorder & { borderMaterialPrice?: BorderResponse }
  id: number
}

export const BorderMaterialOptions = ({ borderItem, id }: BorderMateriaOptionsProps) => {
  const { borderName, CONTENTFUL_ID, images, description } = borderItem
  const { state, dispatch } = useRugBuilder()
  const { border, subTotalPrice } = state
  const [showModalBorder, setShowModalBorder] = useState(false)

  useEffect(() => {
    if (id == 0 && !border.borderMaterial) {
      dispatch({
        type: RugBuilderActionTypes.HANDLE_BORDER_IS_COMPLETE,
        isCompletePayload: true,
      })
      dispatch({
        type: RugBuilderActionTypes.SET_BORDER_MATERIAL,
        borderMaterialPayload: borderItem,
      })

      dispatch({
        type: RugBuilderActionTypes.SET_BORDER_COLOR,
        borderColorPayload: undefined,
      })

      dispatch({
        type: RugBuilderActionTypes.SET_BORDER_WIDTH,
        borderWidthPayload: undefined,
      })

      dispatch({
        type: RugBuilderActionTypes.SET_CORNER_STYLE,
        cornerStylePayload: undefined,
      })
      dispatch({
        type: RugBuilderActionTypes.SET_SUB_TOTAL_PRICE,
      })
    }
  }, [border.borderMaterial, borderItem, dispatch, id, subTotalPrice])

  const handleChange = useCallback(
    (borderSelected: ContentfulObjFlooringBorder) => {
      dispatch({
        type: RugBuilderActionTypes.HANDLE_BORDER_IS_COMPLETE,
        isCompletePayload: true,
      })

      dispatch({
        type: RugBuilderActionTypes.SET_BORDER_MATERIAL,
        borderMaterialPayload: borderSelected,
      })

      dispatch({
        type: RugBuilderActionTypes.SET_BORDER_COLOR,
        borderColorPayload: undefined,
      })

      dispatch({
        type: RugBuilderActionTypes.SET_BORDER_WIDTH,
        borderWidthPayload: undefined,
      })

      dispatch({
        type: RugBuilderActionTypes.SET_CORNER_STYLE,
        cornerStylePayload: undefined,
      })
      dispatch({
        type: RugBuilderActionTypes.SET_SUB_TOTAL_PRICE,
      })
    },
    [dispatch],
  )

  const hasInfoButton = !!images && !!description

  return (
    <div className="flex flex-col md:flex-row gap-3">
      <OrModal
        isOpen={showModalBorder}
        title={`Border Material: ${borderName}`}
        handleCloseModal={() => setShowModalBorder(false)}
      >
        <div className="mt-4 w-full gap-4 flex flex-col">
          {images && <AtImage src={images[0].url} alt={`${borderName} image`} className="object-cover  w-full" />}
          <MlRichText text={getBorderMaterialRichText(description)} />
          <AtButton
            label="Close"
            className="w-full mt-2"
            variant={AtButtonVariant.SECONDARY}
            onClick={() => setShowModalBorder(false)}
          />
        </div>
      </OrModal>
      {CONTENTFUL_ID === border.borderMaterial?.CONTENTFUL_ID}
      <div className="flex flex-row gap-1">
        <MlFormField
          name={CONTENTFUL_ID}
          label={`${borderName}`}
          checked={CONTENTFUL_ID === border.borderMaterial?.CONTENTFUL_ID}
          type="radio"
          onChange={() => handleChange(borderItem)}
          hasInfoButton={hasInfoButton}
          onClickInfoHandler={() => setShowModalBorder(true)}
        />
      </div>
    </div>
  )
}

interface BorderMaterialProps {
  isPreviewConfirm?: boolean
}

export const BorderMaterial = memo(({ isPreviewConfirm = false }: BorderMaterialProps) => {
  const { state } = useRugBuilder()
  const { product, areaPrices, customize } = state

  return (
    <div className="py-4 flex flex-col gap-4">
      <h3 className={isPreviewConfirm ? 'uppercase text-sm' : 'text-lg font-medium py-2'}>
        {customize.type === CustomizeType.WALL_TO_WALL
          ? 'Border options do not apply to wall-to-wall carpet.'
          : 'Choose Border Material'}
      </h3>
      {product && product.borderGroup && product.borderGroup.description && !isPreviewConfirm && (
        <MlRichText text={product.borderGroup.description} />
      )}
      {customize.type !== CustomizeType.WALL_TO_WALL && (
        <div className="flex flex-col md:flex-row gap-6 flex-wrap">
          {product &&
            product.borderGroup &&
            Array.isArray(product.borderGroup.borders) &&
            product.borderGroup.borders.length > 0 &&
            product.borderGroup.status !== 'Inactive' &&
            product.borderGroup.borders
              .filter(
                (option) =>
                  option.status !== 'Inactive' &&
                  areaPrices?.borders.find((border) => border.reference === option.CONTENTFUL_ID),
              )
              .map((borderItem, id) => {
                const foundBorderPriceWithoutWidth =
                  areaPrices && product && product.CONTENTFUL_ID === areaPrices.reference
                    ? areaPrices?.borders?.find(
                        (border) => border?.reference === borderItem.CONTENTFUL_ID && !border.width,
                      ) ?? undefined
                    : undefined

                return (
                  <BorderMaterialOptions
                    key={borderItem.CONTENTFUL_ID}
                    borderItem={
                      foundBorderPriceWithoutWidth
                        ? { ...borderItem, borderMaterialPrice: foundBorderPriceWithoutWidth }
                        : borderItem
                    }
                    id={id}
                  />
                )
              })}
        </div>
      )}
    </div>
  )
})
