import { memo, useEffect, useMemo } from 'react'
import { AtButton, AtButtonState, AtButtonVariant, IconType } from '@curran-catalog/curran-atomic-library'

import { RugBuilderActionTypes } from '@context/rug-builder'
import { useRugBuilder } from '@hooks/use-rug-builder'
import { CircularRugType, RectangularRugType, WallToWallType } from './customize-rug-types'
import { CustomizeType, OrderRugStepperIndexes } from 'types'
import { getMeasureUnitImperialOptions, getMeasureUnitMetricOptions } from '@utils/measures'
import { useAuth } from '@hooks/use-auth'

const activeCustomizeTypeButton = (customizeTypeContext: CustomizeType, title: CustomizeType) => {
  return customizeTypeContext === title ? AtButtonVariant.SECONDARY : AtButtonVariant.TERTIARY
}

interface CustomizeButtonTypesProps {
  types: StepCustomizeType[]
  onClickHandler: (title: CustomizeType) => void
}

export const CustomizeButtonTypes = memo(({ types, onClickHandler }: CustomizeButtonTypesProps) => {
  const { state } = useRugBuilder()

  return (
    <div>
      <div className="">
        <div className="tw-hidden lg:flex flex-row gap-3 p-3 flex-wrap">
          {types.map(({ title, disabled, icon }, index) => (
            <AtButton
              key={index}
              label={title}
              variant={activeCustomizeTypeButton(state.customize.type, title)}
              onClick={() => onClickHandler(title)}
              disabled={disabled}
              state={disabled ? AtButtonState.DISABLED : AtButtonState.ENABLED}
              className="p-3 h-fit text-nowrap md:w-[200px]  xl:p-4 xl:w-[240px]"
              icon={{
                type: icon,
                size: 20,
                className: '!tw-size-[20px]',
              }}
            />
          ))}
        </div>
      </div>
      <div className="lg:tw-hidden">
        <div className=" flex flex-col gap-3 md:gap-6 p-3 flex-wrap">
          {types.map(({ title, disabled }, index) => (
            <AtButton
              key={index}
              label={title}
              variant={activeCustomizeTypeButton(state.customize.type, title)}
              onClick={() => onClickHandler(title)}
              disabled={disabled}
              state={disabled ? AtButtonState.DISABLED : AtButtonState.ENABLED}
              className="p-3 h-fit text-nowrap md:w-[200px]  xl:p-4 xl:w-[240px]"
            />
          ))}
        </div>
      </div>
    </div>
  )
})

export type StepCustomizeType = {
  title: CustomizeType
  component: JSX.Element | null
  disabled: boolean
  icon: IconType
}

export const OrderRugStepCustomize = memo(() => {
  const { state, dispatch } = useRugBuilder()
  const { activeStep, product, customize } = state
  const { user } = useAuth({})
  const types: StepCustomizeType[] = useMemo(
    () => [
      {
        title: CustomizeType.RECTANGULAR,
        component: <RectangularRugType />,
        disabled: false,
        icon: 'rectangle',
      },
      {
        title: CustomizeType.CIRCULAR,
        component: <CircularRugType />,
        disabled: !product?.rugBuilderCircleCheck ?? true,
        icon: 'circle',
      },
      {
        title: CustomizeType.WALL_TO_WALL,
        component: <WallToWallType />,
        disabled: !product?.rugBuilderWallToWallCheck ?? true,
        icon: 'wall-to-wall',
      },
    ],
    [product?.rugBuilderCircleCheck, product?.rugBuilderWallToWallCheck],
  )
  useEffect(() => {
    dispatch({
      type: RugBuilderActionTypes.SET_USER_TRADE_LEVEL,
      userTradeLevelPayload: user?.trade_level ?? '',
    })
  }, [dispatch, user, product])

  return (
    <div className={`flex flex-col gap-5 ${customize.type === CustomizeType.WALL_TO_WALL ? 'pb-[50px]' : ''}`}>
      <div className="flex flex-col gap-4">
        <h3 className="text-lg font-medium py-2">Choose Rug Type or Carpet</h3>
        <CustomizeButtonTypes
          types={types}
          onClickHandler={(title) => {
            if (customize.isComplete && activeStep === OrderRugStepperIndexes.CUSTOMIZE) {
              dispatch({
                type: RugBuilderActionTypes.HANDLE_CUSTOMIZE_IS_COMPLETE,
                isCompletePayload: false,
              })
            }
            if (customize.type !== title) {
              dispatch({
                type: RugBuilderActionTypes.SET_CUSTOMIZE_TYPE,
                customizeTypePayload: title,
              })

              if (title === CustomizeType.WALL_TO_WALL) {
                dispatch({
                  type: RugBuilderActionTypes.SELECT_UNIT_MEASURE,
                  selectOptionPayload: customize.isMetric
                    ? getMeasureUnitMetricOptions()[0]
                    : getMeasureUnitImperialOptions()[0],
                })
              }
            }
            dispatch({
              type: RugBuilderActionTypes.RESET_BORDER_STEP,
            })
            dispatch({
              type: RugBuilderActionTypes.RESET_NOTES_STEP,
            })
            dispatch({
              type: RugBuilderActionTypes.RESET_ADDONS_STEP,
            })
            dispatch({
              type: RugBuilderActionTypes.RESET_SUB_TOTAL_PRICE,
            })
            dispatch({
              type: RugBuilderActionTypes.RESET_CUSTOMIZE_SIZE_FIELDS,
            })
            dispatch({
              type: RugBuilderActionTypes.HANDLE_DIMENSION_ERROR,
              handleDimensionErrorPayload: true,
            })
          }}
        />
      </div>
      <hr className="text-outlined-gray" />
      {types.map((type, index) => (
        <div
          key={index}
          className={`${state.customize.type === type.title ? 'block opacity-100' : 'hidden opacity-0'}`}
        >
          {type.component}
        </div>
      ))}
    </div>
  )
})
