import { ChangeEvent, FocusEvent, Fragment, memo, useCallback, useEffect, useRef, useState } from 'react'
import { AxiosError } from 'axios'
import {
  AtButton,
  AtButtonVariant,
  AtControl,
  AtIcon,
  AtLink,
  MlFormField,
  MlPopover,
  MlRichText,
  OrModal,
} from '@curran-catalog/curran-atomic-library'
import { sortFn } from 'color-sorter'

import { CustomizeFieldKey, RugBuilderActionTypes } from '@context/rug-builder'
import { useRugBuilder } from '@hooks/use-rug-builder'
import { useRugBuilderPrices } from '@hooks/use-rug-builder-prices'
import { useDebouncedCallback } from '@hooks/use-debounced-callback'
import { useForm } from '@hooks/use-form'
import { WeaveParametersSuccess } from '@services/prices'
import { useRugTypeValidations } from './hooks'
import { CustomizeType, OrderRugStepperIndexes } from 'types'
import { ColorSlider } from '@components/flooring-product-detail/color-slider'

export type RectangularFtInValues = {
  widthFt: string
  widthIn: string
  lengthFt: string
  lengthIn: string
}

export type RectangularCmValues = {
  widthCm: string
  lengthCm: string
}

interface RectangularRugSizeProps {
  isPreviewConfirm?: boolean

  rectanglePricesLoading: boolean
  rectanglePricesError: unknown

  ftInValues: RectangularFtInValues
  cmValues: RectangularCmValues
  ftInErrors: Partial<Record<keyof RectangularFtInValues, string>>
  cmErrors: Partial<Record<keyof RectangularCmValues, string>>
  ftInHandleBlur: (event: FocusEvent<HTMLInputElement>) => void
  cmHandleBlur: (event: FocusEvent<HTMLInputElement>) => void
  ftInHandleChange: (event: ChangeEvent<HTMLInputElement>) => void
  cmHandleChange: (event: ChangeEvent<HTMLInputElement>) => void
}

export const RectangularRugSize = memo(
  ({
    isPreviewConfirm = false,
    rectanglePricesLoading,
    rectanglePricesError,
    ftInValues,
    cmValues,
    ftInErrors,
    cmErrors,
    cmHandleBlur,
    ftInHandleBlur,
    ftInHandleChange,
    cmHandleChange,
  }: RectangularRugSizeProps) => {
    const [isOpenModal, setIsOpenModal] = useState(false)
    const { state, dispatch } = useRugBuilder()
    const { activeStep, product, customize, border } = state
    const [isFocus, setIsFocus] = useState({
      widthCm: false,
      widthFt: false,
      widthIn: false,
      lengthCm: false,
      lengthFt: false,
      lengthIn: false,
    })

    const erpWeave: { [key: string]: string } = {}

    if (product?.erpWeaveAttributes) {
      product.erpWeaveAttributes.forEach((weaveAttribute) => {
        if (Object.keys(weaveAttribute)[0]) {
          erpWeave[Object.keys(weaveAttribute)[0]] = Object.values(weaveAttribute)[0]
        }
      })
    }

    const rollWidth = erpWeave.rollWidth ? erpWeave.rollWidth : null

    const resetBorderAddonsCollapses = useCallback(() => {
      if (activeStep === OrderRugStepperIndexes.PREVIEW_CONFIRM) {
        dispatch({
          type: RugBuilderActionTypes.HANDLE_PREVIEW_CONFIRM_COLLAPSE,
          keyPreviewConfirmPayload: 'isBorderCollapseComplete',
          valuePreviewConfirmPayload: false,
        })

        dispatch({
          type: RugBuilderActionTypes.HANDLE_PREVIEW_CONFIRM_COLLAPSE,
          keyPreviewConfirmPayload: 'isAddonsCollapseComplete',
          valuePreviewConfirmPayload: false,
        })

        dispatch({
          type: RugBuilderActionTypes.RESET_SUB_TOTAL_PRICE,
        })

        dispatch({
          type: RugBuilderActionTypes.RESET_PREVIEW_CONFIRM_BORDER_COLLAPSE,
          isCompletePayload: true,
        })

        dispatch({
          type: RugBuilderActionTypes.RESET_PREVIEW_CONFIRM_ADDONS_COLLAPSE,
          isCompletePayload: true,
        })
      }
    }, [activeStep, dispatch])

    return (
      <div className={`flex flex-col gap-4 ${isPreviewConfirm ? '-mt-10' : ''}`}>
        <OrModal
          isOpen={isOpenModal}
          title="Rugs weave direction and variances"
          handleCloseModal={() => setIsOpenModal(false)}
        >
          <div className="mt-4 w-full gap-4 flex flex-col">
            {product && product.rectangularDescription && <MlRichText text={product.rectangularDescription} />}

            <AtButton
              label="Close"
              className="w-full mt-2"
              variant={AtButtonVariant.SECONDARY}
              onClick={() => setIsOpenModal(false)}
            />
          </div>
        </OrModal>

        <div className={`flex ${!customize.isMetric ? 'flex-col sm:flex-row' : 'flex-row'} gap-6`}>
          <div className={!customize.isMetric ? 'flex flex-row gap-2 items-start' : 'block w-full'}>
            {customize.isMetric ? (
              <MlFormField
                type="number"
                name="widthCm"
                label="Width"
                disabled={rectanglePricesLoading}
                placeholder="0"
                isInputFocus={isFocus.widthCm}
                error={!!cmErrors.widthCm}
                errorMessage={cmErrors.widthCm}
                value={cmValues.widthCm}
                onChange={(e) => {
                  cmHandleChange(e)
                  if (border.borderMaterial) {
                    dispatch({
                      type: RugBuilderActionTypes.RESET_ADDONS_STEP,
                    })

                    dispatch({
                      type: RugBuilderActionTypes.RESET_BORDER_STEP,
                    })

                    dispatch({
                      type: RugBuilderActionTypes.RESET_SUB_TOTAL_PRICE,
                    })
                  }
                }}
                onFocus={() => setIsFocus({ ...isFocus, widthCm: true })}
                onBlur={(e) => {
                  resetBorderAddonsCollapses()
                  cmHandleBlur(e)
                  setIsFocus({ ...isFocus, widthCm: false })
                }}
                rightIcon={cmErrors.widthCm ? 'close-circle' : undefined}
                textDecoration="cm"
                onWheel={(event) => event.currentTarget.blur()}
              />
            ) : (
              <Fragment>
                <MlFormField
                  type="number"
                  name="widthFt"
                  label="Width"
                  isInputFocus={isFocus.widthFt}
                  disabled={rectanglePricesLoading}
                  placeholder="0"
                  error={!!ftInErrors.widthFt}
                  errorMessage={ftInErrors.widthFt}
                  value={ftInValues.widthFt}
                  onChange={(e) => {
                    if (border.borderMaterial) {
                      dispatch({
                        type: RugBuilderActionTypes.RESET_ADDONS_STEP,
                      })

                      dispatch({
                        type: RugBuilderActionTypes.RESET_BORDER_STEP,
                      })

                      dispatch({
                        type: RugBuilderActionTypes.RESET_SUB_TOTAL_PRICE,
                      })
                    }
                    ftInHandleChange(e)
                  }}
                  onFocus={() => setIsFocus({ ...isFocus, widthFt: true })}
                  onBlur={(e) => {
                    resetBorderAddonsCollapses()
                    ftInHandleBlur(e)
                    setIsFocus({ ...isFocus, widthFt: false })
                  }}
                  rightIcon={ftInErrors.widthFt ? 'close-circle' : undefined}
                  textDecoration="ft"
                  helperMessage={`${rollWidth ? `Max. width ${rollWidth}` : ''}`}
                  onWheel={(event) => event.currentTarget.blur()}
                />

                <MlFormField
                  type="number"
                  name="widthIn"
                  label="&nbsp;"
                  isInputFocus={isFocus.widthIn}
                  disabled={rectanglePricesLoading}
                  placeholder="0"
                  error={!!ftInErrors.widthIn}
                  errorMessage={ftInErrors.widthIn}
                  value={ftInValues.widthIn}
                  onChange={(e) => {
                    if (border.borderMaterial) {
                      dispatch({
                        type: RugBuilderActionTypes.RESET_ADDONS_STEP,
                      })

                      dispatch({
                        type: RugBuilderActionTypes.RESET_BORDER_STEP,
                      })
                    }
                    dispatch({
                      type: RugBuilderActionTypes.RESET_SUB_TOTAL_PRICE,
                    })
                    ftInHandleChange(e)
                  }}
                  onFocus={() => setIsFocus({ ...isFocus, widthIn: true })}
                  onBlur={(e) => {
                    resetBorderAddonsCollapses()
                    ftInHandleBlur(e)
                    setIsFocus({ ...isFocus, widthIn: false })
                  }}
                  rightIcon={ftInErrors.widthIn ? 'close-circle' : undefined}
                  textDecoration="in"
                  onWheel={(event) => event.currentTarget.blur()}
                />
              </Fragment>
            )}
          </div>

          <div className={!customize.isMetric ? 'flex flex-row gap-2 items-start' : 'block w-full'}>
            {customize.isMetric ? (
              <MlFormField
                name="lengthCm"
                disabled={rectanglePricesLoading}
                type="number"
                placeholder="0"
                label="Length"
                isInputFocus={isFocus.lengthCm}
                error={!!cmErrors.lengthCm}
                errorMessage={cmErrors.lengthCm}
                value={cmValues.lengthCm}
                onChange={(e) => {
                  if (border.borderMaterial) {
                    dispatch({
                      type: RugBuilderActionTypes.RESET_ADDONS_STEP,
                    })

                    dispatch({
                      type: RugBuilderActionTypes.RESET_BORDER_STEP,
                    })
                  }
                  dispatch({
                    type: RugBuilderActionTypes.RESET_SUB_TOTAL_PRICE,
                  })
                  cmHandleChange(e)
                }}
                onBlur={(e) => {
                  resetBorderAddonsCollapses()
                  cmHandleBlur(e)
                  setIsFocus({ ...isFocus, lengthCm: false })
                }}
                onFocus={() => setIsFocus({ ...isFocus, lengthCm: true })}
                rightIcon={cmErrors.lengthCm ? 'close-circle' : undefined}
                textDecoration="cm"
                onWheel={(event) => event.currentTarget.blur()}
              />
            ) : (
              <Fragment>
                <MlFormField
                  name="lengthFt"
                  placeholder="0"
                  type="number"
                  disabled={rectanglePricesLoading}
                  label="Length"
                  isInputFocus={isFocus.lengthFt}
                  error={!!ftInErrors.lengthFt}
                  errorMessage={ftInErrors.lengthFt}
                  value={ftInValues.lengthFt}
                  onChange={(e) => {
                    if (border.borderMaterial) {
                      dispatch({
                        type: RugBuilderActionTypes.RESET_ADDONS_STEP,
                      })

                      dispatch({
                        type: RugBuilderActionTypes.RESET_BORDER_STEP,
                      })
                    }
                    dispatch({
                      type: RugBuilderActionTypes.RESET_SUB_TOTAL_PRICE,
                    })
                    ftInHandleChange(e)
                  }}
                  onFocus={() => setIsFocus({ ...isFocus, lengthFt: true })}
                  onBlur={(e) => {
                    resetBorderAddonsCollapses()
                    ftInHandleBlur(e)
                    setIsFocus({ ...isFocus, lengthFt: false })
                  }}
                  rightIcon={ftInErrors.lengthFt ? 'close-circle' : undefined}
                  textDecoration="ft"
                  onWheel={(event) => event.currentTarget.blur()}
                />

                <MlFormField
                  type="number"
                  name="lengthIn"
                  label="&nbsp;"
                  disabled={rectanglePricesLoading}
                  placeholder="0"
                  isInputFocus={isFocus.lengthIn}
                  error={!!ftInErrors.lengthIn}
                  errorMessage={ftInErrors.lengthIn}
                  value={ftInValues.lengthIn}
                  onChange={(e) => {
                    if (border.borderMaterial) {
                      dispatch({
                        type: RugBuilderActionTypes.RESET_ADDONS_STEP,
                      })

                      dispatch({
                        type: RugBuilderActionTypes.RESET_BORDER_STEP,
                      })
                    }
                    dispatch({
                      type: RugBuilderActionTypes.RESET_SUB_TOTAL_PRICE,
                    })
                    ftInHandleChange(e)
                  }}
                  onFocus={() => setIsFocus({ ...isFocus, lengthIn: true })}
                  onBlur={(e) => {
                    resetBorderAddonsCollapses()
                    ftInHandleBlur(e)
                    setIsFocus({ ...isFocus, lengthIn: false })
                  }}
                  rightIcon={ftInErrors.lengthIn ? 'close-circle' : undefined}
                  textDecoration="in"
                  onWheel={(event) => event.currentTarget.blur()}
                />
              </Fragment>
            )}
          </div>
        </div>

        <div className="inline-flex w-fit mt-2">
          <AtLink
            icon={{
              type: 'info',
              size: 20,
            }}
            className="font-bold text-sm w-full"
            type="button"
            label="Learn more about weave directions, size, and variances"
            onClick={() => setIsOpenModal(true)}
          />
        </div>
        {!!rectanglePricesError && Object.keys(rectanglePricesError).length !== 0 && (
          <div className="bg-[#DC26261A] py-2 px-4 gap-2 inline-flex items-center w-fit rounded-full">
            <AtIcon type="info" color="error" />
            <span className="text-error text-sm">{(rectanglePricesError as AxiosError).message}</span>
          </div>
        )}
      </div>
    )
  },
)

export const RectangularRugType = () => {
  const { state, dispatch } = useRugBuilder()
  const { product, customize, customeStepFilled } = state

  const { fetchRectangleAreaPrices } = useRugBuilderPrices()
  const { RectangularRugFtInSchema, RectangularRugCmSchema } = useRugTypeValidations()

  useEffect(() => {
    if (customeStepFilled && customize.type === CustomizeType.RECTANGULAR) {
      form.current?.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customeStepFilled, customize.type])

  const debouncedHandler = useDebouncedCallback((name: string, value: string) => {
    dispatch({
      type: RugBuilderActionTypes.SET_CUSTOMIZE_SIZE_FIELDS,
      fieldKeyPayload: name as CustomizeFieldKey,
      fieldValuePayload: value,
    })
  }, 200)

  const initialFtInValues: RectangularFtInValues = {
    widthFt: customize.widthFt ?? '',
    widthIn: customize.widthIn ?? '',
    lengthFt: customize.lengthFt ?? '',
    lengthIn: customize.lengthIn ?? '',
  }

  const initialCmValues: RectangularCmValues = {
    widthCm: customize.widthCm ?? '',
    lengthCm: customize.lengthCm ?? '',
  }

  const cfMinSqFt =
    !!product && !!product.parameters && (product.parameters as WeaveParametersSuccess)['cf_min_sqft']
      ? (product.parameters as WeaveParametersSuccess)['cf_min_sqft'] ?? 0
      : 0

  const cfMaxSeams =
    !!product && !!product.parameters && (product.parameters as WeaveParametersSuccess)['cf_max_seams']
      ? (product.parameters as WeaveParametersSuccess)['cf_max_seams'] ?? 0
      : 0

  const rollWidth =
    !!product && !!product.parameters && (product.parameters as WeaveParametersSuccess)['roll_width']
      ? Number((product.parameters as WeaveParametersSuccess)['roll_width']) ?? 0
      : 0

  const [rectanglePricesLoading, setRectanglePricesLoading] = useState(false)
  const [rectanglePricesError, setRectanglePricesError] = useState<unknown>({})

  const submitHandler = async () => {
    return fetchRectangleAreaPrices({
      weave: product?.CONTENTFUL_ID ?? '',
      isMetric: customize.isMetric,
      widthFt: Number(customize.widthFt ?? 0),
      widthIn: Number(customize.widthIn ?? 0),
      widthCm: Number(customize.widthCm ?? 0),
      lengthFt: Number(customize.lengthFt ?? 0),
      lengthIn: Number(customize.lengthIn ?? 0),
      lengthCm: Number(customize.lengthCm ?? 0),
      setLoading: setRectanglePricesLoading,
      setErrors: (err) => {
        setRectanglePricesError(err)
        dispatch({
          type: RugBuilderActionTypes.CUSTOME_STEP_FILLED,
          isCustomeStepFilled: false,
        })
      },
      onSuccess: () => {
        dispatch({
          type: RugBuilderActionTypes.HANDLE_CUSTOMIZE_IS_COMPLETE,
          isCompletePayload: true,
        })
        dispatch({
          type: RugBuilderActionTypes.HANDLE_NEXT_STEP,
        })
      },
      onFailure: () => {
        dispatch({
          type: RugBuilderActionTypes.CUSTOME_STEP_FILLED,
          isCustomeStepFilled: false,
        })
        dispatch({
          type: RugBuilderActionTypes.HANDLE_API_ERRORS,
          hasApiErrorPayload: true,
        })
      },
    })
  }

  const {
    errors: ftInErrors,
    handleBlur: ftInHandleBlur,
    handleChange: ftInHandleChange,
    handleSubmit: ftInHandleSubmit,
    values: ftInValues,
    resetValues: ftInResetValues,
  } = useForm(
    initialFtInValues,
    submitHandler,
    customize.isMetric ? undefined : RectangularRugFtInSchema({ cfMinSqFt, cfMaxSeams, rollWidth }),
    debouncedHandler,
  )

  const {
    errors: cmErrors,
    handleBlur: cmHandleBlur,
    handleChange: cmHandleChange,
    handleSubmit: cmHandleSubmit,
    values: cmValues,
    resetValues: cmResetValues,
  } = useForm(
    initialCmValues,
    submitHandler,
    customize.isMetric ? RectangularRugCmSchema({ cfMinSqFt, cfMaxSeams, rollWidth }) : undefined,
    debouncedHandler,
  )

  const handleMetricChange = useCallback(() => {
    dispatch({
      type: RugBuilderActionTypes.RESET_CUSTOMIZE_SIZE_FIELDS,
    })
    cmResetValues()
    ftInResetValues()
    dispatch({
      type: RugBuilderActionTypes.SET_CUSTOMIZE_IS_METRIC,
    })
    dispatch({
      type: RugBuilderActionTypes.RESET_BORDER_STEP,
    })
    dispatch({
      type: RugBuilderActionTypes.RESET_NOTES_STEP,
    })
    dispatch({
      type: RugBuilderActionTypes.RESET_ADDONS_STEP,
    })
    dispatch({
      type: RugBuilderActionTypes.RESET_SUB_TOTAL_PRICE,
    })
    dispatch({
      type: RugBuilderActionTypes.HANDLE_DIMENSION_ERROR,
      handleDimensionErrorPayload: true,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useEffect(() => {
    cmResetValues()
    ftInResetValues()
    dispatch({
      type: RugBuilderActionTypes.HANDLE_DIMENSION_ERROR,
      handleDimensionErrorPayload: true,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customize.type])

  const form = useRef<HTMLFormElement>(null)

  useEffect(() => {
    if (product) {
      ftInResetValues()
      cmResetValues()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product])

  return (
    <form ref={form} onSubmit={customize.isMetric ? cmHandleSubmit : ftInHandleSubmit}>
      <div className="flex flex-col gap-4">
        <h3 className="text-lg font-medium py-2">
          Choose Weave Color{customize.weaveColor?.weaveColorName ? ': ' : ''}
          <span className="font-normal text-base">{customize.weaveColor?.weaveColorName ?? ''}</span>{' '}
        </h3>
        <ColorSlider
          weaveColors={
            product?.colorGroup?.weaveColors?.sort((a, b) =>
              sortFn(a.colorHex ?? '#000000', b.colorHex ?? '#000000'),
            ) ?? []
          }
          showSwatch
        />
        {/* we can keep this component like a reference */}
        {/* <WeaveColorOptions isLoading={rectanglePricesLoading} options={product?.colorGroup?.weaveColors ?? []} /> */}
      </div>

      <hr className="text-outlined-gray mb-0 mt-8" />
      <div className="flex flex-row justify-between py-4">
        <div className="flex flex-row">
          <h3 className="text-lg font-medium pr-1">Enter Size</h3>
          {product?.rollWidthTextInfo && (
            <MlPopover title="Additional Notes" description={product?.rollWidthTextInfo}>
              <AtIcon type="info" color="link" />
            </MlPopover>
          )}
        </div>
        <div className="w-28 self-center">
          <AtControl
            name="rectangularRugMetric"
            label="Metric"
            type="control"
            checked={customize.isMetric}
            onChange={handleMetricChange}
          />
        </div>
      </div>

      <RectangularRugSize
        ftInValues={ftInValues}
        cmValues={cmValues}
        ftInErrors={ftInErrors}
        cmErrors={cmErrors}
        ftInHandleBlur={ftInHandleBlur}
        cmHandleBlur={cmHandleBlur}
        ftInHandleChange={ftInHandleChange}
        cmHandleChange={cmHandleChange}
        rectanglePricesLoading={rectanglePricesLoading}
        rectanglePricesError={rectanglePricesError}
      />
    </form>
  )
}
