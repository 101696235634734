import { memo, useCallback, useMemo, useRef } from 'react'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import { MlSampleCard } from '@curran-catalog/curran-atomic-library'

import { RugBuilderActionTypes } from '@context/rug-builder'
import { useRugBuilder } from '@hooks/use-rug-builder'
import { useRugBuilderTopScroll } from '@hooks/use-rug-builder-top-scroll'
import { CustomizeType, FurniturePrices, OrderRugStepperIndexes, SupportedProductTypes } from 'types'
import { ObjAddonState } from '@context/furniture-product'
import { formatPrice } from '@utils/price'
interface SuppliesOptionsProps {
  addonOption: ObjAddonState
}

export const SuppliesOptions = memo(({ addonOption }: SuppliesOptionsProps) => {
  const { CONTENTFUL_ID, addonName, description, images, addonPrices } = addonOption

  const { state, dispatch } = useRugBuilder()
  const { activeStep, addons } = state

  // const handleChange = useCallback(
  //   ({ target }: ChangeEvent<HTMLInputElement>) =>
  //     (addonOptionSelected: ObjAddonState) => {
  //       const { checked } = target
  //       if (checked) {
  //         if (addons.isComplete && activeStep === OrderRugStepperIndexes.ADDONS) {
  //           dispatch({
  //             type: RugBuilderActionTypes.HANDLE_ADDONS_IS_COMPLETE,
  //             isCompletePayload: false,
  //           })
  //         }
  //         dispatch({
  //           type: RugBuilderActionTypes.SET_SUPPLY,
  //           supplyPayload: addonOptionSelected,
  //         })
  //       } else {
  //         dispatch({
  //           type: RugBuilderActionTypes.REMOVE_SUPPLY,
  //           supplyPayload: addonOptionSelected,
  //         })
  //       }

  //       dispatch({
  //         type: RugBuilderActionTypes.SET_SUB_TOTAL_PRICE,
  //       })
  //     },
  //   [activeStep, addons.isComplete, dispatch],
  // )

  const handleClick = useCallback(
    (checked: boolean) => (addonOptionSelected: ObjAddonState) => {
      if (checked) {
        if (activeStep === OrderRugStepperIndexes.ADDONS) {
          dispatch({
            type: RugBuilderActionTypes.HANDLE_ADDONS_IS_COMPLETE,
            isCompletePayload: true,
          })
        }
        dispatch({
          type: RugBuilderActionTypes.SET_SUPPLY,
          supplyPayload: addonOptionSelected,
        })
      } else {
        dispatch({
          type: RugBuilderActionTypes.REMOVE_SUPPLY,
          supplyPayload: addonOptionSelected,
        })
      }

      dispatch({
        type: RugBuilderActionTypes.SET_SUB_TOTAL_PRICE,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeStep, addons.isComplete, dispatch],
  )

  // const hasInfoButton = !!description

  return (
    <div className="flex flex-row gap-1">
      <MlSampleCard
        id={CONTENTFUL_ID}
        title={addonName}
        titleClassName="!mb-0"
        subtitle={description ? documentToPlainTextString(description) : ''}
        subtitleClassName="!mb-auto"
        description={
          addonPrices &&
          Array.isArray((addonPrices as FurniturePrices).products) &&
          (addonPrices as FurniturePrices).products.length > 0
            ? `+${formatPrice((addonPrices as FurniturePrices).products[0].price ?? 0)}`
            : ''
        }
        descriptionClassName="font-bold !font-sm"
        image={
          images && images.length > 0
            ? { src: images[0].url, alt: `${addonName} image` }
            : { src: '', alt: `${addonName} image` }
        }
        imageClassName="!w-[184px] !h-[160px] object-cover"
        onClick={async () =>
          handleClick(!addons.supplies.find((supply) => supply.CONTENTFUL_ID === CONTENTFUL_ID))(addonOption)
        }
        className="!w-[200px]"
        isAddedToCart={!!addons.supplies.find((supply) => supply.CONTENTFUL_ID === CONTENTFUL_ID)}
      />

      {/* <MlFormField
        name={CONTENTFUL_ID}
        label={`${addonName} ${
          addonPrices &&
          Array.isArray((addonPrices as FurniturePrices).products) &&
          (addonPrices as FurniturePrices).products.length > 0
            ? `(${formatPrice((addonPrices as FurniturePrices).products[0].price ?? 0)})`
            : ''
        }`}
        helperMessage={description ? documentToPlainTextString(description) : ''}
        type="checkbox"
        checked={!!addons.supplies.find((supply) => supply.CONTENTFUL_ID === CONTENTFUL_ID)}
        onChange={(e) => handleChange(e)(addonOption)}
        hasInfoButton={hasInfoButton}
        hasPopover={true}
        popover={{
          title: addonName,
          description: { text: getBorderMaterialRichText(description) },
          image:
            images && images.length > 0
              ? { src: images[0].url, alt: `${addonName} image`, disableFadding: true }
              : undefined,
          price:
            addonPrices &&
            Array.isArray((addonPrices as FurniturePrices).products) &&
            (addonPrices as FurniturePrices).products.length > 0
              ? `+${formatPrice((addonPrices as FurniturePrices).products[0].price ?? 0)}`
              : '',
        }}
        InfoButtonIcon="image"
      /> */}
    </div>
  )
})

export const getSupportedProductType = (type: CustomizeType) => {
  const typeMap = new Map([
    [CustomizeType.RECTANGULAR, SupportedProductTypes.RECTANGULAR],
    [CustomizeType.CIRCULAR, SupportedProductTypes.CIRCULAR],
  ])

  return typeMap.get(type) || SupportedProductTypes.WALL_TO_WALL
}

interface SuppliesProps {
  isPreviewConfirm?: boolean
}

export const Supplies = ({ isPreviewConfirm = false }: SuppliesProps) => {
  const { state } = useRugBuilder()
  const { customize, product } = state

  const supplies = useMemo(
    () =>
      product && product.addons && Array.isArray(product.addons) && product.addons.length > 0
        ? product.addons
            .map((addonOption) => {
              // This to hide the addon if this not have prices
              if (!addonOption.addonPrices) return null

              const validateAddonOption =
                Array.isArray(addonOption.supportedProductTypes) &&
                addonOption.supportedProductTypes.length > 0 &&
                addonOption.supportedProductTypes.some(
                  (supportedRugType) => supportedRugType === getSupportedProductType(customize.type),
                )

              return validateAddonOption ? addonOption : null
            })
            .filter((filteredAddon) => !!filteredAddon)
        : [],
    [customize.type, product],
  )

  const addonsRef = useRef<HTMLDivElement>(null)

  useRugBuilderTopScroll({ ref: addonsRef, stepIndex: OrderRugStepperIndexes.ADDONS })

  return supplies.length > 0 ? (
    <div className="flex flex-col gap-4">
      <h3
        ref={customize.type === CustomizeType.WALL_TO_WALL ? addonsRef : null}
        className={isPreviewConfirm ? 'uppercase text-sm' : 'text-lg font-medium py-2'}
      >
        Choose Supplies
      </h3>
      <div className="flex flex-row gap-6 flex-wrap">
        {supplies.map((addonOption) =>
          addonOption ? <SuppliesOptions key={addonOption.CONTENTFUL_ID} addonOption={addonOption} /> : null,
        )}
      </div>
      <hr className="tw-text-outlined-gray mt-4" />
    </div>
  ) : null
}
