import { useRef } from 'react'

import { Border, Customize } from '@context/rug-builder'
import { useRugBuilder } from '@hooks/use-rug-builder'
import { useRugBuilderTopScroll } from '@hooks/use-rug-builder-top-scroll'
import { BorderColor, BorderMaterial, BorderWidth, CornerStyle } from './border-sections'
import { ContentfulObjFlooringBorderGroup, CustomizeType, OrderRugStepperIndexes } from 'types'

export const validateBorder = (
  customize: Customize,
  border: Border,
  borderGroup?: ContentfulObjFlooringBorderGroup,
) => {
  //Validate that borderGroup exists in Contentful
  if (borderGroup && Array.isArray(borderGroup.borders) && borderGroup.borders.length > 0) {
    // validate customer settings for w2w/broadloom
    if (customize.type === CustomizeType.WALL_TO_WALL) {
      // no border color, border width or corner style is required for w2w/broadloom
      return !border.borderMaterial && !border.borderColor && !border.borderWidth && !border.cornerStyle
    }
    // validate customer sections for area rugs
    else {
      // check Contentful if the border needs a border color selected
      const needsColorSelected =
        !!border.borderMaterial &&
        !!border.borderMaterial?.borderColorGroup &&
        Array.isArray(border.borderMaterial.borderColorGroup?.borderColors) &&
        border.borderMaterial.borderColorGroup?.borderColors.length > 0

      // check Contentful if the border needs a border width selected
      const needsBorderWidth =
        !!border.borderMaterial &&
        Array.isArray(border.borderMaterial.widths) &&
        border.borderMaterial.widths.length > 0

      // check if customer needs to see the corner style dialog (i.e. mitering settings dialog).
      // Only show corner styles if rug is rectangular and autometering is on
      // or if rug is rectangular, autometering is off, but the mitering options should be shown.
      const needsCornerStyle =
        !!border.borderMaterial &&
        (!!border.borderMaterial.automiter ||
          (!border.borderMaterial.automiter && !!border.borderMaterial.miteringOption)) &&
        customize.type === CustomizeType.RECTANGULAR

      if (!needsColorSelected) {
        // applies to serged borders: no color, border width or corner style is required
        return !!border.borderMaterial && !border.borderColor && !border.borderWidth && !border.cornerStyle
      } else {
        // if a border color is selected, validate that we have a border width and a corner style if so required.
        return (
          !!border.borderMaterial &&
          !!border.borderColor &&
          !!border.borderWidth == needsBorderWidth &&
          !!border.cornerStyle == needsCornerStyle
        )
      }
    }
  }
}

export const OrderRugStepBorder = () => {
  const { state } = useRugBuilder()
  const { activeStep } = state

  const borderRef = useRef<HTMLDivElement>(null)

  useRugBuilderTopScroll({ ref: borderRef, stepIndex: OrderRugStepperIndexes.BORDER })

  return (
    <div ref={borderRef} className="flex flex-col gap-5">
      {activeStep === OrderRugStepperIndexes.BORDER && (
        <>
          <BorderMaterial />
          <hr className="border-outlined-gray" />
          <BorderColor />
          <BorderWidth />
          {state.customize.type !== CustomizeType.CIRCULAR && <CornerStyle />}
        </>
      )}
    </div>
  )
}
