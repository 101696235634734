import { ChangeEvent, FocusEvent, Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { AxiosError } from 'axios'
import {
  AtButton,
  AtButtonVariant,
  AtControl,
  AtIcon,
  AtLink,
  MlFormField,
  MlRichText,
  OrModal,
} from '@curran-catalog/curran-atomic-library'

import { CustomizeFieldKey, RugBuilderActionTypes } from '@context/rug-builder'
import { useRugBuilder } from '@hooks/use-rug-builder'
import { useDebouncedCallback } from '@hooks/use-debounced-callback'
import { useForm } from '@hooks/use-form'
import { useRugBuilderPrices } from '@hooks/use-rug-builder-prices'
import { WeaveParametersSuccess } from '@services/prices'
import { useRugTypeValidations } from './hooks'
import { OrderRugControls } from '../../order-rug-controls'
import { CustomizeType, OrderRugStepperIndexes } from 'types'
import { hideOrderRugControls } from '../../order-rug-collapse'
import { ColorSlider } from '@components/flooring-product-detail/color-slider'
import { sortFn } from 'color-sorter'

export type CircularFtInValues = {
  diameterFt: string
  diameterIn: string
}

export type CircularCmValues = {
  diameterCm: string
}

interface CircularRugSizeProps {
  isPreviewConfirm?: boolean

  circlePricesLoading: boolean
  circlePricesError: unknown

  ftInValues: CircularFtInValues
  cmValues: CircularCmValues
  ftInErrors: Partial<Record<keyof CircularFtInValues, string>>
  cmErrors: Partial<Record<keyof CircularCmValues, string>>
  ftInHandleBlur: (event: FocusEvent<HTMLInputElement>) => void
  cmHandleBlur: (event: FocusEvent<HTMLInputElement>) => void
  ftInHandleChange: (event: ChangeEvent<HTMLInputElement>) => void
  cmHandleChange: (event: ChangeEvent<HTMLInputElement>) => void
}

export const CircularRugSize = ({
  isPreviewConfirm = false,
  circlePricesLoading,
  circlePricesError,
  ftInValues,
  cmValues,
  ftInErrors,
  cmErrors,
  cmHandleBlur,
  ftInHandleBlur,
  ftInHandleChange,
  cmHandleChange,
}: CircularRugSizeProps) => {
  const { state, dispatch } = useRugBuilder()
  const { activeStep, customize, product, border } = state
  const [isOpenModal, setIsOpenModal] = useState(false)

  const erpWeave: { [key: string]: string } = {}

  if (product?.erpWeaveAttributes) {
    product.erpWeaveAttributes.forEach((weaveAttribute) => {
      if (Object.keys(weaveAttribute)[0]) {
        erpWeave[Object.keys(weaveAttribute)[0]] = Object.values(weaveAttribute)[0]
      }
    })
  }

  const rollWidth = erpWeave.rollWidth ? erpWeave.rollWidth : null

  const [isFocus, setIsFocus] = useState({
    diameterCm: false,
    diameterFt: false,
    diameterIn: false,
  })
  const resetBorderAddonsCollapses = useCallback(() => {
    if (activeStep === OrderRugStepperIndexes.PREVIEW_CONFIRM) {
      dispatch({
        type: RugBuilderActionTypes.HANDLE_PREVIEW_CONFIRM_COLLAPSE,
        keyPreviewConfirmPayload: 'isBorderCollapseComplete',
        valuePreviewConfirmPayload: false,
      })

      dispatch({
        type: RugBuilderActionTypes.HANDLE_PREVIEW_CONFIRM_COLLAPSE,
        keyPreviewConfirmPayload: 'isAddonsCollapseComplete',
        valuePreviewConfirmPayload: false,
      })

      dispatch({
        type: RugBuilderActionTypes.RESET_PREVIEW_CONFIRM_BORDER_COLLAPSE,
        isCompletePayload: true,
      })

      dispatch({
        type: RugBuilderActionTypes.RESET_PREVIEW_CONFIRM_ADDONS_COLLAPSE,
        isCompletePayload: true,
      })

      dispatch({
        type: RugBuilderActionTypes.RESET_SUB_TOTAL_PRICE,
      })
    }
  }, [activeStep, dispatch])

  return (
    <div className={`flex flex-col gap-4 ${isPreviewConfirm ? '-mt-10' : ''}`}>
      <OrModal
        isOpen={isOpenModal}
        title="Rugs weave direction and variances"
        handleCloseModal={() => setIsOpenModal(false)}
      >
        <div className="mt-4 w-full gap-4 flex flex-col">
          {product && product.circularDescription && <MlRichText text={product.circularDescription} />}

          <AtButton
            label="Close"
            className="w-full mt-2"
            variant={AtButtonVariant.SECONDARY}
            onClick={() => setIsOpenModal(false)}
          />
        </div>
      </OrModal>
      <div className="flex flex-row gap-6">
        <div className={!customize.isMetric ? 'flex flex-row gap-2' : 'block w-full md:w-1/2'}>
          {customize.isMetric ? (
            <MlFormField
              type="number"
              placeholder="0"
              label="Diameter"
              disabled={circlePricesLoading}
              name="diameterCm"
              error={!!cmErrors.diameterCm}
              errorMessage={cmErrors.diameterCm}
              value={cmValues.diameterCm}
              isInputFocus={isFocus.diameterCm}
              onChange={(e) => {
                cmHandleChange(e)
                if (border.borderMaterial) {
                  dispatch({
                    type: RugBuilderActionTypes.RESET_ADDONS_STEP,
                  })

                  dispatch({
                    type: RugBuilderActionTypes.RESET_BORDER_STEP,
                  })
                }
                dispatch({
                  type: RugBuilderActionTypes.RESET_SUB_TOTAL_PRICE,
                })
              }}
              onBlur={(e) => {
                resetBorderAddonsCollapses()
                cmHandleBlur(e)
                setIsFocus({ ...isFocus, diameterCm: false })
              }}
              onFocus={() => setIsFocus({ ...isFocus, diameterCm: true })}
              rightIcon={cmErrors.diameterCm ? 'close-circle' : undefined}
              textDecoration="cm"
            />
          ) : (
            <Fragment>
              <MlFormField
                type="number"
                placeholder="0"
                label="Diameter"
                disabled={circlePricesLoading}
                name="diameterFt"
                isInputFocus={isFocus.diameterFt}
                error={!!ftInErrors.diameterFt}
                errorMessage={ftInErrors.diameterFt}
                value={ftInValues.diameterFt}
                onChange={(e) => {
                  if (border.borderMaterial) {
                    dispatch({
                      type: RugBuilderActionTypes.RESET_ADDONS_STEP,
                    })

                    dispatch({
                      type: RugBuilderActionTypes.RESET_BORDER_STEP,
                    })
                  }
                  dispatch({
                    type: RugBuilderActionTypes.RESET_SUB_TOTAL_PRICE,
                  })
                  ftInHandleChange(e)
                }}
                onFocus={() => setIsFocus({ ...isFocus, diameterFt: true })}
                onBlur={(e) => {
                  resetBorderAddonsCollapses()
                  ftInHandleBlur(e)
                  setIsFocus({ ...isFocus, diameterFt: false })
                }}
                rightIcon={ftInErrors.diameterFt ? 'close-circle' : undefined}
                textDecoration="ft"
                helperMessage={`${rollWidth ? `Max. width ${rollWidth}` : ''}`}
              />

              <MlFormField
                name="diameterIn"
                label="&nbsp;"
                disabled={circlePricesLoading}
                type="number"
                placeholder="0"
                isInputFocus={isFocus.diameterIn}
                error={!!ftInErrors.diameterIn}
                errorMessage={ftInErrors.diameterIn}
                value={ftInValues.diameterIn}
                // onChange={ftInHandleChange}
                onChange={(e) => {
                  ftInHandleChange(e)
                  if (border.borderMaterial) {
                    dispatch({
                      type: RugBuilderActionTypes.RESET_ADDONS_STEP,
                    })

                    dispatch({
                      type: RugBuilderActionTypes.RESET_BORDER_STEP,
                    })
                  }
                  dispatch({
                    type: RugBuilderActionTypes.RESET_SUB_TOTAL_PRICE,
                  })
                }}
                onBlur={(e) => {
                  resetBorderAddonsCollapses()
                  ftInHandleBlur(e)
                  setIsFocus({ ...isFocus, diameterIn: false })
                }}
                onFocus={() => setIsFocus({ ...isFocus, diameterIn: true })}
                rightIcon={ftInErrors.diameterIn ? 'close-circle' : undefined}
                textDecoration="in"
              />
            </Fragment>
          )}
        </div>
      </div>

      <div className="inline-flex w-fit mt-2">
        <AtLink
          icon={{
            type: 'info',
            size: 20,
          }}
          className="font-bold text-sm w-full"
          type="button"
          label="Learn more about weave directions, size, and variances"
          onClick={() => setIsOpenModal(true)}
        />
      </div>

      {!!circlePricesError && Object.keys(circlePricesError).length !== 0 && (
        <div className="bg-[#DC26261A] py-2 px-4 gap-2 inline-flex items-center w-fit rounded-full">
          <AtIcon type="info" color="error" />
          <span className="text-error text-sm ">{(circlePricesError as AxiosError).message}</span>
        </div>
      )}
    </div>
  )
}

export const CircularRugType = () => {
  const { state, dispatch } = useRugBuilder()
  const { customize, product, subTotalPrice, customeStepFilled } = state

  const { fetchCircleAreaPrices } = useRugBuilderPrices()
  const { CircularRugFtInSchema, CircularRugCmSchema } = useRugTypeValidations()

  useEffect(() => {
    if (customeStepFilled && customize.type === CustomizeType.CIRCULAR) {
      form.current?.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customeStepFilled, customize.type])

  const debouncedHandler = useDebouncedCallback((name: string, value: string) => {
    dispatch({
      type: RugBuilderActionTypes.SET_CUSTOMIZE_SIZE_FIELDS,
      fieldKeyPayload: name as CustomizeFieldKey,
      fieldValuePayload: value,
    })
  }, 200)

  const initialFtInValues: CircularFtInValues = {
    diameterFt: customize.diameterFt ?? '',
    diameterIn: customize.diameterIn ?? '',
  }

  const initialCmValues: CircularCmValues = {
    diameterCm: customize.diameterCm ?? '',
  }

  const cfMinDiameter =
    !!product && !!product.parameters && (product.parameters as WeaveParametersSuccess)['cf_min_diameter']
      ? (product.parameters as WeaveParametersSuccess)['cf_min_diameter'] ?? 0
      : 0

  const cfMaxSeams =
    !!product && !!product.parameters && (product.parameters as WeaveParametersSuccess)['cf_max_seams']
      ? (product.parameters as WeaveParametersSuccess)['cf_max_seams'] ?? 0
      : 0

  const rollWidth =
    !!product && !!product.parameters && (product.parameters as WeaveParametersSuccess)['roll_width']
      ? Number((product.parameters as WeaveParametersSuccess)['roll_width']) ?? 0
      : 0

  const [circlePricesLoading, setCirclePricesLoading] = useState<boolean>(false)
  const [circlePricesError, setCirclePricesError] = useState<unknown>({})

  const submitHandler = async () =>
    fetchCircleAreaPrices({
      weave: product?.CONTENTFUL_ID ?? '',
      isMetric: customize.isMetric,
      diameterFt: Number(customize.diameterFt ?? 0),
      diameterIn: Number(customize.diameterIn ?? 0),
      diameterCm: Number(customize.diameterCm ?? 0),
      setLoading: setCirclePricesLoading,
      setErrors: (err) => {
        setCirclePricesError(err)
        dispatch({
          type: RugBuilderActionTypes.CUSTOME_STEP_FILLED,
          isCustomeStepFilled: false,
        })
      },
      onSuccess: () => {
        dispatch({
          type: RugBuilderActionTypes.HANDLE_CUSTOMIZE_IS_COMPLETE,
          isCompletePayload: true,
        })

        dispatch({
          type: RugBuilderActionTypes.HANDLE_NEXT_STEP,
        })
      },
      onFailure: () => {
        dispatch({
          type: RugBuilderActionTypes.CUSTOME_STEP_FILLED,
          isCustomeStepFilled: false,
        })
        dispatch({
          type: RugBuilderActionTypes.HANDLE_API_ERRORS,
          hasApiErrorPayload: true,
        })
      },
    })

  const {
    errors: ftInErrors,
    handleBlur: ftInHandleBlur,
    handleChange: ftInHandleChange,
    handleSubmit: ftInHandleSubmit,
    values: ftInValues,
    resetValues: ftInResetValues,
  } = useForm(
    initialFtInValues,
    submitHandler,
    customize.isMetric ? undefined : CircularRugFtInSchema({ cfMinDiameter, cfMaxSeams, rollWidth }),
    debouncedHandler,
  )

  const {
    errors: cmErrors,
    handleBlur: cmHandleBlur,
    handleChange: cmHandleChange,
    handleSubmit: cmHandleSubmit,
    values: cmValues,
    resetValues: cmResetValues,
  } = useForm(
    initialCmValues,
    submitHandler,
    customize.isMetric ? CircularRugCmSchema({ cfMinDiameter, cfMaxSeams, rollWidth }) : undefined,
    debouncedHandler,
  )

  const handleMetricChange = useCallback(() => {
    dispatch({
      type: RugBuilderActionTypes.RESET_CUSTOMIZE_SIZE_FIELDS,
    })
    cmResetValues()
    ftInResetValues()
    dispatch({
      type: RugBuilderActionTypes.SET_CUSTOMIZE_IS_METRIC,
    })
    dispatch({
      type: RugBuilderActionTypes.RESET_BORDER_STEP,
    })
    dispatch({
      type: RugBuilderActionTypes.RESET_NOTES_STEP,
    })
    dispatch({
      type: RugBuilderActionTypes.RESET_ADDONS_STEP,
    })
    dispatch({
      type: RugBuilderActionTypes.RESET_SUB_TOTAL_PRICE,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useEffect(() => {
    cmResetValues()
    ftInResetValues()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customize.type])

  const form = useRef<HTMLFormElement>(null)

  useEffect(() => {
    if (product) {
      ftInResetValues()
      cmResetValues()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product])

  return (
    <form className="" ref={form} onSubmit={customize.isMetric ? cmHandleSubmit : ftInHandleSubmit}>
      <div className="flex flex-col gap-4">
        <h3 className="text-lg font-medium py-2">
          Choose Weave Color{customize.weaveColor?.weaveColorName ? ': ' : ''}
          <span className="font-normal text-base">{customize.weaveColor?.weaveColorName ?? ''}</span>{' '}
        </h3>
        <ColorSlider
          weaveColors={
            product?.colorGroup?.weaveColors?.sort((a, b) =>
              sortFn(a.colorHex ?? '#000000', b.colorHex ?? '#000000'),
            ) ?? []
          }
          showSwatch
        />
        {/* we can keep this component like a reference */}
        {/* <WeaveColorOptions isLoading={rectanglePricesLoading} options={product?.colorGroup?.weaveColors ?? []} /> */}
      </div>
      <hr className="text-outlined-gray mb-0 mt-8" />

      <div className="flex flex-row justify-between py-4">
        <h3 className="text-lg font-medium py-2">Enter Size</h3>
        <div className="w-28 self-center">
          <AtControl
            name="circular-rug-metric"
            label="Metric"
            type="control"
            checked={customize.isMetric}
            onChange={handleMetricChange}
          />
        </div>
      </div>

      <CircularRugSize
        ftInValues={ftInValues}
        cmValues={cmValues}
        ftInErrors={ftInErrors}
        cmErrors={cmErrors}
        ftInHandleBlur={ftInHandleBlur}
        cmHandleBlur={cmHandleBlur}
        ftInHandleChange={ftInHandleChange}
        cmHandleChange={cmHandleChange}
        circlePricesLoading={circlePricesLoading}
        circlePricesError={circlePricesError}
      />

      {!hideOrderRugControls && (
        <OrderRugControls
          subtotal={subTotalPrice}
          handleNextStep={() => form.current?.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }))}
          isNextStepEnabled={(customize.weaveColor ?? false) && !circlePricesLoading}
          nextStepLabel={circlePricesLoading ? 'Loading' : 'Continue'}
          isSubmitting={circlePricesLoading}
        />
      )}
    </form>
  )
}
