import { Fragment, memo, useCallback, useMemo, useState } from 'react'
import {
  AtIcon,
  AtImage,
  AtLink,
  FocusElement,
  OrModal,
  focusElementClasses,
} from '@curran-catalog/curran-atomic-library'

import { Customize } from '@context/rug-builder'
import { useRugBuilder } from '@hooks/use-rug-builder'
import { formatPrice } from '@utils/price'
import { normalizeAsset } from '@utils/normalize'
import { cmToFeetInchesConversion, feetInchesToMConversion, getFeetInches } from './steps/customize-rug-types/utils'
import { CornerStyleType, CustomizeType, FurniturePrices, OrderRugStepperIndexes } from 'types'
import { Dialog, Transition } from '@headlessui/react'
import { getFullConversionDetail, MeasureUnitKey } from '@utils/measures'
import { WeaveParametersSuccess } from '@services/prices'
import { useAuth } from '@hooks/use-auth'

enum OrderRugPreviewItemsName {
  TYPE = 'TYPE',
  SIZE = 'SIZE',
  WEAVE_COLOR = 'WEAVE COLOR',
  BORDER = 'BORDER',
  ADDONS = 'ADD-ONS',
  NOTES = 'NOTES',
}

const INITIAL_BORDER_PIXEL = 20
const INCHES_TO_CENTIMETER_VALUE = 2.54

const getMiteredMarginClasses = (width: number, isShoppingCart: boolean) => {
  if (width !== 0) {
    if (isShoppingCart) {
      return `-${(INITIAL_BORDER_PIXEL + width * INCHES_TO_CENTIMETER_VALUE) / 2}px`
    }
    return `-${(INITIAL_BORDER_PIXEL + width * (INCHES_TO_CENTIMETER_VALUE * 2)) / 2}px`
  }
  return '-10px'
}

const getBorderSizeClasses = (width: number, isShoppingCart: boolean) => {
  if (isShoppingCart) {
    return `${INITIAL_BORDER_PIXEL + width * INCHES_TO_CENTIMETER_VALUE}px`
  }
  return `${INITIAL_BORDER_PIXEL + width * (INCHES_TO_CENTIMETER_VALUE * 2)}px`
}

const getPaddingSize = (width: number, isShoppingCart: boolean) => {
  if (isShoppingCart) {
    return INITIAL_BORDER_PIXEL + width * INCHES_TO_CENTIMETER_VALUE
  }
  return INITIAL_BORDER_PIXEL + width * (INCHES_TO_CENTIMETER_VALUE * 2)
}

export const getSizeValue = ({ customize, rollWidth }: { customize: Customize; rollWidth: number }) => {
  const {
    unitMeasure,
    diameterCm,
    diameterFt,
    diameterIn,
    isMetric,
    lengthCm,
    lengthFt,
    lengthIn,
    type,
    widthCm,
    widthFt,
    widthIn,
    lft,
    lftIn,
    lm,
    sqft,
    sqm,
    sqyd,
  } = customize

  switch (type) {
    case CustomizeType.RECTANGULAR:
      if (isMetric) {
        return `${widthCm ? cmToFeetInchesConversion(Number(widthCm)) : ''}
                ${widthCm ? 'W' : ''}
                ${widthCm && lengthCm ? 'x' : ''}
                ${lengthCm ? cmToFeetInchesConversion(Number(lengthCm)) : ''}
                ${lengthCm ? 'L' : ''}
                ${widthCm ? `- ${widthCm}cm` : ''}
                ${widthCm && lengthCm ? 'x' : ''}
                ${lengthCm ? `${lengthCm}cm` : ''}`
      }

      return `${widthFt ? `${widthFt}'` : ''}
              ${widthIn ? `${widthIn}''` : ''} 
              ${widthFt ? 'W' : ''}
              ${widthFt && lengthFt ? 'x' : ''}
              ${lengthFt ? `${lengthFt}'` : ''}
              ${lengthIn ? `${lengthIn}''` : ''}
              ${lengthFt ? 'L' : ''}`

    case CustomizeType.CIRCULAR:
      if (isMetric) {
        return `${diameterCm ? `${cmToFeetInchesConversion(Number(diameterCm))} - ${diameterCm}cm` : ''} 
                ${diameterCm ? `D` : ''}`
      }

      return `${diameterFt ? `${diameterFt}'` : ''} 
              ${diameterIn ? `${diameterIn}''` : ''}
              ${diameterFt ? `D` : ''}`

    case CustomizeType.WALL_TO_WALL:
      return getFullConversionDetail({
        isMetric,
        rollWidth,
        unitMeasure: unitMeasure?.value as MeasureUnitKey,
        lft,
        lftIn,
        lm,
        sqft,
        sqm,
        sqyd,
      })

    default:
      return ''
  }
}

export const getSizeMeasureValue = ({ customize, rollWidth }: { customize: Customize; rollWidth: number }) => {
  const {
    unitMeasure,
    diameterCm,
    diameterFt,
    diameterIn,
    isMetric,
    lengthCm,
    lengthFt,
    lengthIn,
    type,
    widthCm,
    widthFt,
    widthIn,
    lft,
    lftIn,
    lm,
    sqft,
    sqm,
    sqyd,
  } = customize

  switch (type) {
    case CustomizeType.RECTANGULAR:
      if (isMetric) {
        return {
          width: widthCm ? `${cmToFeetInchesConversion(Number(widthCm))} - ${widthCm}cm` : '',
          length: lengthCm ? `${cmToFeetInchesConversion(Number(lengthCm))} - ${lengthCm}cm` : '',
        }
      }

      return {
        width: widthFt ? `${widthFt}' ${widthIn ? `${widthIn} ''` : ''}` : '',
        length: lengthFt ? `${lengthFt}' ${lengthIn ? `${lengthIn} ''` : ''}` : '',
      }

    case CustomizeType.CIRCULAR:
      if (isMetric) {
        return {
          diameter: diameterCm ? `${cmToFeetInchesConversion(Number(diameterCm))} - ${diameterCm}cm` : '',
        }
      }

      return {
        diameter: diameterFt ? `${diameterFt}' ${diameterIn ? `${diameterIn}''` : ''}` : '',
      }

    case CustomizeType.WALL_TO_WALL: {
      const dim = {
        width: '',
        length: '',
      }
      if (isMetric) {
        dim['width'] = Number(feetInchesToMConversion(rollWidth)) + 'm'
      } else {
        dim['width'] = getFeetInches(rollWidth).feet + "' " + getFeetInches(rollWidth).inches + "''"
      }
      switch (unitMeasure?.value) {
        case MeasureUnitKey.SQUARE_METER:
          dim['length'] = (Number(sqm ?? 0) / Number(feetInchesToMConversion(rollWidth))).toFixed(2) + 'm'
          return dim
        case MeasureUnitKey.LINEAR_METER:
          dim['length'] = Number(lm ?? 0).toFixed(0) + 'm'
          return dim
        case MeasureUnitKey.SQUARE_YARD: {
          const sf = Number(sqyd ?? 0) * 9
          const length_sqyd = Number(sf) / rollWidth
          dim['length'] = getFeetInches(length_sqyd).feet + "' " + getFeetInches(length_sqyd).inches + "''"
          return dim
        }
        case MeasureUnitKey.SQUARE_FEET: {
          const length_sf = Number(sqft ?? 0) / rollWidth
          dim['length'] = getFeetInches(length_sf).feet + "' " + getFeetInches(length_sf).inches + "''"
          return dim
        }
        case MeasureUnitKey.LINEAR_FEET:
          dim['length'] = (lft ? lft : 0) + "' " + (lftIn ? lftIn : 0) + "''"
          return dim
        default:
          return dim
      }
    }
    default:
      return {
        width: '',
        length: '',
      }
  }
}

interface OrderPreviewImageProps {
  isShoppingCart?: boolean
}

export const OrderPreviewImageCart = memo(() => {
  const { state } = useRugBuilder()
  const { customize, border, activeStep } = state

  return (
    <div
      className={`overflow-hidden max-w-[250px] mx-auto shadow-lg shadow-gray ${
        activeStep === OrderRugStepperIndexes.PREVIEW_CONFIRM ? 'sticky md:top-28 lg:top-40 block' : 'relative'
      }`}
    >
      {border.borderColor && (
        <div
          className={`absolute right-0 top-0 h-full transition-transform z-[5] ${
            !border.borderColor ? '-translate-x-full' : 'transform-none'
          }`}
          style={{ width: '15px' }}
        >
          <div
            className="w-full h-full overflow-hidden bg-cover"
            style={{
              backgroundImage: `url(${normalizeAsset({ asset: border.borderColor.images[0] }).src})`,
            }}
          />
        </div>
      )}
      <div
        className={`absolute inset-x-0 bottom-0 w-full ${!border.borderColor ? 'translate-y-full' : 'z-[5]'}`}
        style={{ height: '15px' }}
      >
        <div
          className={`transition-[transform,margin] duration-200 my-0 w-full overflow-hidden relative ${
            (border.cornerStyle && border.cornerStyle.name === CornerStyleType.MITERED) ||
            border.borderMaterial?.automiter
              ? `border-r-2 border-r-gray-preview border-opacity-75`
              : ''
          }`}
          style={{
            height: '15px',
            ...(((border.cornerStyle && border.cornerStyle.name === CornerStyleType.MITERED) ||
              border.borderMaterial?.automiter) && {
              transform: 'skew(45deg)',
              marginLeft: '-7.5px',
            }),
          }}
        >
          {border.borderColor && (
            <div
              className="absolute transition-transform top-0 right-0 bottom-0 left-0 overflow-hidden bg-cover"
              style={{
                height: '15px',
                backgroundImage: `url(${normalizeAsset({ asset: border.borderColor.images[0] }).src})`,
                ...(((border.cornerStyle && border.cornerStyle.name === CornerStyleType.MITERED) ||
                  border.borderMaterial?.automiter) && {
                  transform: 'skew(-45deg)',
                }),
              }}
            />
          )}
        </div>
      </div>

      {customize.weaveColor && Array.isArray(customize.weaveColor.images) && customize.weaveColor.images.length > 0 && (
        <AtImage
          {...normalizeAsset({ asset: customize.weaveColor.images[0] })}
          className="w-64 h-64"
          caption={undefined}
        />
      )}
    </div>
  )
})

export const OrderPreviewImage = memo(({ isShoppingCart = false }: OrderPreviewImageProps) => {
  const { state } = useRugBuilder()
  const { customize, border, activeStep, product } = state
  const [isModalZoomOpen, setIsModalZoomOpen] = useState(false)
  const rollWidth =
    !!product && !!product.parameters && (product.parameters as WeaveParametersSuccess)['roll_width']
      ? Number((product.parameters as WeaveParametersSuccess)['roll_width']) ?? 0
      : 0
  const sizes = getSizeMeasureValue({ customize, rollWidth })

  return (
    <div
      className={`overflow-hidden  ${
        activeStep === OrderRugStepperIndexes.PREVIEW_CONFIRM ? 'sticky md:top-28 lg:top-40 block' : 'relative'
      }`}
    >
      <OrModal isOpen={isModalZoomOpen} title="" handleCloseModal={() => setIsModalZoomOpen(false)}>
        <div className="relative overflow-hidden">
          {border.borderColor && (
            <div
              className={`absolute right-0 top-0 h-full transition-transform z-[5] ${
                !border.borderColor ? '-translate-x-full' : 'transform-none'
              }`}
              style={{ width: !border.borderWidth ? '24px' : getBorderSizeClasses(border.borderWidth, isShoppingCart) }}
            >
              <div
                className="w-full h-full overflow-hidden bg-cover"
                style={{
                  backgroundImage: `url(${normalizeAsset({ asset: border.borderColor.images[0] }).src})`,
                }}
              />
            </div>
          )}
          {customize.weaveColor &&
            Array.isArray(customize.weaveColor.images) &&
            customize.weaveColor.images.length > 0 && (
              <AtImage
                {...normalizeAsset({ asset: customize.weaveColor.images[0] })}
                className="object-cover"
                caption={undefined}
              />
            )}
          <div
            className={`absolute inset-x-0 bottom-0 w-full ${!border.borderColor ? 'translate-y-full' : 'z-[5]'}`}
            style={{
              height: !border.borderWidth ? '24px' : getBorderSizeClasses(border.borderWidth, isShoppingCart),
            }}
          >
            <div
              className={`transition-[transform,margin] duration-200 my-0 w-full overflow-hidden relative ${
                (border.cornerStyle && border.cornerStyle.name === CornerStyleType.MITERED) ||
                border.borderMaterial?.automiter
                  ? `border-r-2 border-r-gray-preview border-opacity-75`
                  : ''
              }`}
              style={{
                height: !border.borderWidth ? '24px' : getBorderSizeClasses(border.borderWidth, isShoppingCart),
                ...(((border.cornerStyle && border.cornerStyle.name === CornerStyleType.MITERED) ||
                  border.borderMaterial?.automiter) && {
                  transform: 'skew(45deg)',
                  marginLeft: getMiteredMarginClasses(border.borderWidth ?? 0, isShoppingCart),
                }),
              }}
            >
              {border.borderColor && (
                <div
                  className="absolute transition-transform top-0 -right-[10px] bottom-0 left-0 overflow-hidden bg-cover"
                  style={{
                    backgroundImage: `url(${normalizeAsset({ asset: border.borderColor.images[0] }).src})`,
                    ...(((border.cornerStyle && border.cornerStyle.name === CornerStyleType.MITERED) ||
                      border.borderMaterial?.automiter) && {
                      transform: 'skew(-45deg)',
                    }),
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </OrModal>
      {border.borderColor && (
        <div
          className={`${
            isShoppingCart ? 'absolute right-0' : 'hidden xl:absolute right-[6.6rem] xl:right-6'
          } top-0 h-[220px] transition-transform z-[5] ${!border.borderColor ? '-translate-x-full' : 'transform-none'}`}
          style={{ width: !border.borderWidth ? '24px' : getBorderSizeClasses(border.borderWidth, isShoppingCart) }}
        >
          <div
            className="w-full h-full overflow-hidden bg-cover"
            style={{
              backgroundImage: `url(${normalizeAsset({ asset: border.borderColor.images[0] }).src})`,
            }}
          />
        </div>
      )}

      {isShoppingCart && (
        <div
          className={`absolute inset-x-0 bottom-0 w-[220px] ${!border.borderColor ? 'translate-y-full' : 'z-[5]'}`}
          style={{
            height: !border.borderWidth ? '24px' : getBorderSizeClasses(border.borderWidth, isShoppingCart),
          }}
        >
          <div
            className={`transition-[transform,margin] duration-200 my-0 w-full overflow-hidden relative ${
              (border.cornerStyle && border.cornerStyle.name === CornerStyleType.MITERED && border.borderColor) ||
              (border.borderMaterial?.automiter && border.borderColor)
                ? `border-r-2 border-r-gray-preview border-opacity-75`
                : ''
            }`}
            style={{
              height: !border.borderWidth ? '24px' : getBorderSizeClasses(border.borderWidth, isShoppingCart),
              ...(((border.cornerStyle && border.cornerStyle.name === CornerStyleType.MITERED) ||
                border.borderMaterial?.automiter) && {
                transform: 'skew(45deg)',
                marginLeft: getMiteredMarginClasses(border.borderWidth ?? 0, isShoppingCart),
              }),
            }}
          >
            {border.borderColor && (
              <div
                className="absolute transition-transform top-0 -right-[10px] bottom-0 left-0 overflow-hidden bg-cover"
                style={{
                  backgroundImage: `url(${normalizeAsset({ asset: border.borderColor.images[0] }).src})`,
                  ...(((border.cornerStyle && border.cornerStyle.name === CornerStyleType.MITERED) ||
                    border.borderMaterial?.automiter) && {
                    transform: 'skew(-45deg)',
                  }),
                }}
              />
            )}
          </div>
        </div>
      )}

      {customize.weaveColor && Array.isArray(customize.weaveColor.images) && customize.weaveColor.images.length > 0 && (
        <div>
          <div className="flex flex-row">
            <div
              className="size-[220px] relative"
              style={
                border.borderColor
                  ? {
                      backgroundImage: `url(${normalizeAsset({ asset: border.borderColor.images[0] }).src})`,
                      backgroundSize: 'cover',
                    }
                  : { backgroundColor: 'transparent' }
              }
            >
              <div
                style={{
                  paddingRight: isShoppingCart
                    ? 0
                    : !border.borderColor
                    ? 0
                    : !border.borderWidth
                    ? 24
                    : getPaddingSize(border.borderWidth, isShoppingCart),
                }}
              >
                <AtImage
                  {...normalizeAsset({ asset: customize.weaveColor.images[0] })}
                  className="object-cover"
                  caption={undefined}
                />
              </div>
              <div
                className={`absolute inset-x-0 bottom-0 w-[220px] ${
                  !border.borderColor ? 'translate-y-full' : 'z-[5]'
                }`}
                style={{
                  height: !border.borderWidth ? '24px' : getBorderSizeClasses(border.borderWidth, isShoppingCart),
                }}
              >
                <div
                  className={`transition-[transform,margin] duration-200 my-0 w-full overflow-hidden relative ${
                    (border.cornerStyle && border.cornerStyle.name === CornerStyleType.MITERED && border.borderColor) ||
                    (border.borderMaterial?.automiter && border.borderColor)
                      ? `border-r-2 border-r-gray-preview border-opacity-75`
                      : ''
                  }`}
                  style={{
                    height: !border.borderWidth ? '24px' : getBorderSizeClasses(border.borderWidth, isShoppingCart),
                    ...(((border.cornerStyle && border.cornerStyle.name === CornerStyleType.MITERED) ||
                      border.borderMaterial?.automiter) && {
                      transform: 'skew(45deg)',
                      marginLeft: getMiteredMarginClasses(border.borderWidth ?? 0, isShoppingCart),
                    }),
                  }}
                >
                  {border.borderColor && (
                    <div
                      className="absolute transition-transform top-0 -right-[10px] bottom-0 left-0 overflow-hidden bg-cover"
                      style={{
                        backgroundImage: `url(${normalizeAsset({ asset: border.borderColor.images[0] }).src})`,
                        ...(((border.cornerStyle && border.cornerStyle.name === CornerStyleType.MITERED) ||
                          border.borderMaterial?.automiter) && {
                          transform: 'skew(-45deg)',
                        }),
                      }}
                    />
                  )}
                </div>
              </div>
            </div>

            {!isShoppingCart && sizes.length && (
              <div className="flex flex-col ml-1 justify-center gap-4">
                <AtIcon type="arrows" size={16} color="gray" className="-rotate-90" />
                <p className="text-sm rotate-180" style={{ textOrientation: 'mixed', writingMode: 'vertical-lr' }}>
                  {sizes.length}
                </p>
                <AtIcon type="arrows" size={16} color="gray" className="rotate-90" />
              </div>
            )}
          </div>

          {!isShoppingCart && (sizes.width ?? sizes.diameter) && (
            <div className="flex flex-row  mt-1 items-center justify-center gap-4 max-w-[220px]">
              <AtIcon type="arrows" size={16} color="gray" className="-rotate-180" />
              <p className="text-center text-sm">{`${sizes.width ?? sizes.diameter}`}</p>
              <AtIcon type="arrows" size={16} color="gray" className="" />
            </div>
          )}
        </div>
      )}
    </div>
  )
})

interface OrderRugPreviewItemProps {
  name: OrderRugPreviewItemsName
  values?: string[]
}

const OrderRugPreviewItem = memo(({ name, values }: OrderRugPreviewItemProps) => (
  <li className="py-4">
    <span className="text-sm">{name}</span>
    {Array.isArray(values) &&
      values.length > 0 &&
      values.map((value, index) => (
        <p key={`${value}-${index}`} className="text-lg font-medium break-words">
          {value}
        </p>
      ))}
  </li>
))

const OrderRugPreviewContent = memo(() => {
  const { state } = useRugBuilder()
  const {
    customize,
    border,
    addons,
    notes,
    product,
    subTotalDiscountPrice,
    subTotalPrice,
    baseWeavePrice,
    rugpadPrice,
    addOnPrice,
    discountPrice,
    userTradeLevel,
  } = state

  const rollWidth =
    !!product && !!product.parameters && (product.parameters as WeaveParametersSuccess)['roll_width']
      ? Number((product.parameters as WeaveParametersSuccess)['roll_width']) ?? 0
      : 0

  const renderWeaveColorName = useCallback(
    (prefix = '') => (customize.weaveColor ? `${prefix} ${customize.weaveColor?.weaveColorName}` : ''),
    [customize.weaveColor],
  )

  const orderRugPreviewItems: OrderRugPreviewItemProps[] = useMemo(
    () => [
      { name: OrderRugPreviewItemsName.TYPE, values: [customize.type] },
      { name: OrderRugPreviewItemsName.WEAVE_COLOR, values: [renderWeaveColorName()] },
      {
        name: OrderRugPreviewItemsName.SIZE,
        values: [getSizeValue({ customize, rollWidth })],
      },
      {
        name: OrderRugPreviewItemsName.BORDER,
        values: [
          `${border.borderMaterial?.borderName ? `${border.borderMaterial?.borderName} ` : ''}`,
          border.borderColor?.borderColorName ?? '',
          border.borderWidth ? `${border.borderWidth}'' ` : '',
          border.borderMaterial?.borderMaterialPrice?.price
            ? `(${formatPrice(border.borderMaterial?.borderMaterialPrice?.price)})`
            : '',
          border.cornerStyle
            ? `${border.cornerStyle.name} ${
                border.cornerStyle.price ? `(${formatPrice(border.cornerStyle.price)})` : ''
              }`
            : '',
        ],
      },
      {
        name: OrderRugPreviewItemsName.ADDONS,
        values: [
          `${addons.rugpad?.rugpadName ? `${addons.rugpad.rugpadName}` : ''}
           ${addons.rugpad?.rugpadPrice ? `(${formatPrice(addons.rugpad?.rugpadPrice.price)})` : ''}
            `,
          addons.supplies
            ?.map((supply) => {
              const supplyPrice =
                supply.addonPrices &&
                Array.isArray((supply.addonPrices as FurniturePrices).products) &&
                (supply.addonPrices as FurniturePrices).products.length > 0
                  ? (supply.addonPrices as FurniturePrices).products[0].price ?? 0
                  : 0

              return `${supply.addonName} ${supplyPrice ? `(${formatPrice(supplyPrice)})` : ''}`
            })
            .join(', ') ?? '',
          addons.productionRush ? addons.productionRush.rushName : '',
        ],
      },
      { name: OrderRugPreviewItemsName.NOTES, values: [notes.optionalNotes ?? ''] },
    ],
    [
      customize,
      rollWidth,
      renderWeaveColorName,
      border.borderMaterial?.borderName,
      border.borderColor?.borderColorName,
      border.borderWidth,
      border.cornerStyle,
      addons.rugpad?.rugpadName,
      addons.rugpad?.rugpadPrice,
      addons.supplies,
      addons.productionRush,
      notes.optionalNotes,
      border.borderMaterial?.borderMaterialPrice?.price,
    ],
  )
  const { user } = useAuth({})

  return (
    <div className="pt-4 pb-8">
      <h3 className="text-lg font-medium my-4">Order Preview</h3>

      <div className="mt-4 flex flex-col gap-4">
        {customize.weaveColor && <OrderPreviewImage />}

        <h3 className="text-xl font-medium mt-2">
          {product?.weaveName ?? ''} {renderWeaveColorName('-')}
        </h3>

        <ul className="flex flex-col divide-y divide-outlined-medium border-y border-y-outlined-medium overflow-x-hidden">
          {orderRugPreviewItems.map((orderRugPreviewItem, index) => (
            <OrderRugPreviewItem key={`${orderRugPreviewItem.name}-${index}`} {...orderRugPreviewItem} />
          ))}
        </ul>
        {!user || !userTradeLevel || userTradeLevel == '' || subTotalPrice <= 0 ? (
          <div className="text-lg font-medium ">
            Subtotal: {subTotalPrice > 0 ? ' ' + formatPrice(subTotalPrice) + '' : '-'}
          </div>
        ) : (
          <dl className="mt-1 space-y-1 text-sm font-normal">
            <div className="flex items-center justify-between">
              <dt className="text-gray-900">{CustomizeType.WALL_TO_WALL == customize.type ? 'Wall-to-Wall' : 'Rug'}</dt>
              <dd className="text-md font-medium text-gray-900">
                {baseWeavePrice > 0 ? formatPrice(baseWeavePrice) + '.00' : '-'}
              </dd>
            </div>
            {rugpadPrice > 0 && (
              <div className="flex items-center justify-between">
                <dt className="text-gray-900">Rugpad</dt>
                <dd className="text-md font-medium text-gray-900">
                  {rugpadPrice > 0 ? formatPrice(rugpadPrice) + '.00' : '-'}
                </dd>
              </div>
            )}
            <div className="flex items-center justify-between border-b pb-1 text-gray-900">
              <dt className="flex ">
                <div className="pr-1">Discount</div>
                <div className="pl-1 rounded-full bg-gray py-1 px-1 text-xs tracking-wide text-subdued text-center">
                  {userTradeLevel?.charAt(0).toUpperCase() + userTradeLevel?.slice(1)}
                </div>
              </dt>
              <dd className="text-md font-medium text-gray-900">
                {discountPrice > 0 ? '-' + formatPrice(discountPrice) : '-'}
              </dd>
            </div>
            {(addOnPrice > 0 || (addons?.productionRush?.price ?? 0) > 0) && (
              <div className="flex items-center justify-between">
                <dt className="text-gray-900">Subtotal</dt>
                <dd className="text-md font-medium text-gray-900">
                  {baseWeavePrice > 0 ? formatPrice(subTotalDiscountPrice - discountPrice) : '-'}
                </dd>
              </div>
            )}
            {addOnPrice > 0 && (
              <div
                className={
                  'flex items-center justify-between ' +
                  ((addons?.productionRush?.price ?? 0) > 0 ? '' : 'border-b pb-1')
                }
              >
                <dt className="text-gray-900">Supplies</dt>
                <dd className="text-md font-medium text-gray-900">
                  {addOnPrice > 0 ? formatPrice(addOnPrice) + '.00' : '-'}
                </dd>
              </div>
            )}
            {(addons?.productionRush?.price ?? 0) > 0 && (
              <div className="flex items-center justify-between border-b pb-1">
                <dt className="text-gray-900">Hot Rush</dt>
                <dd className="text-md font-medium text-gray-900">
                  {addons.productionRush?.price ?? 0 > 0 ? formatPrice(addons.productionRush?.price ?? 0) + '.00' : '-'}
                </dd>
              </div>
            )}
            <div className="flex items-center justify-between">
              <dt className="">Total</dt>
              <dd className="text-lg font-medium">
                {subTotalPrice > 0 ? formatPrice(subTotalPrice - discountPrice) : '-'}
              </dd>
            </div>
          </dl>
        )}
      </div>
    </div>
  )
})

export const OrderRugMobilePreview = memo(() => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <div className="inline-flex gap-x-1 items-center">
        <AtIcon type="rug" className="hidden md:block" />
        <AtLink
          icon={<AtIcon type="ruler" />}
          className="font-bold text-sm w-full"
          type="button"
          label="See Order Preview"
          onClick={() => setIsOpen(true)}
        />
      </div>

      <Transition show={isOpen} as={Fragment}>
        <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="relative z-50">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/50" aria-hidden="true" />
          </Transition.Child>

          <Transition.Child
            enter="transition-transform duration-500 ease-out"
            enterFrom="-translate-x-full"
            enterTo="transform-none"
            leave="transition-transform duration-250 ease-in"
            leaveFrom="transform-none"
            leaveTo="-translate-x-full"
            as={Fragment}
          >
            <div className="fixed inset-0">
              <Dialog.Panel className="px-6 w-full sm:w-1/2 lg:w-1/3 bg-subdued h-screen overflow-y-auto border-r border-r-outlined-gray">
                <div className="pt-4 flex flex-row justify-end">
                  <button
                    className={`py-4 ${focusElementClasses[FocusElement.FOCUS_VISIBLE]}`}
                    aria-label="Close order preview"
                    type="button"
                    onClick={() => setIsOpen(false)}
                  >
                    <AtIcon type="close" color="secondary" />
                  </button>
                </div>

                <OrderRugPreviewContent />
              </Dialog.Panel>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition>
    </>
  )
})

export const OrderRugDesktopPreview = memo(() => {
  return (
    <div className="hidden xl:block xl:w-80 bg-subdued px-6 ">
      <OrderRugPreviewContent />
    </div>
  )
})
