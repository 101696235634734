import { memo } from 'react'

import { useRugBuilder } from '@hooks/use-rug-builder'
import { BorderColorSwatch } from '@components/product-detail-options/border-color-swatch'

interface BorderColorProps {
  isPreviewConfirm?: boolean
}

export const BorderColor = memo(({ isPreviewConfirm = false }: BorderColorProps) => {
  const { state } = useRugBuilder()
  const { border } = state
  const { borderMaterial } = border

  return borderMaterial &&
    borderMaterial.borderColorGroup &&
    Array.isArray(borderMaterial.borderColorGroup.borderColors) &&
    borderMaterial.borderColorGroup.borderColors.length > 0 ? (
    <div className="pb-2 flex flex-col gap-4">
      <h3 className={isPreviewConfirm ? 'uppercase text-sm' : 'text-lg font-medium py-2'}>
        Choose Border Color{border.borderColor?.borderColorName ? ': ' : ''}
        <span className="font-normal text-base">{border.borderColor?.borderColorName ?? ''}</span>{' '}
      </h3>
      <div className="flex flex-row gap-6 flex-wrap">
        <BorderColorSwatch options={borderMaterial.borderColorGroup.borderColors} />
      </div>
      <hr className="border-outlined-gray" />
    </div>
  ) : null
})
