import { formatPrice } from '@utils/price'

const OrderRugSubtotal = ({ amount }: { amount: number }) =>
  amount > 0 ? (
    <div className="flex flex-row justify-center xl:hidden">
      <span className="text-lg font-medium py-4 xl:py-8 xl:px-4">Subtotal: {formatPrice(amount)}</span>
    </div>
  ) : null

export default OrderRugSubtotal
