import { memo } from 'react'
import { AtButtonVariant, AtButtonSize, AtButton, AtButtonState } from '@curran-catalog/curran-atomic-library'

import { RugBuilderActionTypes } from '@context/rug-builder'
import { useRugBuilder } from '@hooks/use-rug-builder'
import { EditButton } from '@components/flooring-product-detail'
import { formatPrice } from '@utils/price'
import { BorderColor, BorderMaterial, BorderWidth, CornerStyle } from '../border-sections'
import { useValidatePreviewSteps } from './hooks'
import { validateBorder } from '../border'
import { CustomizeType } from 'types'

export const BorderRugEditable = memo(() => {
  const { state, dispatch } = useRugBuilder()
  const { customize, border, previewConfirm, product } = state

  const { isOpenCollapse, handleCollapse, openNextCollapse } = useValidatePreviewSteps({
    steps: ['addons', 'notes'],
    currentStep: 'border',
    nextStep: 'addons',
  })

  return (
    <div className="border-b border-b-outlined-gray w-full">
      <div className="py-4">
        <div className="flex flex-row justify-between items-center">
          <p className="uppercase text-sm text-secondary">Border</p>
          <EditButton
            edit={isOpenCollapse}
            handleEdit={handleCollapse}
            enable={previewConfirm.isBorderCollapseComplete}
          />
        </div>
        <div className="flex flex-col md:flex-row gap-2 font-medium text-lg">
          {border.borderMaterial && <span>{border.borderMaterial.borderName}</span>}
          {border.borderMaterial && border.borderColor && <span className="hidden md:block">|</span>}
          {border.borderColor && <span>{`${border.borderColor.borderColorName}`}</span>}
          {border.borderColor && border.borderWidth && <span className="hidden md:block">|</span>}
          {border.borderWidth && <span>{`${border.borderWidth}''`}</span>}
          {border.borderWidth && border.cornerStyle && <span className="hidden md:block">|</span>}
          {border.cornerStyle && (
            <span>{`${border.cornerStyle.name} ${
              border.cornerStyle.price ? `(${formatPrice(border.cornerStyle.price)})` : ''
            }`}</span>
          )}
        </div>
      </div>

      <div className={`${isOpenCollapse ? 'block' : 'hidden'}`}>
        <BorderMaterial isPreviewConfirm />
        <BorderColor isPreviewConfirm />
        <BorderWidth isPreviewConfirm />
        {customize.type !== CustomizeType.CIRCULAR && <CornerStyle isPreviewConfirm />}

        <div className="py-8">
          <AtButton
            label="Update"
            variant={AtButtonVariant.SECONDARY}
            size={AtButtonSize.COMPACT}
            onClick={() => {
              dispatch({
                type: RugBuilderActionTypes.RESET_PREVIEW_CONFIRM_ADDONS_COLLAPSE,
              })

              dispatch({
                type: RugBuilderActionTypes.HANDLE_BORDER_IS_COMPLETE,
                isCompletePayload: true,
              })

              dispatch({
                type: RugBuilderActionTypes.HANDLE_PREVIEW_CONFIRM_COLLAPSE,
                keyPreviewConfirmPayload: 'isAddonsCollapseComplete',
                valuePreviewConfirmPayload: true,
              })

              handleCollapse()
              openNextCollapse()
            }}
            disabled={!isOpenCollapse || !validateBorder(customize, border, product?.borderGroup)}
            state={
              !isOpenCollapse || !validateBorder(customize, border, product?.borderGroup)
                ? AtButtonState.DISABLED
                : AtButtonState.ENABLED
            }
          />
        </div>
      </div>
    </div>
  )
})
