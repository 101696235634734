import { FlooringWeaveState } from '@context/flooring-product'
import { CartTilesItemDTO, CartW2WItemDTO } from '@services/cart'
import { Unit } from '@services/prices'
import { normalizeAsset, sendMeasureUnitObject } from '..'
import { ContentfulObjFlooringWeave } from 'types'

export enum WeaveTypes {
  WALL_TO_WALL = 'wall-to-wall',
  WALL_COVERING = 'wall-covering',
  TILES = 'tiles',
  AREA_RUG = 'area-rug',
}

export const getWallToWallAddToCartData = (
  product: ContentfulObjFlooringWeave,
  state: FlooringWeaveState,
  itemListName?: string,
  productIndex?: number,
): CartW2WItemDTO => {
  const imageInfo = state.selectedColor?.images
    ? normalizeAsset({ asset: state.selectedColor?.images[0] })
    : { src: '/images/image-coming-soon.jpg', alt: '' }

  return {
    weaveReference: product.CONTENTFUL_ID,
    isWallcovering: false,
    imageSrc: imageInfo.src,
    imageAlt: imageInfo.alt,
    color: state.selectedColor ? state.selectedColor.weaveColorName : '',
    unit: state.size.isMetric ? Unit.METRIC : Unit.IMPERIAL,
    ...sendMeasureUnitObject({
      unitMeasure: state.size.unitMeasure?.value,
      lft: state.size.lft,
      lftIn: state.size.lftIn,
      lm: state.size.lm,
      sqft: state.size.sqft,
      sqm: state.size.sqm,
      sqyd: state.size.sqyd,
    }),
    quantity: state.quantity,
    supplies: state.selectedAddons
      ? state.selectedAddons.map((supply) => {
          return { sku: supply?.orderSku ?? '', quantity: supply.quantity ?? 1 }
        })
      : [],
    metadata: {
      link: window.location.href,
      ...{ ...(product.minQuantity && { minimumQuantity: product.minQuantity }) },
      contentful_id: product.CONTENTFUL_ID,
      category: product.materialFamily ?? 'All Weaves',
      category2: 'Wall-To-Wall',
      category5: state.selectedColor ? state.selectedColor.weaveColorName : '',
      brand: product.vendor?.vendorName ?? '',
      itemListName: itemListName || '',
      index: productIndex || 1,
      query_id: state.algoliaQueryId ?? '',
    },
  }
}

export const getWallCoveringAddToCartData = (
  product: ContentfulObjFlooringWeave,
  state: FlooringWeaveState,
  rollWidth: number,
  itemListName?: string,
  productIndex?: number,
): CartW2WItemDTO => {
  const imageInfo = state.selectedColor?.images
    ? normalizeAsset({ asset: state.selectedColor?.images[0] })
    : { src: '/images/image-coming-soon.jpg', alt: '' }

  return {
    weaveReference: product.CONTENTFUL_ID,
    isWallcovering: true,
    imageSrc: imageInfo.src,
    imageAlt: imageInfo.alt,
    color: state.selectedColor ? state.selectedColor.weaveColorName : '',
    unit: state.size.isMetric ? Unit.METRIC : Unit.IMPERIAL,
    ...{
      ...(!state.size.isMetric &&
        state.sizeWallCovering && { sqft: Number(state.sizeWallCovering?.value) * rollWidth }),
    },
    ...{
      ...(state.size.isMetric && state.sizeWallCovering && { sqm: Number(state.sizeWallCovering?.value) * rollWidth }),
    },
    quantity: state.quantity,
    supplies: state.selectedAddons
      ? state.selectedAddons.map((supply) => {
          return { sku: supply?.orderSku ?? '', quantity: supply.quantity ?? 1 }
        })
      : [],
    metadata: {
      contentful_id: product.CONTENTFUL_ID,
      link: window.location.href,
      ...{ ...(product.minQuantity && { minimumQuantity: product.minQuantity }) },
      category: product.materialFamily ?? 'All Weaves', // category of item shop by item, shop by type,etc
      category2: 'Wall Covering', // Choose rug type or carpet
      category5: state.selectedColor ? state.selectedColor.weaveColorName : '', // choose weave color
      brand: product.vendor?.vendorName ?? '',
      itemListName: itemListName || '',
      index: productIndex || 1,
      query_id: state.algoliaQueryId ?? '',
    },
  }
}

export const getTilesAddToCartData = (
  product: ContentfulObjFlooringWeave,
  state: FlooringWeaveState,
  itemListName?: string,
  productIndex?: number,
): CartTilesItemDTO => {
  const imageInfo = state.selectedColor?.images && normalizeAsset({ asset: state.selectedColor?.images[0] })

  return {
    weaveReference: product.CONTENTFUL_ID,
    imageSrc: imageInfo?.src ?? '/images/image-coming-soon.jpg',
    imageAlt: imageInfo?.alt ?? '',
    color: state.selectedColor ? state.selectedColor.weaveColorName : '',
    quantity: state.quantity,
    supplies: state.selectedAddons
      ? state.selectedAddons.map((supply) => {
          return { sku: supply?.orderSku ?? '', quantity: supply.quantity ?? 1 }
        })
      : [],
    metadata: {
      link: window.location.href,
      ...{ ...(product.minQuantity && { minimumQuantity: product.minQuantity }) },
      contentful_id: product.CONTENTFUL_ID,
      category: product.materialFamily ?? 'All Weaves',
      category2: 'Tiles',
      category5: state.selectedColor ? state.selectedColor.weaveColorName : '',
      brand: product.vendor?.vendorName ?? '',
      itemListName: itemListName || '',
      index: productIndex || 1,
      query_id: state.algoliaQueryId ?? '',
    },
  }
}
