import { memo, useCallback, useEffect, useMemo, useRef } from 'react'
import { MlFormField } from '@curran-catalog/curran-atomic-library'

import { RugBuilderActionTypes } from '@context/rug-builder'
import { useRugBuilder } from '@hooks/use-rug-builder'
import { useRugBuilderTopScroll } from '@hooks/use-rug-builder-top-scroll'
import { WeaveParametersSuccess } from '@services/prices'
import { formatPrice } from '@utils/price'
import { CustomizeType, ObjFlooringProductionRush, OrderRugStepperIndexes, ProductionRushState } from 'types'
import { getFlooringSupplies } from '@utils/get-flooring-supplies'

interface ProductionRushOptionsProps {
  productRushOption: ObjFlooringProductionRush
}

export const ProductionRushOptions = memo(({ productRushOption }: ProductionRushOptionsProps) => {
  const { id, state: rushState, price } = productRushOption

  const { state, dispatch } = useRugBuilder()
  const { activeStep, addons } = state

  const handleChange = useCallback(
    (productRushOptionSelected: ObjFlooringProductionRush) => {
      dispatch({
        type: RugBuilderActionTypes.HANDLE_ADDONS_IS_COMPLETE,
        isCompletePayload: true,
      })

      dispatch({
        type: RugBuilderActionTypes.SET_PRODUCTION_RUSH,
        productionRushPayload: productRushOptionSelected,
      })

      dispatch({
        type: RugBuilderActionTypes.SET_SUB_TOTAL_PRICE,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeStep, addons.isComplete, dispatch],
  )

  useEffect(() => {
    if (
      id === '1' &&
      !addons.productionRush &&
      (activeStep == OrderRugStepperIndexes.ADDONS || activeStep == OrderRugStepperIndexes.NOTES)
    ) {
      dispatch({
        type: RugBuilderActionTypes.SET_PRODUCTION_RUSH,
        productionRushPayload: productRushOption,
      })
      dispatch({
        type: RugBuilderActionTypes.HANDLE_ADDONS_IS_COMPLETE,
        isCompletePayload: true,
      })

      dispatch({
        type: RugBuilderActionTypes.SET_SUB_TOTAL_PRICE,
      })
    }
  }, [dispatch, productRushOption, activeStep, addons, id])

  return (
    <MlFormField
      name={String(id)}
      label={`${rushState} ${price ? `(${formatPrice(price)})` : ''}`}
      checked={id === addons.productionRush?.id || (id === '1' && !addons.productionRush)}
      type="radio"
      value={id}
      labelClassName="capitalize"
      onChange={() => handleChange(productRushOption)}
    />
  )
})

interface ProductionRushProps {
  isPreviewConfirm?: boolean
}

export const ProductionRush = ({ isPreviewConfirm = false }: ProductionRushProps) => {
  const { state } = useRugBuilder()
  const { product, customize } = state

  const addonsRef = useRef<HTMLDivElement>(null)

  useRugBuilderTopScroll({ ref: addonsRef, stepIndex: OrderRugStepperIndexes.ADDONS })

  const supplies = useMemo(() => product && getFlooringSupplies(product, customize), [product, customize]) ?? []

  const productRushOptions: ObjFlooringProductionRush[] = useMemo(
    () => [
      {
        id: '2',
        state: ProductionRushState.YES,
        rushName: `Rush (${formatPrice(Number((product?.parameters as WeaveParametersSuccess)?.rush_price))})`,
        price: Number((product?.parameters as WeaveParametersSuccess)?.rush_price),
      },
      { id: '1', state: ProductionRushState.NO, rushName: 'No Rush', price: 0 },
    ],
    [product?.parameters],
  )

  return (
    <div className="pb-4 flex flex-col gap-4">
      <div>
        <h3
          ref={customize.type === CustomizeType.WALL_TO_WALL && supplies.length === 0 ? addonsRef : null}
          className={isPreviewConfirm ? 'uppercase text-sm' : 'text-lg font-medium py-2'}
        >
          Production Rush
        </h3>
        {!isPreviewConfirm && (
          <p className="text-sm">
            Production will be cut from 2-3 weeks to 3-5 business days. Standard transit times apply.
          </p>
        )}
      </div>
      <div className="flex flex-col md:flex-row gap-6 flex-wrap">
        {productRushOptions.map((productRushOption) => (
          <ProductionRushOptions key={`product-rush-${productRushOption.id}`} productRushOption={productRushOption} />
        ))}
      </div>
    </div>
  )
}
