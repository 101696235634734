import { ReactNode } from 'react'
import { AtImage } from '@curran-catalog/curran-atomic-library'
import { Block, Inline } from '@contentful/rich-text-types'

import { normalizeAsset } from '@utils/normalize'
import { CloudinaryAsset, ContentType } from 'types'

export const customRenderNode = {
  'embedded-entry-block': (node: Block | Inline, children: ReactNode) => {
    if (node.data.target.sys.contentType.sys.id === ContentType.AT_CLOUDINARY_ASSET) {
      return node.data.target?.fields?.image?.map((img: CloudinaryAsset, idx: number) => (
        <AtImage key={idx} {...normalizeAsset({ asset: img })} className="max-w-xl w-full aspect-square" />
      ))
    }

    return children
  },
}
