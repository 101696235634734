import { memo } from 'react'

import { useRugBuilder } from '@hooks/use-rug-builder'
import { WeaveColorOptions } from '@components/product-detail-options'
import { EditButton } from '@components/flooring-product-detail'
import { useValidatePreviewSteps } from './hooks'

export const CustomizeRugWeaveColorEditable = memo(() => {
  const { state } = useRugBuilder()
  const { customize, product } = state

  const { isOpenCollapse, handleCollapse } = useValidatePreviewSteps({
    steps: ['size', 'border', 'addons', 'notes'],
    currentStep: 'weaveColor',
    nextStep: 'size',
  })

  return (
    <div className="border-b border-b-outlined-gray w-full">
      <div className="py-4">
        <div className="flex flex-row justify-between items-center">
          <p className="uppercase text-sm text-secondary">Weave Color</p>
          <EditButton edit={isOpenCollapse} handleEdit={handleCollapse} />
        </div>
        <span className="font-medium text-lg">{customize.weaveColor?.weaveColorName}</span>
      </div>

      <div className={`${isOpenCollapse ? 'flex flex-col gap-4 pt-4 pb-8' : 'hidden'}`}>
        <WeaveColorOptions options={product?.colorGroup?.weaveColors ?? []} />
      </div>
    </div>
  )
})
