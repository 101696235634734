import classNames from 'classnames'
import { AtButton, AtButtonState, AtButtonVariant } from '@curran-catalog/curran-atomic-library'

import OrderRugSubtotal from './order-rug-subtotal'
import { hideOrderRugControls } from './order-rug-collapse'

interface OrderRugControlsProps {
  handlePrevStep?: () => void | null
  handleNextStep: () => void
  isPrevStepEnabled?: boolean
  isNextStepEnabled: boolean
  nextStepLabel?: string
  isSubmitting?: boolean
  buttonsBetween?: boolean
  subtotal: number
  className?: string
}

export const OrderRugControls = ({
  subtotal,
  handlePrevStep,
  handleNextStep,
  isPrevStepEnabled = true,
  isNextStepEnabled,
  nextStepLabel,
  isSubmitting,
  buttonsBetween,
  className = '',
}: OrderRugControlsProps) => {
  return (
    <div className="border-none">
      {!hideOrderRugControls && <OrderRugSubtotal amount={subtotal} />}

      <div
        className={classNames(
          buttonsBetween ? 'xl:justify-between' : 'xl:justify-start',
          'flex flex-col-reverse md:flex-row justify-center gap-4 px-6 lg:px-0 py-6 xl:py-8',
          className,
        )}
      >
        {handlePrevStep && (
          <AtButton
            label="Previous"
            variant={AtButtonVariant.TERTIARY}
            state={isSubmitting || !isPrevStepEnabled ? AtButtonState.DISABLED : AtButtonState.ENABLED}
            onClick={handlePrevStep}
            className="w-full lg:w-fit"
          />
        )}
        <AtButton
          label={nextStepLabel ?? 'Continue'}
          icon={isSubmitting ? { type: 'spinner' } : undefined}
          state={isNextStepEnabled ? AtButtonState.ENABLED : AtButtonState.DISABLED}
          variant={AtButtonVariant.PRIMARY}
          onClick={handleNextStep}
          className="w-full lg:w-fit"
        />
      </div>
    </div>
  )
}
