import { memo } from 'react'
import { AtButton, AtButtonVariant, AtButtonSize, AtButtonState } from '@curran-catalog/curran-atomic-library'

import { useRugBuilder } from '@hooks/use-rug-builder'
import { EditButton } from '@components/flooring-product-detail'
import { formatPrice } from '@utils/price'
import { ProductionRush, RugPad, Supplies } from '../addons-sections'
import { useValidatePreviewSteps } from './hooks'
import { CustomizeType, FurniturePrices } from 'types'
import { WeaveParametersSuccess } from '@services/prices'
import { RugBuilderActionTypes } from '@context/rug-builder'
import { validateAddons } from '../addons'

export const AddonsEditable = memo(() => {
  const { state, dispatch } = useRugBuilder()
  const { customize, addons, previewConfirm, product } = state

  const { isOpenCollapse, handleCollapse, openNextCollapse } = useValidatePreviewSteps({
    steps: ['notes'],
    currentStep: 'addons',
    nextStep: 'notes',
  })

  const hasRushPrice = !(
    (product?.parameters as WeaveParametersSuccess)?.rush_price === 0 ||
    (product?.parameters as WeaveParametersSuccess)?.rush_price === null
  )

  return (
    <div className="border-b border-b-outlined-gray w-full">
      <div className="py-4">
        <div className="flex flex-row justify-between items-center">
          <p className="uppercase text-sm text-secondary">Add-Ons</p>
          <EditButton
            edit={isOpenCollapse}
            handleEdit={handleCollapse}
            enable={previewConfirm.isAddonsCollapseComplete}
          />
        </div>
        <div className="flex flex-col md:flex-row gap-2 font-medium text-lg">
          {addons.rugpad && (
            <span>{`${addons.rugpad.rugpadName}  ${
              addons.rugpad?.rugpadPrice ? `(${formatPrice(addons.rugpad?.rugpadPrice.price)})` : ''
            }`}</span>
          )}
          {addons.rugpad && Array.isArray(addons.supplies) && addons.supplies.length > 0 && (
            <span className="hidden md:block">|</span>
          )}
          {addons.rugpad &&
            Array.isArray(addons.supplies) &&
            addons.rugpad &&
            Array.isArray(addons.supplies) &&
            addons.supplies.length === 0 &&
            addons.productionRush && <span className="hidden md:block">|</span>}
          {Array.isArray(addons.supplies) && addons.supplies.length > 0 && (
            <span>{`${
              addons.supplies
                ?.map((supply) => {
                  const supplyPrice =
                    supply.addonPrices &&
                    Array.isArray((supply.addonPrices as FurniturePrices).products) &&
                    (supply.addonPrices as FurniturePrices).products.length > 0
                      ? (supply.addonPrices as FurniturePrices).products[0].price ?? 0
                      : 0

                  return `${supply.addonName} ${supplyPrice ? `(${formatPrice(supplyPrice)})` : ''}`
                })
                .join(', ') ?? ''
            }`}</span>
          )}
          {Array.isArray(addons.supplies) && addons.supplies.length > 0 && addons.productionRush && (
            <span className="hidden md:block">|</span>
          )}
          {addons.productionRush && <span>{addons.productionRush.rushName}</span>}
        </div>
      </div>

      {isOpenCollapse && (
        <>
          {customize.type === CustomizeType.WALL_TO_WALL || !product?.rugpadGroup ? null : <RugPad isPreviewConfirm />}
          <Supplies isPreviewConfirm />
          {hasRushPrice && <ProductionRush isPreviewConfirm />}

          <div className="py-8">
            <AtButton
              label="Update"
              variant={AtButtonVariant.SECONDARY}
              size={AtButtonSize.COMPACT}
              onClick={() => {
                dispatch({
                  type: RugBuilderActionTypes.HANDLE_ADDONS_IS_COMPLETE,
                  isCompletePayload: true,
                })
                handleCollapse()
                openNextCollapse()
              }}
              disabled={!validateAddons(customize.type, addons, product?.rugpadGroup, hasRushPrice)}
              state={
                !validateAddons(customize.type, addons, product?.rugpadGroup, hasRushPrice)
                  ? AtButtonState.DISABLED
                  : AtButtonState.ENABLED
              }
            />
          </div>
        </>
      )}
    </div>
  )
})
