import { ChangeEvent, FocusEvent, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { AxiosError } from 'axios'
import { AtControl, MlFormField, MlRichText, SelectOption } from '@curran-catalog/curran-atomic-library'

import { Customize, CustomizeFieldKey, RugBuilderActionTypes } from '@context/rug-builder'
import { useForm } from '@hooks/use-form'
import { useRugBuilder } from '@hooks/use-rug-builder'
import { useMediaQuery } from '@hooks/use-media-query'
import { useRugBuilderPrices } from '@hooks/use-rug-builder-prices'
import { useDebouncedCallback } from '@hooks/use-debounced-callback'
import { useRugTypeValidations } from './hooks'
import { WeaveParametersSuccess } from '@services/prices'
import { getFlooringSupplies } from '@utils/get-flooring-supplies'
import { OrderRugControls } from '../../order-rug-controls'
import { feetInchesToFeetConversion, feetInchesToMConversion, getFeetInches } from './utils'
import { getMeasureUnitImperialOptions, getMeasureUnitMetricOptions, MeasureUnitKey } from '@utils/measures'
import { CustomizeType, OrderRugStepperIndexes } from 'types'
import { hideOrderRugControls } from '../../order-rug-collapse'
import { ColorSlider } from '@components/flooring-product-detail/color-slider'

type Values = {
  sqyd: string
  sqft: string
  lft: string
  lftIn: string
  sqm: string
  lm: string
}

export type W2WImperialValues = Omit<Values, 'sqm' | 'lm'>

export type W2WMetricValues = Pick<Values, 'sqm' | 'lm'>

interface WallToWallRugSizeProps {
  isPreviewConfirm?: boolean

  rollWidth: number
  imperialValues: W2WImperialValues
  metricValues: W2WMetricValues

  imperialErrors: Partial<Record<keyof W2WImperialValues, string>>
  metricErrors: Partial<Record<keyof W2WMetricValues, string>>
  handleImperialBlur: (event: FocusEvent<HTMLInputElement>) => void
  handleMetricBlur: (event: FocusEvent<HTMLInputElement>) => void
  handleImperialChange: (event: ChangeEvent<HTMLInputElement>) => void
  handleMetricChange: (event: ChangeEvent<HTMLInputElement>) => void

  w2wPricesLoading: boolean
  w2wPricesError: unknown
}

export const WallToWallRugSize = memo(
  ({
    isPreviewConfirm = false,
    w2wPricesLoading,
    w2wPricesError,
    rollWidth,
    imperialValues,
    metricValues,
    imperialErrors,
    metricErrors,
    handleImperialBlur,
    handleMetricBlur,
    handleImperialChange,
    handleMetricChange,
  }: WallToWallRugSizeProps) => {
    const isDesktop = useMediaQuery('(min-width: 640px)')
    const { state, dispatch } = useRugBuilder()
    const { activeStep, customize, product, border } = state

    const [customizeValues, setCustomizeValues] = useState<Customize>({
      isComplete: false,
      isMetric: false,
      type: CustomizeType.WALL_TO_WALL,
      unitMeasure: { label: 'Square Yards', value: 'sqyd' },
    })

    const [isFocus, setIsFocus] = useState({
      sqm: false,
      lm: false,
      yd: false,
      sqft: false,
    })
    const getLinearFeetConversion = () => {
      if (customizeValues.isMetric) {
        switch (customizeValues.unitMeasure?.value) {
          case MeasureUnitKey.SQUARE_METER: {
            const lengthM = Number(customizeValues.sqm ?? 0) / Number(feetInchesToMConversion(rollWidth))
            return `${lengthM.toFixed(2)}m`
          }

          case MeasureUnitKey.LINEAR_METER: {
            const rollWidthMeters = Number(feetInchesToMConversion(rollWidth))
            return `${(Number(customizeValues.lm ?? 0) * rollWidthMeters).toFixed(2)}m\u00B2`
          }

          default:
            return '-'
        }
      } else {
        switch (customizeValues.unitMeasure?.value) {
          case MeasureUnitKey.SQUARE_YARD: {
            const sqft = Number(customizeValues.sqyd ?? 0) * 9
            const lengthFtIn = Number(sqft) / rollWidth
            const { feet, inches } = getFeetInches(lengthFtIn)
            return `${feet}' ${inches}''`
          }

          case MeasureUnitKey.SQUARE_FEET: {
            const lengthFtIn = Number(customizeValues.sqft ?? 0) / rollWidth

            const { feet, inches } = getFeetInches(lengthFtIn)
            return `${feet}' ${inches}''`
          }

          default:
            return '-'
        }
      }
    }

    const handleUnitMeasure = useCallback(
      (selectedOption: SelectOption) => {
        if (selectedOption.value !== customizeValues.unitMeasure?.value) {
          dispatch({
            type: RugBuilderActionTypes.HANDLE_CUSTOMIZE_IS_COMPLETE,
            isCompletePayload: false,
          })
          dispatch({
            type: RugBuilderActionTypes.SELECT_UNIT_MEASURE,
            selectOptionPayload: selectedOption,
          })
          dispatch({
            type: RugBuilderActionTypes.RESET_SUB_TOTAL_PRICE,
          })
          dispatch({
            type: RugBuilderActionTypes.RESET_CUSTOMIZE_SIZE_FIELDS,
          })
          dispatch({
            type: RugBuilderActionTypes.HANDLE_DIMENSION_ERROR,
            handleDimensionErrorPayload: true,
          })
          dispatch({
            type: RugBuilderActionTypes.RESET_BORDER_STEP,
          })
          dispatch({
            type: RugBuilderActionTypes.RESET_NOTES_STEP,
          })
          dispatch({
            type: RugBuilderActionTypes.RESET_ADDONS_STEP,
          })
        }
      },
      [customizeValues.unitMeasure?.value, dispatch],
    )

    const { feet, inches } = getFeetInches(rollWidth)

    const resetBorderAddonsCollapses = useCallback(() => {
      if (activeStep === OrderRugStepperIndexes.PREVIEW_CONFIRM) {
        dispatch({
          type: RugBuilderActionTypes.HANDLE_PREVIEW_CONFIRM_COLLAPSE,
          keyPreviewConfirmPayload: 'isAddonsCollapseComplete',
          valuePreviewConfirmPayload: false,
        })

        dispatch({
          type: RugBuilderActionTypes.RESET_PREVIEW_CONFIRM_ADDONS_COLLAPSE,
          isCompletePayload: true,
        })

        dispatch({
          type: RugBuilderActionTypes.RESET_SUB_TOTAL_PRICE,
        })
      }
    }, [activeStep, dispatch])

    useEffect(() => {
      setCustomizeValues(customize)
    }, [customize])

    return (
      <div className={`flex flex-col gap-4 ${isPreviewConfirm ? '-mt-10' : ''}`}>
        <div className={`text-sm ${isPreviewConfirm ? 'without-switch-btn' : ''}`}>
          {product && product.wallToWallDescription && <MlRichText text={product.wallToWallDescription} />}
        </div>

        {/* Select */}
        <div className="flex flex-col md:flex-row gap-2 md:gap-6">
          <div className="w-full md:w-1/2">
            {customizeValues.isMetric ? (
              <MlFormField
                key="select-isMetric"
                type="select"
                labelClassName="font-medium"
                label="Select Unit of Measure:"
                options={getMeasureUnitMetricOptions()}
                currentOption={customizeValues.unitMeasure}
                filterHandler={(selectedOption) => handleUnitMeasure(selectedOption)}
              />
            ) : (
              <MlFormField
                key="select-isImperial"
                labelClassName="font-medium"
                label="Select Unit of Measure:"
                type="select"
                options={getMeasureUnitImperialOptions()}
                currentOption={customizeValues.unitMeasure ?? getMeasureUnitImperialOptions()[0]}
                filterHandler={(selectedOption) => handleUnitMeasure(selectedOption)}
              />
            )}
          </div>

          <div className="w-full md:w-1/2">
            {/* METRIC INPUTS */}
            {customizeValues.isMetric && MeasureUnitKey.SQUARE_METER === customizeValues.unitMeasure?.value && (
              <MlFormField
                placeholder="Enter Your Measurement"
                label="&nbsp;"
                name="sqm"
                type="number"
                value={metricValues.sqm}
                isInputFocus={isFocus.sqm}
                error={!!metricErrors.sqm}
                disabled={w2wPricesLoading}
                errorMessage={metricErrors.sqm}
                rightIcon={metricErrors.sqm ? 'close-circle' : undefined}
                onChange={(e) => {
                  if (border.borderMaterial) {
                    dispatch({
                      type: RugBuilderActionTypes.RESET_ADDONS_STEP,
                    })

                    dispatch({
                      type: RugBuilderActionTypes.RESET_BORDER_STEP,
                    })
                  }
                  handleMetricChange(e)
                  dispatch({
                    type: RugBuilderActionTypes.RESET_SUB_TOTAL_PRICE,
                  })
                }}
                onBlur={(e) => {
                  resetBorderAddonsCollapses()
                  handleMetricBlur(e)
                  setIsFocus({ ...isFocus, sqm: false })
                }}
                onFocus={() => setIsFocus({ ...isFocus, sqm: true })}
                textDecoration="m"
              />
            )}

            {customizeValues.isMetric && MeasureUnitKey.LINEAR_METER === customizeValues.unitMeasure?.value && (
              <div className="flex flex-row gap-4">
                <div className="w-3/6">
                  <MlFormField
                    placeholder=""
                    disabled
                    label="Roll Width:"
                    name="roll_width"
                    value={`${feetInchesToMConversion(rollWidth)}m`}
                  />
                </div>

                <div className="w-3/6 flex flex-col gap-2">
                  <span className="text-sm leading-4 font-medium">Enter Your Length:</span>
                  <div className="flex flex-row gap-4 md:gap-1 lg:gap-4 -mt-6">
                    <MlFormField
                      placeholder="0"
                      label="&nbsp;"
                      name="lm"
                      type="number"
                      value={metricValues.lm}
                      disabled={w2wPricesLoading}
                      isInputFocus={isFocus.lm}
                      error={!!metricErrors.lm}
                      errorMessage={metricErrors.lm}
                      rightIcon={metricErrors.lm ? 'close-circle' : undefined}
                      onChange={(e) => {
                        if (border.borderMaterial) {
                          dispatch({
                            type: RugBuilderActionTypes.RESET_ADDONS_STEP,
                          })

                          dispatch({
                            type: RugBuilderActionTypes.RESET_BORDER_STEP,
                          })
                        }
                        handleMetricChange(e)
                        dispatch({
                          type: RugBuilderActionTypes.RESET_SUB_TOTAL_PRICE,
                        })
                      }}
                      onBlur={(e) => {
                        resetBorderAddonsCollapses()
                        handleMetricBlur(e)
                        setIsFocus({ ...isFocus, lm: false })
                      }}
                      onFocus={() => setIsFocus({ ...isFocus, lm: true })}
                      textDecoration="m"
                    />
                  </div>
                </div>
              </div>
            )}

            {/* IMPERIAL INPUTS */}
            {!customizeValues.isMetric && MeasureUnitKey.SQUARE_YARD === customizeValues.unitMeasure?.value && (
              <MlFormField
                placeholder="Enter Your Measurement"
                label="&nbsp;"
                name="sqyd"
                type="number"
                value={imperialValues.sqyd}
                disabled={w2wPricesLoading}
                isInputFocus={isFocus.yd}
                error={!!imperialErrors.sqyd}
                errorMessage={imperialErrors.sqyd}
                rightIcon={imperialErrors.sqyd ? 'close-circle' : undefined}
                onChange={(e) => {
                  if (border.borderMaterial) {
                    dispatch({
                      type: RugBuilderActionTypes.RESET_ADDONS_STEP,
                    })

                    dispatch({
                      type: RugBuilderActionTypes.RESET_BORDER_STEP,
                    })
                  }
                  handleImperialChange(e)
                  dispatch({
                    type: RugBuilderActionTypes.RESET_SUB_TOTAL_PRICE,
                  })
                }}
                onBlur={(e) => {
                  resetBorderAddonsCollapses()
                  handleImperialBlur(e)
                  setIsFocus({ ...isFocus, yd: false })
                }}
                onFocus={() => setIsFocus({ ...isFocus, yd: true })}
                textDecoration="yd"
              />
            )}

            {!customizeValues.isMetric && MeasureUnitKey.SQUARE_FEET === customizeValues.unitMeasure?.value && (
              <MlFormField
                placeholder="Enter Your Measurement"
                label="&nbsp;"
                name="sqft"
                type="number"
                disabled={w2wPricesLoading}
                value={imperialValues.sqft}
                isInputFocus={isFocus.sqft}
                error={!!imperialErrors.sqft}
                errorMessage={imperialErrors.sqft}
                rightIcon={imperialErrors.sqft ? 'close-circle' : undefined}
                onChange={(e) => {
                  if (border.borderMaterial) {
                    dispatch({
                      type: RugBuilderActionTypes.RESET_ADDONS_STEP,
                    })

                    dispatch({
                      type: RugBuilderActionTypes.RESET_BORDER_STEP,
                    })
                  }
                  handleImperialChange(e)
                  dispatch({
                    type: RugBuilderActionTypes.RESET_SUB_TOTAL_PRICE,
                  })
                }}
                onBlur={(e) => {
                  resetBorderAddonsCollapses()
                  handleImperialBlur(e)
                  setIsFocus({ ...isFocus, sqft: false })
                }}
                onFocus={() => setIsFocus({ ...isFocus, sqft: true })}
                textDecoration="ft"
              />
            )}

            {!!w2wPricesError && (
              <span className="text-error text-sm leading-4 -mt-3">{(w2wPricesError as AxiosError).message}</span>
            )}

            {!customizeValues.isMetric && MeasureUnitKey.LINEAR_FEET === customizeValues.unitMeasure?.value && (
              <div className="flex flex-row gap-4">
                <div className="w-3/6">
                  <MlFormField
                    placeholder=""
                    disabled
                    label="Roll Width:"
                    name="roll_width"
                    value={`${feet}' ${inches}''`}
                  />
                </div>

                <div className="w-3/6 flex flex-col gap-2">
                  <span className="text-sm leading-4 font-medium">Enter Your Length:</span>
                  <div className="flex flex-row gap-4 md:gap-1 lg:gap-4 -mt-6">
                    <div className="w-1/2">
                      <MlFormField
                        placeholder="Ft."
                        name="lft"
                        type="number"
                        value={imperialValues.lft}
                        error={!!imperialErrors.lft}
                        errorMessage={imperialErrors.lft}
                        rightIcon={imperialErrors.lft ? 'close-circle' : undefined}
                        onChange={handleImperialChange}
                        onBlur={(e) => {
                          resetBorderAddonsCollapses()
                          handleImperialBlur(e)
                        }}
                        label="&nbsp;"
                      />
                    </div>

                    <div className="w-1/2">
                      <MlFormField
                        placeholder="In."
                        name="lftIn"
                        type="number"
                        label="&nbsp;"
                        value={imperialValues.lftIn}
                        error={!!imperialErrors.lftIn}
                        errorMessage={imperialErrors.lftIn}
                        onChange={handleImperialChange}
                        onBlur={(e) => {
                          resetBorderAddonsCollapses()
                          handleImperialBlur(e)
                        }}
                        rightIcon={imperialErrors.lftIn ? 'close-circle' : undefined}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="flex flex-row gap-4 mt-4">
              {MeasureUnitKey.LINEAR_FEET === customizeValues.unitMeasure?.value ||
              MeasureUnitKey.LINEAR_METER === customizeValues.unitMeasure?.value ? null : (
                <div className="w-3/6">
                  <MlFormField
                    placeholder=""
                    disabled
                    label="Roll Width:"
                    name="roll_width"
                    value={customizeValues.isMetric ? `${feetInchesToMConversion(rollWidth)}m` : `${feet}' ${inches}''`}
                  />
                </div>
              )}

              <div
                className={
                  MeasureUnitKey.LINEAR_FEET === customizeValues.unitMeasure?.value ||
                  MeasureUnitKey.LINEAR_METER === customizeValues.unitMeasure?.value
                    ? 'w-full'
                    : 'w-3/6'
                }
              >
                {MeasureUnitKey.LINEAR_FEET === customizeValues.unitMeasure?.value ? (
                  <MlFormField
                    placeholder=""
                    disabled
                    label="Square Yard Conversion:"
                    name="sqydConversion"
                    value={`${(
                      (rollWidth *
                        feetInchesToFeetConversion(
                          Number(customizeValues.lft ?? 0),
                          Number(customizeValues.lftIn ?? 0),
                        )) /
                      9
                    ).toFixed(1)} ${MeasureUnitKey.SQUARE_YARD}`}
                  />
                ) : (
                  <MlFormField
                    placeholder=""
                    disabled
                    label={
                      MeasureUnitKey.LINEAR_METER === customizeValues.unitMeasure?.value
                        ? 'Square Meter Conversion:'
                        : isDesktop
                        ? 'Linear Feet Conversion:'
                        : 'LF Conversion:'
                    }
                    name="lftConversion"
                    value={getLinearFeetConversion()}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  },
)

export const WallToWallType = () => {
  const { state, dispatch } = useRugBuilder()
  const { customize, product, subTotalPrice, customeStepFilled, addons } = state

  const { fetchWallToWallAreaPrices } = useRugBuilderPrices()
  const { WallToWallRugMetricSchema, WallToWallRugImperialSchema } = useRugTypeValidations()

  const initialImperialValues: W2WImperialValues = {
    sqyd: '',
    sqft: '',
    lft: '',
    lftIn: '',
  }

  const initialMetricValues: W2WMetricValues = {
    sqm: '',
    lm: '',
  }

  useEffect(() => {
    if (customeStepFilled && customize.type === CustomizeType.WALL_TO_WALL) {
      form.current?.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customeStepFilled, customize.type])

  const debouncedHandler = useDebouncedCallback((name: string, value: string) => {
    dispatch({
      type: RugBuilderActionTypes.SET_CUSTOMIZE_SIZE_FIELDS,
      fieldKeyPayload: name as CustomizeFieldKey,
      fieldValuePayload: value,
    })
  }, 200)

  const rollWidth =
    !!product && !!product.parameters && (product.parameters as WeaveParametersSuccess)['roll_width']
      ? Number((product.parameters as WeaveParametersSuccess)['roll_width']) ?? 0
      : 0

  const blMinLength =
    !!product && !!product.parameters && (product.parameters as WeaveParametersSuccess)['bl_min_length']
      ? (product.parameters as WeaveParametersSuccess)['bl_min_length'] ?? 0
      : 0

  const [w2wPricesLoading, setW2WPricesLoading] = useState(false)
  const [w2wPricesError, setW2WPricesError] = useState<unknown>({})

  const hasRushPrice = !(
    (product?.parameters as WeaveParametersSuccess)?.rush_price === 0 ||
    (product?.parameters as WeaveParametersSuccess)?.rush_price === null
  )

  const supplies = useMemo(() => product && getFlooringSupplies(product, customize), [product, customize]) ?? []

  const submitHandler = async () => {
    return fetchWallToWallAreaPrices({
      weave: product?.CONTENTFUL_ID ?? '',
      unitMeasure: customize.unitMeasure?.value as MeasureUnitKey,
      isMetric: customize.isMetric,
      sqYd: Number(customize.sqyd ?? 0),
      sqFt: Number(customize.sqft ?? 0),
      lft: Number(customize.lft ?? 0),
      lftIn: Number(customize.lftIn ?? 0),
      lm: Number(customize.lm ?? 0),
      sqM: Number(customize.sqm ?? 0),
      setLoading: setW2WPricesLoading,
      setErrors: (err) => {
        setW2WPricesError(err)
        dispatch({
          type: RugBuilderActionTypes.CUSTOME_STEP_FILLED,
          isCustomeStepFilled: false,
        })
      },
      onSuccess: () => {
        if (!!addons.rugpad || !!addons.supplies) {
          dispatch({
            type: RugBuilderActionTypes.RESET_ADDONS_STEP,
          })
        }

        dispatch({
          type: RugBuilderActionTypes.HANDLE_CUSTOMIZE_IS_COMPLETE,
          isCompletePayload: true,
        })

        dispatch({
          type: RugBuilderActionTypes.HANDLE_BORDER_IS_COMPLETE,
          isCompletePayload: true,
        })

        if (!hasRushPrice && product && supplies.length === 0) {
          dispatch({
            type: RugBuilderActionTypes.HANDLE_ADDONS_IS_COMPLETE,
            isCompletePayload: true,
          })
        }

        dispatch({
          type: RugBuilderActionTypes.SET_SUB_TOTAL_PRICE,
        })

        dispatch({
          type: RugBuilderActionTypes.HANDLE_NEXT_STEP,
          ...(hasRushPrice && product && product.addons ? { stepPayload: 2 } : { stepPayload: 3 }),
        })
      },
      onFailure: () => {
        dispatch({
          type: RugBuilderActionTypes.CUSTOME_STEP_FILLED,
          isCustomeStepFilled: false,
        })

        dispatch({
          type: RugBuilderActionTypes.HANDLE_API_ERRORS,
          hasApiErrorPayload: true,
        })
      },
    })
  }

  const {
    errors: imperialErrors,
    handleBlur: handleImperialBlur,
    handleChange: handleImperialChange,
    handleSubmit: handleImperialSubmit,
    values: imperialValues,
    resetValues: resetImperialValues,
  } = useForm(
    initialImperialValues,
    submitHandler,
    WallToWallRugImperialSchema({
      measureUnit: customize.unitMeasure?.value as MeasureUnitKey,
      blMinLength,
      rollWidth,
    }),
    debouncedHandler,
  )

  const {
    errors: metricErrors,
    handleBlur: handleMetricBlur,
    handleChange: handleMetricChange,
    handleSubmit: handleMetricSubmit,
    values: metricValues,
    resetValues: resetMetricValues,
  } = useForm(
    initialMetricValues,
    submitHandler,
    WallToWallRugMetricSchema({
      measureUnit: customize.unitMeasure?.value as MeasureUnitKey,
      blMinLength,
      rollWidth,
    }),
    debouncedHandler,
  )

  const handleToggleMetricChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({
        type: RugBuilderActionTypes.SET_CUSTOMIZE_IS_METRIC,
      })

      if (e.target.checked) {
        resetMetricValues()
        dispatch({
          type: RugBuilderActionTypes.SELECT_UNIT_MEASURE,
          selectOptionPayload: getMeasureUnitMetricOptions()[0],
        })
      } else {
        resetImperialValues()
        dispatch({
          type: RugBuilderActionTypes.SELECT_UNIT_MEASURE,
          selectOptionPayload: getMeasureUnitImperialOptions()[0],
        })
      }
      dispatch({
        type: RugBuilderActionTypes.RESET_CUSTOMIZE_SIZE_FIELDS,
      })
      dispatch({
        type: RugBuilderActionTypes.HANDLE_CUSTOMIZE_IS_COMPLETE,
        isCompletePayload: false,
      })
      resetImperialValues()
      resetMetricValues()
      dispatch({
        type: RugBuilderActionTypes.RESET_BORDER_STEP,
      })
      dispatch({
        type: RugBuilderActionTypes.RESET_NOTES_STEP,
      })
      dispatch({
        type: RugBuilderActionTypes.RESET_ADDONS_STEP,
      })
      dispatch({
        type: RugBuilderActionTypes.RESET_SUB_TOTAL_PRICE,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, resetImperialValues, resetMetricValues],
  )
  useEffect(() => {
    resetImperialValues()
    resetMetricValues()
    dispatch({
      type: RugBuilderActionTypes.HANDLE_DIMENSION_ERROR,
      handleDimensionErrorPayload: true,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customize.type, customize.unitMeasure])

  const form = useRef<HTMLFormElement>(null)

  useEffect(() => {
    if (product) {
      resetImperialValues()
      resetMetricValues()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product])

  return (
    <form ref={form} onSubmit={customize.isMetric ? handleMetricSubmit : handleImperialSubmit}>
      <div className="flex flex-col gap-4">
        <h3 className="text-lg font-medium py-2">
          Choose Weave Color{customize.weaveColor?.weaveColorName ? ': ' : ''}
          <span className="font-normal text-base">{customize.weaveColor?.weaveColorName ?? ''}</span>{' '}
        </h3>
        <ColorSlider weaveColors={product?.colorGroup?.weaveColors ?? []} showSwatch />

        {/* we can keep this component like a reference */}
        {/* <WeaveColorOptions isLoading={rectanglePricesLoading} options={product?.colorGroup?.weaveColors ?? []} /> */}
      </div>

      <hr className="text-outlined-gray mb-0 mt-8 py-4" />
      <div className="flex flex-row justify-between">
        <h3 className="text-lg font-medium py-2">Enter Size</h3>

        <div className="w-28 self-center">
          <AtControl
            name="wallToWallMetric"
            label="Metric"
            type="control"
            checked={customize.isMetric}
            onChange={handleToggleMetricChange}
          />
        </div>
      </div>

      <WallToWallRugSize
        rollWidth={rollWidth}
        imperialValues={imperialValues}
        metricValues={metricValues}
        imperialErrors={imperialErrors}
        metricErrors={metricErrors}
        handleImperialBlur={handleImperialBlur}
        handleMetricBlur={handleMetricBlur}
        handleImperialChange={handleImperialChange}
        handleMetricChange={handleMetricChange}
        w2wPricesLoading={w2wPricesLoading}
        w2wPricesError={w2wPricesError}
      />

      {!hideOrderRugControls && (
        <OrderRugControls
          subtotal={subTotalPrice}
          handleNextStep={() => form.current?.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }))}
          isNextStepEnabled={(customize.weaveColor ?? false) && !w2wPricesLoading}
          nextStepLabel={w2wPricesLoading ? 'Loading' : 'Continue'}
          isSubmitting={w2wPricesLoading}
        />
      )}
    </form>
  )
}
