import * as Yup from 'yup'

import { YupSchemaObject } from '@hooks/use-form'
import { RectangularCmValues, RectangularFtInValues } from '../rectangular-rug'
import { CircularCmValues, CircularFtInValues } from '../circular-rug'
import {
  feetInchesToFeetConversion,
  getFeetInches,
  rectangleCmConversion,
  rectangleSqFtConversion,
  sqFtToCmConversion,
  sqFtToM2Conversion,
  feetInchesToCmConversion,
  feetInchesToMConversion,
} from '../utils'
import { W2WImperialValues, W2WMetricValues } from '../wall-to-wall'
import { DEFAULT_ERROR_MESSAGE_MAX_INCHES_VALUE, MAX_INCHES_VALUE, MeasureUnitKey } from '@utils/measures'

export const useRugTypeValidations = () => {
  const RectangularRugFtInSchema: ({
    cfMinSqFt,
    cfMaxSeams,
    rollWidth,
  }: {
    cfMinSqFt: number
    cfMaxSeams: number
    rollWidth: number
    messageForMaxInchValue?: string
  }) => YupSchemaObject<RectangularFtInValues> = ({ cfMinSqFt, cfMaxSeams, rollWidth, messageForMaxInchValue }) => ({
    widthFt: Yup.lazy((value) =>
      value === ''
        ? Yup.string().required('Required')
        : Yup.number()
            .required('Required')
            .integer('Decimals are not allowed')
            // Min Validation
            .when(['widthFt', 'widthIn', 'lengthFt', 'lengthIn'], {
              is: (
                widthFt: string | number,
                widthIn: string | number,
                lengthFt: string | number,
                lengthIn: string | number,
              ) =>
                widthFt &&
                lengthFt &&
                rectangleSqFtConversion({
                  widthFt: Number(widthFt),
                  widthIn: Number(widthIn),
                  lengthFt: Number(lengthFt),
                  lengthIn: Number(lengthIn),
                }) < cfMinSqFt,
              then: (schema) => schema.min(cfMinSqFt, `Min. ${cfMinSqFt} sq.ft.`),
              // Max Validation
              otherwise: (schema) =>
                schema.when(['widthFt', 'widthIn'], {
                  is: (widthFt: string | number, widthIn: string | number) => {
                    const maxValue = !cfMaxSeams ? rollWidth : rollWidth + rollWidth * cfMaxSeams
                    return feetInchesToFeetConversion(Number(widthFt), Number(widthIn)) > maxValue
                  },
                  then: (schema) => {
                    const { feet, inches } = getFeetInches(!cfMaxSeams ? rollWidth : rollWidth + rollWidth * cfMaxSeams)
                    return schema
                      .max(feet, `Max. ${feet ? `Width ${feet}'-` : ''}${inches ? `${inches}''` : ''}.`)
                      .oneOf(
                        [Yup.ref('widthIn'), null],
                        `Max. ${feet ? `Width ${feet}'-` : ''}${inches ? `${inches}''` : ''}.`,
                      )
                  },
                  otherwise: (schema) => schema,
                }),
            }),
    ),
    widthIn: Yup.lazy((value) =>
      value === ''
        ? Yup.string()
        : Yup.number()
            .integer('Decimals are not allowed')
            // Min Validation
            .when(['widthFt', 'widthIn', 'lengthFt', 'lengthIn'], {
              is: (
                widthFt: string | number,
                widthIn: string | number,
                lengthFt: string | number,
                lengthIn: string | number,
              ) =>
                widthFt &&
                lengthFt &&
                rectangleSqFtConversion({
                  widthFt: Number(widthFt),
                  widthIn: Number(widthIn),
                  lengthFt: Number(lengthFt),
                  lengthIn: Number(lengthIn),
                }) < cfMinSqFt,
              then: (schema) => schema.min(cfMinSqFt, ' '),
              // Max Validation
              otherwise: (schema) =>
                schema.when(['widthFt', 'widthIn'], {
                  is: (widthFt: string | number, widthIn: string | number) => {
                    const maxValue = !cfMaxSeams ? rollWidth : rollWidth + rollWidth * cfMaxSeams
                    return feetInchesToFeetConversion(Number(widthFt), Number(widthIn)) > maxValue
                  },
                  then: (schema) => {
                    return schema.max(
                      MAX_INCHES_VALUE,
                      messageForMaxInchValue ?? DEFAULT_ERROR_MESSAGE_MAX_INCHES_VALUE,
                    )
                  },
                  otherwise: (schema) =>
                    schema.max(MAX_INCHES_VALUE, messageForMaxInchValue ?? DEFAULT_ERROR_MESSAGE_MAX_INCHES_VALUE),
                }),
            }),
    ),
    lengthFt: Yup.lazy((value) =>
      value === ''
        ? Yup.string().required('Required')
        : Yup.number()
            .required('Required')
            .integer('Decimals are not allowed')
            // Min Validation
            .when(['widthFt', 'widthIn', 'lengthFt', 'lengthIn'], {
              is: (
                widthFt: string | number,
                widthIn: string | number,
                lengthFt: string | number,
                lengthIn: string | number,
              ) =>
                widthFt &&
                lengthFt &&
                rectangleSqFtConversion({
                  widthFt: Number(widthFt),
                  widthIn: Number(widthIn),
                  lengthFt: Number(lengthFt),
                  lengthIn: Number(lengthIn),
                }) < cfMinSqFt,
              then: (schema) => schema.min(cfMinSqFt, ' '),
              otherwise: (schema) => schema,
            }),
    ),
    lengthIn: Yup.lazy((value) =>
      value === ''
        ? Yup.string()
        : Yup.number()
            .integer('Decimals are not allowed')
            // Min Validation
            .when(['widthFt', 'widthIn', 'lengthFt', 'lengthIn'], {
              is: (
                widthFt: string | number,
                widthIn: string | number,
                lengthFt: string | number,
                lengthIn: string | number,
              ) =>
                widthFt &&
                lengthFt &&
                rectangleSqFtConversion({
                  widthFt: Number(widthFt),
                  widthIn: Number(widthIn),
                  lengthFt: Number(lengthFt),
                  lengthIn: Number(lengthIn),
                }) < cfMinSqFt,
              then: (schema) => schema.min(cfMinSqFt, ' '),
              otherwise: (schema) =>
                schema.max(MAX_INCHES_VALUE, messageForMaxInchValue ?? DEFAULT_ERROR_MESSAGE_MAX_INCHES_VALUE),
            }),
    ),
  })

  const RectangularRugCmSchema: ({
    cfMinSqFt,
    cfMaxSeams,
    rollWidth,
  }: {
    cfMinSqFt: number
    cfMaxSeams: number
    rollWidth: number
  }) => YupSchemaObject<RectangularCmValues> = ({ cfMinSqFt, cfMaxSeams, rollWidth }) => ({
    widthCm: Yup.lazy((value) =>
      value === ''
        ? Yup.string().required('Required')
        : Yup.number()
            .required('Required')
            .integer('Decimals are not allowed')
            // Min Validation
            .when(['widthCm', 'lengthCm'], {
              is: (widthCm: string | number, lengthCm: string | number) =>
                widthCm &&
                lengthCm &&
                rectangleCmConversion({
                  widthCm: Number(widthCm ?? 0),
                  lengthCm: Number(lengthCm ?? 0),
                }) < sqFtToCmConversion({ sqFt: cfMinSqFt }),
              then: (schema) =>
                schema.min(
                  sqFtToCmConversion({ sqFt: cfMinSqFt }),
                  `Min. ${sqFtToM2Conversion({ sqFt: cfMinSqFt })}m\u00B2.`,
                ),
              // Max Validation
              otherwise: (schema) =>
                schema.when(['widthCm'], {
                  is: (widthCm: string | number) => {
                    const maxValue = !cfMaxSeams ? rollWidth : rollWidth + rollWidth * cfMaxSeams
                    return Number(widthCm) > feetInchesToCmConversion(maxValue)
                  },
                  then: (schema) =>
                    schema.max(
                      feetInchesToCmConversion(!cfMaxSeams ? rollWidth : rollWidth + rollWidth * cfMaxSeams),
                      `Max. ${feetInchesToCmConversion(
                        !cfMaxSeams ? rollWidth : rollWidth + rollWidth * cfMaxSeams,
                      )}cm.`,
                    ),
                  otherwise: (schema) => schema,
                }),
            }),
    ),
    lengthCm: Yup.lazy((value) =>
      value === ''
        ? Yup.string().required('Required')
        : Yup.number()
            .required('Required')
            .integer('Decimals are not allowed')
            // Min Validation
            .when(['widthCm', 'lengthCm'], {
              is: (widthCm: string | number, lengthCm: string | number) =>
                widthCm &&
                lengthCm &&
                rectangleCmConversion({
                  widthCm: Number(widthCm ?? 0),
                  lengthCm: Number(lengthCm ?? 0),
                }) < sqFtToCmConversion({ sqFt: cfMinSqFt }),
              then: (schema) => schema.min(sqFtToCmConversion({ sqFt: cfMinSqFt }), ' '),
              otherwise: (schema) => schema,
            }),
    ),
  })

  const CircularRugFtInSchema: ({
    cfMinDiameter,
    cfMaxSeams,
    rollWidth,
    messageForMaxInchValue,
  }: {
    cfMinDiameter: number
    cfMaxSeams: number
    rollWidth: number
    messageForMaxInchValue?: string
  }) => YupSchemaObject<CircularFtInValues> = ({ cfMinDiameter, cfMaxSeams, rollWidth, messageForMaxInchValue }) => ({
    diameterFt: Yup.lazy((value) =>
      value === ''
        ? Yup.string().required('Required')
        : Yup.number()
            .required('Required')
            .integer('Decimals are not allowed')
            // Min Validation
            .when(['diameterFt', 'diameterIn'], {
              is: (diameterFt: string | number, diameterIn: string | number) =>
                diameterFt && feetInchesToFeetConversion(Number(diameterFt), Number(diameterIn)) < cfMinDiameter,
              then: (schema) => {
                const { feet } = getFeetInches(cfMinDiameter)
                return schema.min(feet, `Min. ${feet}'.`)
              },
              otherwise: (schema) =>
                schema
                  // Max Validation
                  .when(['diameterFt', 'diameterIn'], {
                    is: (diameterFt: string | number, diameterIn: string | number) => {
                      const maxValue = !cfMaxSeams ? rollWidth : rollWidth + rollWidth * cfMaxSeams
                      return feetInchesToFeetConversion(Number(diameterFt), Number(diameterIn)) > maxValue
                    },
                    then: (schema) => {
                      const { feet, inches } = getFeetInches(
                        !cfMaxSeams ? rollWidth : rollWidth + rollWidth * cfMaxSeams,
                      )
                      return schema
                        .max(feet, `Max. ${feet ? `Width ${feet}'-` : ''}${inches ? `${inches}''` : ''}.`)
                        .oneOf(
                          [Yup.ref('widthIn'), null],
                          `Max. ${feet ? `Width ${feet}'-` : ''}${inches ? `${inches}''` : ''}.`,
                        )
                    },
                    otherwise: (schema) => schema,
                  }),
            }),
    ),
    diameterIn: Yup.lazy((value) =>
      value === ''
        ? Yup.string()
        : Yup.number()
            .integer('Decimals are not allowed')
            // Min Validation
            .when(['diameterFt', 'diameterIn'], {
              is: (diameterFt: string | number, diameterIn: string | number) =>
                feetInchesToFeetConversion(Number(diameterFt), Number(diameterIn)) < cfMinDiameter,
              then: (schema) => {
                const { inches } = getFeetInches(cfMinDiameter)
                return schema.min(inches, `Min. ${inches}'.`)
              },
              otherwise: (schema) =>
                schema
                  // Max Validation
                  .when(['diameterFt', 'diameterIn'], {
                    is: (diameterFt: string | number, diameterIn: string | number) => {
                      const maxValue = !cfMaxSeams ? rollWidth : rollWidth + rollWidth * cfMaxSeams
                      return feetInchesToFeetConversion(Number(diameterFt), Number(diameterIn)) > maxValue
                    },
                    then: (schema) => {
                      return schema.max(
                        MAX_INCHES_VALUE,
                        messageForMaxInchValue ?? DEFAULT_ERROR_MESSAGE_MAX_INCHES_VALUE,
                      )
                    },
                    otherwise: (schema) =>
                      schema.max(MAX_INCHES_VALUE, messageForMaxInchValue ?? DEFAULT_ERROR_MESSAGE_MAX_INCHES_VALUE),
                  }),
            }),
    ),
  })

  const CircularRugCmSchema: ({
    cfMinDiameter,
    cfMaxSeams,
    rollWidth,
  }: {
    cfMinDiameter: number
    cfMaxSeams: number
    rollWidth: number
  }) => YupSchemaObject<CircularCmValues> = ({ cfMaxSeams, cfMinDiameter, rollWidth }) => ({
    diameterCm: Yup.lazy((value) =>
      value === ''
        ? Yup.string().required('Required')
        : Yup.number()
            .required('Required')
            .integer('Decimals are not allowed') // Min Validation
            .when(['diameterCm'], {
              is: (diameterCm: string | number) => {
                return Number(diameterCm) < feetInchesToCmConversion(cfMinDiameter)
              },
              then: (schema) =>
                schema.min(
                  feetInchesToCmConversion(cfMinDiameter),
                  `Min. ${feetInchesToCmConversion(cfMinDiameter)}cm.`,
                ),
              // Max Validation
              otherwise: (schema) =>
                schema.when(['diameterCm'], {
                  is: (diameterCm: string | number) => {
                    const maxValue = !cfMaxSeams ? rollWidth : rollWidth + rollWidth * cfMaxSeams
                    return Number(diameterCm) > feetInchesToCmConversion(maxValue)
                  },
                  then: (schema) =>
                    schema.max(
                      feetInchesToCmConversion(!cfMaxSeams ? rollWidth : rollWidth + rollWidth * cfMaxSeams),
                      `Max. ${feetInchesToCmConversion(
                        !cfMaxSeams ? rollWidth : rollWidth + rollWidth * cfMaxSeams,
                      )}cm.`,
                    ),
                  otherwise: (schema) => schema,
                }),
            }),
    ),
  })

  const sqydSchemaValidation: ({
    blMinLength,
    rollWidth,
    messageForMinValue,
  }: {
    blMinLength: number
    rollWidth: number
    messageForMinValue?: string
  }) => YupSchemaObject<W2WImperialValues> = ({ blMinLength, rollWidth, messageForMinValue }) => ({
    sqyd: Yup.lazy((value) =>
      value === ''
        ? Yup.string().required('Required')
        : Yup.number()
            .integer('Decimals are not allowed')
            // Min Validation
            .when(['sqyd'], {
              is: (sqyd: string | number) => Number(sqyd) < Math.round((rollWidth * blMinLength) / 9),
              then: (schema) => {
                const minSqydValue = Math.round((rollWidth * blMinLength) / 9)

                const matches = messageForMinValue?.includes('{sqydValue}')
                const message = matches
                  ? messageForMinValue?.replace('{sqydValue}', String(minSqydValue))
                  : `Min. ${minSqydValue} sq.yd.`
                return schema.min(minSqydValue, message)
              },
              otherwise: (schema) => schema,
            }),
    ),
    sqft: Yup.lazy(() => Yup.string().notRequired()),
    lft: Yup.lazy(() => Yup.string().notRequired()),
    lftIn: Yup.lazy(() => Yup.string().notRequired()),
    sqm: Yup.lazy(() => Yup.string().notRequired()),
    lm: Yup.lazy(() => Yup.string().notRequired()),
  })

  const sqftSchemaValidation: ({
    blMinLength,
    rollWidth,
    messageForMinValue,
  }: {
    blMinLength: number
    rollWidth: number
    messageForMinValue?: string
  }) => YupSchemaObject<W2WImperialValues> = ({ blMinLength, rollWidth, messageForMinValue }) => ({
    sqyd: Yup.lazy(() => Yup.string().notRequired()),
    sqft: Yup.lazy((value) =>
      value === ''
        ? Yup.string().required('Required')
        : Yup.number()
            .required('Required')
            .integer('Decimals are not allowed')
            // Min Validation
            .when(['sqft'], {
              is: (sqft: string | number) => Number(sqft) < Math.round(rollWidth * blMinLength),
              then: (schema) => {
                const minSqftValue = Math.round(rollWidth * blMinLength)
                const matches = messageForMinValue?.includes('{sqftValue}')
                const message = matches
                  ? messageForMinValue?.replace('{sqftValue}', String(minSqftValue))
                  : `Min. ${minSqftValue} sq.ft.`

                return schema.min(minSqftValue, message)
              },
              otherwise: (schema) => schema,
            }),
    ),
    lft: Yup.lazy(() => Yup.string().notRequired()),
    lftIn: Yup.lazy(() => Yup.string().notRequired()),
    sqm: Yup.lazy(() => Yup.string().notRequired()),
    lm: Yup.lazy(() => Yup.string().notRequired()),
  })

  const lftInSchemaValidation: ({
    blMinLength,
    messageForMinValue,
    messageForMaxInchValue,
  }: {
    blMinLength: number
    messageForMinValue?: string
    messageForMaxInchValue?: string
  }) => YupSchemaObject<W2WImperialValues> = ({ blMinLength, messageForMinValue, messageForMaxInchValue }) => ({
    sqyd: Yup.lazy(() => Yup.string().notRequired()),
    sqft: Yup.lazy(() => Yup.string().notRequired()),
    lft: Yup.lazy((value) =>
      value === ''
        ? Yup.string().required('Required')
        : Yup.number()
            .integer('Decimals are not allowed')
            // Min Validation
            .when(['lft', 'lftIn'], {
              is: (lft: string | number, lftIn: string | number) =>
                feetInchesToFeetConversion(Number(lft), Number(lftIn)) < blMinLength,
              then: (schema) => {
                const minValue = Math.round(blMinLength)
                const { feet, inches } = getFeetInches(blMinLength)
                const matches = messageForMinValue?.includes('{lftInValue}')
                const message = matches
                  ? messageForMinValue?.replace('{lftInValue}', `${feet}' ${inches}''`)
                  : `Min. ${feet}' ${inches}''`

                return schema.min(minValue, message)
              },
              otherwise: (schema) => schema,
            }),
    ),
    lftIn: Yup.lazy((value) =>
      value === ''
        ? Yup.string()
        : Yup.number()
            .integer('Decimals are not allowed')
            .max(MAX_INCHES_VALUE, messageForMaxInchValue ?? DEFAULT_ERROR_MESSAGE_MAX_INCHES_VALUE),
    ),
    sqm: Yup.lazy(() => Yup.string().notRequired()),
    lm: Yup.lazy(() => Yup.string().notRequired()),
  })

  const sqmSchemaValidation: ({
    rollWidth,
    blMinLength,
    messageForMinValue,
  }: {
    rollWidth: number
    blMinLength: number
    messageForMinValue?: string
  }) => YupSchemaObject<W2WMetricValues> = ({ blMinLength, rollWidth, messageForMinValue }) => ({
    sqyd: Yup.lazy(() => Yup.string().notRequired()),
    sqft: Yup.lazy(() => Yup.string().notRequired()),
    lft: Yup.lazy(() => Yup.string().notRequired()),
    lftIn: Yup.lazy(() => Yup.string().notRequired()),
    sqm: Yup.lazy((value) =>
      value === ''
        ? Yup.string().required('Required')
        : Yup.number()
            .required('Required')
            .integer('Decimals are not allowed')
            // Min validation
            .when(['sqm'], {
              is: (sqm: string | number) => {
                return Number(sqm) < sqFtToM2Conversion({ sqFt: Math.round(rollWidth * blMinLength) })
              },
              then: (schema) => {
                const minValue = sqFtToM2Conversion({ sqFt: Math.round(rollWidth * blMinLength) })
                const matches = messageForMinValue?.includes('{sqmValue}')
                const message = matches
                  ? messageForMinValue?.replace('{sqmValue}', String(minValue))
                  : `Min. ${minValue}m\u00B2.`
                return schema.min(minValue, message)
              },
              otherwise: (schema) => schema,
            }),
    ),
    lm: Yup.lazy(() => Yup.string().notRequired()),
  })

  const lmSchemaValidation: ({
    rollWidth,
    blMinLength,
    messageForMinValue,
  }: {
    rollWidth: number
    blMinLength: number
    messageForMinValue?: string
  }) => YupSchemaObject<W2WMetricValues> = ({ blMinLength, rollWidth, messageForMinValue }) => ({
    sqyd: Yup.lazy(() => Yup.string().notRequired()),
    sqft: Yup.lazy(() => Yup.string().notRequired()),
    lft: Yup.lazy(() => Yup.string().notRequired()),
    lftIn: Yup.lazy(() => Yup.string().notRequired()),
    sqm: Yup.lazy(() => Yup.string().notRequired()),
    lm: Yup.lazy((value) =>
      value === ''
        ? Yup.string().required('Required')
        : Yup.number()
            .required('Required')
            .integer('Decimals are not allowed')
            // Min validation
            .when(['lm'], {
              is: (lm: string | number) => {
                return (
                  Number(lm) <
                  sqFtToM2Conversion({ sqFt: Math.round(rollWidth * blMinLength) }) /
                    Number(feetInchesToMConversion(Math.round(rollWidth)))
                )
              },
              then: (schema) => {
                const minValue =
                  sqFtToM2Conversion({ sqFt: Math.round(rollWidth * blMinLength) }) /
                  Number(feetInchesToMConversion(rollWidth))

                const matches = messageForMinValue?.includes('{lmValue}')
                const message = matches
                  ? messageForMinValue?.replace('{lmValue}', String(minValue.toFixed(2)))
                  : `Min. ${minValue.toFixed(2)} m.`

                return schema.min(Number(minValue.toFixed(2)), message)
              },
              otherwise: (schema) => schema,
            }),
    ),
  })

  /**
   * Yup schema validation for w2w type = imperial
   * @param measureUnit measure of unit eg: square yard | square feet | linear feet
   * @param blMinLength minimum length for broadloom
   * @param rollWidth width by default
   * @param messageForMinValue custom error message displayed for min value, supports a computed value in the message. eg: `Min value is ({sqydValue} | {sqftValue} | {lftInValue}).`
   * @param messageForMaxInchValue custom error message displayed for max inches value. Only available `lftInSchemaValidation` func
   * @returns a YupSchema
   */
  const WallToWallRugImperialSchema = ({
    measureUnit,
    blMinLength,
    rollWidth,
    messageForMinValue,
    messageForMaxInchValue,
  }: {
    measureUnit: MeasureUnitKey
    blMinLength: number
    rollWidth: number
    messageForMinValue?: string
    messageForMaxInchValue?: string
  }) => {
    switch (measureUnit) {
      case MeasureUnitKey.SQUARE_YARD:
        return sqydSchemaValidation({ blMinLength, rollWidth, messageForMinValue })

      case MeasureUnitKey.SQUARE_FEET:
        return sqftSchemaValidation({ blMinLength, rollWidth, messageForMinValue })

      default:
        return lftInSchemaValidation({ blMinLength, messageForMinValue, messageForMaxInchValue })
    }
  }

  /**
   * Yup schema validation for w2w type = metric
   * @param measureUnit measure of unit eg: square meter | linear meter
   * @param blMinLength minimum length for broadloom
   * @param rollWidth width by default
   * @param messageForMinValue custom error message displayed for min value, supports a computed value in the message. eg: `Min value is ({sqmValue} | {lmValue}).`
   * @returns a YupSchema
   */
  const WallToWallRugMetricSchema = ({
    measureUnit,
    blMinLength,
    rollWidth,
    messageForMinValue,
  }: {
    measureUnit: MeasureUnitKey
    blMinLength: number
    rollWidth: number
    messageForMinValue?: string
  }) => {
    switch (measureUnit) {
      case MeasureUnitKey.SQUARE_METER:
        return sqmSchemaValidation({ blMinLength, rollWidth, messageForMinValue })

      case MeasureUnitKey.LINEAR_METER:
        return lmSchemaValidation({ blMinLength, rollWidth, messageForMinValue })

      default:
        return undefined
    }
  }

  return {
    RectangularRugFtInSchema,
    RectangularRugCmSchema,
    CircularRugFtInSchema,
    CircularRugCmSchema,
    WallToWallRugMetricSchema,
    WallToWallRugImperialSchema,
  }
}
