import { useMemo } from 'react'

import { RugBuilderActionTypes } from '@context/rug-builder'
import { useRugBuilder } from '@hooks/use-rug-builder'
import { EditButton } from '@components/flooring-product-detail'
import { CustomizeButtonTypes, StepCustomizeType } from '../customize'
import { useValidatePreviewSteps } from './hooks'
import { CustomizeType } from 'types'

export const CustomizeRugTypesEditable = () => {
  const { state, dispatch } = useRugBuilder()
  const { product, customize } = state

  const { isOpenCollapse, handleCollapse, openNextCollapse } = useValidatePreviewSteps({
    steps: ['weaveColor', 'size', 'border', 'addons', 'notes'],
    currentStep: 'type',
    nextStep: 'weaveColor',
  })

  const types: StepCustomizeType[] = useMemo(
    () => [
      {
        title: CustomizeType.RECTANGULAR,
        component: null,
        disabled: false,
        icon: 'rectangle',
      },
      {
        title: CustomizeType.CIRCULAR,
        component: null,
        disabled: !product?.rugBuilderCircleCheck ?? true,
        icon: 'circle',
      },
      {
        title: CustomizeType.WALL_TO_WALL,
        component: null,
        disabled: !product?.rugBuilderWallToWallCheck ?? true,
        icon: 'rectangle',
      },
    ],
    [product?.rugBuilderCircleCheck, product?.rugBuilderWallToWallCheck],
  )

  return (
    <div className="border-b border-b-outlined-gray">
      <div className="py-4">
        <div className="flex flex-row justify-between items-center">
          <p className="uppercase text-sm text-secondary">Type</p>
          <EditButton edit={isOpenCollapse} handleEdit={handleCollapse} />
        </div>
        <span className="font-medium text-lg">{customize.type}</span>
      </div>

      <div className={`${isOpenCollapse ? 'flex flex-col gap-4 opacity-100 pt-4 pb-8' : 'hidden opacity-0'}`}>
        <CustomizeButtonTypes
          types={types}
          onClickHandler={(title) => {
            if (customize.type !== title) {
              dispatch({
                type: RugBuilderActionTypes.SET_CUSTOMIZE_TYPE,
                customizeTypePayload: title,
              })

              dispatch({
                type: RugBuilderActionTypes.RESET_SUB_TOTAL_PRICE,
              })

              dispatch({
                type: RugBuilderActionTypes.HANDLE_PREVIEW_CONFIRM_COLLAPSE,
                keyPreviewConfirmPayload: 'isBorderCollapseComplete',
                valuePreviewConfirmPayload: false,
              })

              dispatch({
                type: RugBuilderActionTypes.HANDLE_PREVIEW_CONFIRM_COLLAPSE,
                keyPreviewConfirmPayload: 'isAddonsCollapseComplete',
                valuePreviewConfirmPayload: false,
              })

              dispatch({
                type: RugBuilderActionTypes.RESET_PREVIEW_CONFIRM_BORDER_COLLAPSE,
                isCompletePayload: true,
              })

              dispatch({
                type: RugBuilderActionTypes.RESET_PREVIEW_CONFIRM_ADDONS_COLLAPSE,
                isCompletePayload: true,
              })

              handleCollapse()
              openNextCollapse()
            }
          }}
        />
      </div>
    </div>
  )
}
