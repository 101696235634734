import { useCallback, useEffect, useState } from 'react'
import {
  focusElementClasses,
  FocusElement,
  MlFormField,
  OrModal,
  AtIcon,
  AtButton,
  AtButtonVariant,
  AtImage,
} from '@curran-catalog/curran-atomic-library'
import { BLOCKS } from '@contentful/rich-text-types'

import { RugBuilderActionTypes } from '@context/rug-builder'
import { useRugBuilder } from '@hooks/use-rug-builder'
import { getPopoverRichText } from '@utils/get-custom-richtext'
import { customRenderNode } from './common'
import { CloudinaryFormat, CornerStyleType, DeliveryType, ObjFlooringCornerStyle, ResourceType } from 'types'

const cornersStyle: ObjFlooringCornerStyle[] = [
  {
    id: '2',
    name: CornerStyleType.LAPPED,
    price: 0,
    images: [
      {
        url: '/images/flooring/lapped.jpg',
        tags: [],
        metadata: [],
        context: {
          custom: {
            alt: '',
          },
        },
        public_id: '',
        secure_url: '/images/flooring/lapped.jpg',
        resource_type: ResourceType.IMAGE,
        original_url: '',
        original_secure_url: '',
        height: 500,
        width: 500,
        format: CloudinaryFormat.JPG,
        raw_transformation: '',
        type: DeliveryType.UPLOAD,
        version: 0,
      },
    ],
    description: 'A lapped corner overlaps at a 90° angle.',
  },
  {
    id: '1',
    name: CornerStyleType.MITERED,
    price: 280,
    images: [
      {
        url: '/images/flooring/mitered.jpg',
        tags: [],
        metadata: [],
        context: {
          custom: {
            alt: '',
          },
        },
        public_id: '',
        secure_url: '/images/flooring/mitered.jpg',
        resource_type: ResourceType.IMAGE,
        original_url: '',
        original_secure_url: '',
        height: 500,
        width: 500,
        format: CloudinaryFormat.JPG,
        raw_transformation: '',
        type: DeliveryType.UPLOAD,
        version: 0,
      },
    ],
    description: 'A mitered corner is stitched at a 45° angle.',
  },
]

interface CornerStyleOptionProps {
  cornerStyleOption: ObjFlooringCornerStyle
}

export const CornerStyleOption = ({ cornerStyleOption }: CornerStyleOptionProps) => {
  const { id, price, name, description, images } = cornerStyleOption

  const { state, dispatch } = useRugBuilder()
  const { border } = state

  const [isOpen, setIsOpen] = useState(false)

  const hasBorderColors =
    !!border.borderMaterial &&
    !!border.borderMaterial.borderColorGroup &&
    Array.isArray(border.borderMaterial.borderColorGroup.borderColors) &&
    border.borderMaterial.borderColorGroup.borderColors.length > 0

  const handleChange = useCallback(
    (cornerStyle: ObjFlooringCornerStyle) => {
      dispatch({
        type: RugBuilderActionTypes.SET_CORNER_STYLE,
        cornerStylePayload: cornerStyle,
      })

      dispatch({
        type: RugBuilderActionTypes.SET_SUB_TOTAL_PRICE,
      })
    },
    [dispatch],
  )

  return (
    <div className="flex flex-col md:flex-row gap-3">
      <div className="flex flex-row gap-1">
        <MlFormField
          key={id}
          name={`corner-style-${id}`}
          label={`${name} ${price ? `(+$${price})` : ''} `}
          disabled={
            hasBorderColors &&
            !!border.borderMaterial &&
            Array.isArray(border.borderMaterial.widths) &&
            border.borderMaterial.widths.length > 0
              ? !border.borderWidth
              : !border.borderColor
          }
          checked={id === border.cornerStyle?.id}
          type="radio"
          onChange={() => handleChange(cornerStyleOption)}
        />
        <button
          className={`${focusElementClasses[FocusElement.FOCUS_VISIBLE]}`}
          type="button"
          onClick={() => setIsOpen(true)}
        >
          <span>
            <AtIcon type="info" color="link" />
          </span>
        </button>
        <OrModal
          isOpen={isOpen}
          title=""
          description={{
            text: getPopoverRichText({
              title: name,
              price,
              description: {
                nodeType: BLOCKS.DOCUMENT,
                data: {},
                content: [
                  {
                    nodeType: BLOCKS.PARAGRAPH,
                    content: [
                      {
                        nodeType: 'text',
                        marks: [],
                        value: description,
                        data: {},
                      },
                    ],
                    data: {},
                  },
                ],
              },
              images,
            }),
            className: 'w-full',
            customRenderNode,
          }}
          handleCloseModal={() => setIsOpen(false)}
        />
      </div>
    </div>
  )
}

const CornerStyleButton = ({ cornerStyleOption }: CornerStyleOptionProps) => {
  const { id, price, name } = cornerStyleOption

  const { state, dispatch } = useRugBuilder()
  const { border } = state

  const hasBorderColors =
    !!border.borderMaterial &&
    !!border.borderMaterial.borderColorGroup &&
    Array.isArray(border.borderMaterial.borderColorGroup.borderColors) &&
    border.borderMaterial.borderColorGroup.borderColors.length > 0

  const handleChange = useCallback(
    (cornerStyle: ObjFlooringCornerStyle) => {
      dispatch({
        type: RugBuilderActionTypes.SET_CORNER_STYLE,
        cornerStylePayload: cornerStyle,
      })

      dispatch({
        type: RugBuilderActionTypes.SET_SUB_TOTAL_PRICE,
      })
    },
    [dispatch],
  )

  useEffect(() => {
    if (name === CornerStyleType.LAPPED && !border.cornerStyle)
      dispatch({
        type: RugBuilderActionTypes.SET_CORNER_STYLE,
        cornerStylePayload: cornerStyleOption,
      })

    dispatch({
      type: RugBuilderActionTypes.SET_SUB_TOTAL_PRICE,
    })
  }, [border, cornerStyleOption, dispatch, name])

  return (
    <div className="flex flex-col md:flex-row gap-3">
      <div className="flex flex-row gap-1">
        <AtButton
          icon={{
            type: name === CornerStyleType.MITERED ? 'mitered-corner' : 'lapped-corner',
            size: 25,
          }}
          label={`${name} ${price ? `(+$${price})` : ''} `}
          variant={id === border.cornerStyle?.id ? AtButtonVariant.SECONDARY : AtButtonVariant.TERTIARY}
          onClick={() => handleChange(cornerStyleOption)}
          disabled={
            hasBorderColors &&
            !!border.borderMaterial &&
            Array.isArray(border.borderMaterial.widths) &&
            border.borderMaterial.widths.length > 0
              ? !border.borderWidth
              : !border.borderColor
          }
        />
      </div>
    </div>
  )
}

interface CornerStyleProps {
  isPreviewConfirm?: boolean
}

export const CornerStyle = ({ isPreviewConfirm = false }: CornerStyleProps) => {
  const { state, dispatch } = useRugBuilder()
  const { border } = state

  const [isOpenModal, setIsOpenModal] = useState(false)

  useEffect(() => {
    if (border.borderMaterial && border.borderMaterial.automiter) {
      dispatch({
        type: RugBuilderActionTypes.SET_CORNER_STYLE,
        cornerStylePayload: { ...cornersStyle[0], price: 0, name: CornerStyleType.MITERED_INCLUDED },
      })

      dispatch({
        type: RugBuilderActionTypes.SET_SUB_TOTAL_PRICE,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [border.borderMaterial])

  const autoMiter = border.borderMaterial && border?.borderMaterial?.automiter
  const miteringOption = border.borderMaterial && border?.borderMaterial?.miteringOption
  // - miteringOption: false, automiter: false
  //     no corner style dialog is shown. Due to automiter flag, corners will
  //     default to lapped corners in preview if there are border colors.
  //     If not, no borders are shown in order-rug-preview (i.e. serge).
  // - miteringOption: false, automiter: true
  //     no corner style dialog is shown. Due to automiter flag,
  //     corners will default to mitered corners in preview if there are border colors.
  //     If not, no borders are shown in the order-rug-preview (i.e. serge).
  // - miteringOption: true, automiter: true
  //     show corner style dialog that tells customer that mitered corners are included.
  //     order-rug-preview shows mitered corners due to automiter flag.
  // - miteringOption: true, automiter: false
  //     show corner style dialog that gives customer a choice of mitered or lapped corners.
  //     order-rug-preview shows customer's choice.
  return !miteringOption ? null : (
    <div className="pb-4 flex flex-col gap-4">
      <OrModal isOpen={isOpenModal} title="Corner Styles" handleCloseModal={() => setIsOpenModal(false)}>
        <div className="mt-4 w-full gap-4 flex flex-col">
          <div className="flex flex-row gap-x-4">
            {cornersStyle.map((cornerStyle) => (
              <div className="w-1/2" key={`img_${cornerStyle.id}-${cornerStyle.name}`}>
                <AtImage src={cornerStyle.images[0].secure_url} alt={cornerStyle.name} />
              </div>
            ))}
          </div>
          <div className="px-4">
            <ul className="list-disc">
              {cornersStyle.map((cornerStyle, index) => (
                <li key={`list_${cornerStyle.name}`} className={index === 0 ? 'mb-4' : ''}>
                  {`${cornerStyle.name} ${index === 0 ? '(Left image)' : '(Right image)'}: ${cornerStyle.description}`}
                </li>
              ))}
            </ul>
          </div>
          <AtButton
            label="Close"
            className="w-full"
            variant={AtButtonVariant.SECONDARY}
            onClick={() => setIsOpenModal(false)}
          />
        </div>
      </OrModal>
      <div className="inline-flex items-center gap-x-2">
        <h3 className={isPreviewConfirm ? 'uppercase text-sm' : 'text-lg font-medium'}>Choose Corner Style</h3>

        <button type="button" onClick={() => setIsOpenModal(true)}>
          <AtIcon type="info" color="link" />
        </button>
      </div>
      <div className="flex flex-col md:flex-row gap-6">
        {autoMiter ? (
          // text tells customer that mitering is automatically done for this border
          <p>{CornerStyleType.MITERED_INCLUDED}</p>
        ) : (
          // radio buttons allowing customers to pick mitering for a surcharge
          cornersStyle.map((cornerStyle) => (
            <CornerStyleButton key={`${cornerStyle.id}-${cornerStyle.name}`} cornerStyleOption={cornerStyle} />
          ))
        )}
      </div>
    </div>
  )
}
