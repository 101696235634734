import { useEffect } from 'react'
import { OrTabItemProps, OrTabs } from '@curran-catalog/curran-atomic-library'

import { RugBuilderActionTypes } from '@context/rug-builder'
import { FlooringWeaveActionTypes } from '@context/flooring-product'
import { useFlooringWeave } from '@hooks/use-flooring-weave'
import { useRugBuilder } from '@hooks/use-rug-builder'
import {
  ObjFlooringWeave,
  WeaveParametersResponse,
  WeaveParametersSuccess,
  WeaveStartingAtPriceResponse,
} from '@services/prices'
import { OrderRug } from './order-rug'
import { ContentfulObjFlooringBorderGroup, ContentfulObjFlooringWeaveColorGroup, FlooringSectionsIDs } from 'types'
import dynamic from 'next/dynamic'

interface RugBuilderProps {
  product: ObjFlooringWeave & {
    parameters: WeaveParametersResponse
    startingAtPrice: WeaveStartingAtPriceResponse
  }
}

export const RugBuilder = ({ product }: RugBuilderProps) => {
  const { dispatch } = useRugBuilder()
  const { state, dispatch: weaveDispatch } = useFlooringWeave()

  const OrderSamples = dynamic(() => import('@components/rug-builder/order-samples').then((mod) => mod.OrderSamples))

  const handleClickTab = (tab: FlooringSectionsIDs) => {
    weaveDispatch({
      type: FlooringWeaveActionTypes.SET_TAB_ACTIVE,
      tabActivePayload: tab,
    })
  }

  const RUG_BUILDER_TABS: OrTabItemProps[] = [
    {
      title: {
        label: 'ORDER RUG',
        active: false,
        actionUrl: FlooringSectionsIDs.PRICE_ORDER,
        onClick: () => handleClickTab(FlooringSectionsIDs.PRICE_ORDER),
      },
      blocks: [<OrderRug key="order-rug" />],
    },
    {
      title: {
        label: 'ORDER SAMPLES',
        active: false,
        actionUrl: FlooringSectionsIDs.SAMPLES,
        onClick: () => handleClickTab(FlooringSectionsIDs.SAMPLES),
      },
      blocks: [
        <OrderSamples
          key="order-samples"
          titleColors={product.colorSamplesTitle}
          hasColorSamples={product.hasSamples}
          colors={product.colorGroup as ContentfulObjFlooringWeaveColorGroup}
          priceSample={Number((product?.parameters as WeaveParametersSuccess)?.swatch_price)}
          titleBorders={product.borderSamplesTitle}
          borders={product.borderGroup as ContentfulObjFlooringBorderGroup}
          priceBorderSample={Number((product?.parameters as WeaveParametersSuccess)?.border_swatch_price)}
          weave={product}
        />,
      ],
    },
  ]

  useEffect(() => {
    if (product) {
      dispatch({
        type: RugBuilderActionTypes.RESET_ALL_STEPS,
      })

      dispatch({
        type: RugBuilderActionTypes.GET_FLOORING_PRODUCT,
        productPayload: product,
      })

      weaveDispatch({
        type: FlooringWeaveActionTypes.SET_TAB_ACTIVE,
        tabActivePayload: FlooringSectionsIDs.PRICE_ORDER,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product])

  return <OrTabs currentPath={state.tabActive} tabs={RUG_BUILDER_TABS} />
}
