import { RugBuilderActionTypes } from '@context/rug-builder'
import { ActionType, AtButton, AtButtonSize, AtButtonVariant, AtIcon } from '@curran-catalog/curran-atomic-library'

import { useRugBuilder } from '@hooks/use-rug-builder'

export const OrderRugStepperError = () => {
  const { dispatch } = useRugBuilder()

  return (
    <div className="absolute inset-0 backdrop-blur-xl z-10" style={{ backdropFilter: 'blur(24px)' }}>
      <div className="flex flex-col justify-center items-center h-full gap-4">
        <span>
          <AtIcon size={60} type="info" />
        </span>
        <div className="flex flex-col justify-center items-center gap-1">
          <span className="text-dark text-xl">Something went wrong</span>
          <span className="text-center">Please try again or contact customer service.</span>
        </div>

        <div className="flex flex-col md:flex-row gap-2">
          <AtButton
            label="Try Again"
            size={AtButtonSize.COMPACT}
            variant={AtButtonVariant.SECONDARY}
            onClick={() =>
              dispatch({
                type: RugBuilderActionTypes.HANDLE_API_ERRORS,
                hasApiErrorPayload: false,
              })
            }
            className="w-full md:w-fit"
          />

          <AtButton
            label="Get Help"
            actionType={ActionType.OPEN_URL}
            actionValue="/customer-service"
            size={AtButtonSize.COMPACT}
            variant={AtButtonVariant.TERTIARY}
            onClick={() => ({})}
            className="w-full md:w-fit"
          />
        </div>
      </div>
    </div>
  )
}
