import { useRugBuilder } from '@hooks/use-rug-builder'

export const RugWeaveName = () => {
  const { state } = useRugBuilder()
  const { product } = state

  return (
    <div className="border-b border-b-outlined-gray">
      <div className="py-4">
        <div className="flex flex-row justify-between items-center">
          <p className="uppercase text-sm text-secondary">Weave</p>
        </div>
        <span className="font-medium text-lg">{product?.name ?? ''}</span>
      </div>
    </div>
  )
}
