import { ChangeEvent, memo, useCallback, useState } from 'react'
import { AtButton, AtButtonVariant, AtButtonSize, MlFormField } from '@curran-catalog/curran-atomic-library'

import { RugBuilderActionTypes } from '@context/rug-builder'
import { useRugBuilder } from '@hooks/use-rug-builder'
import { EditButton } from '@components/flooring-product-detail'

interface NoteProps {
  initialNote?: string
  onEdit: () => void
}

const Note = memo(({ initialNote = '', onEdit }: NoteProps) => {
  const { dispatch } = useRugBuilder()

  const [note, setNote] = useState(initialNote)

  const handleChange = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
    const { value } = target

    setNote(value)
  }, [])

  return (
    <>
      <MlFormField
        placeholder="e.g: weave direction, sidemarks, etc."
        name="notes"
        label=""
        type="textarea"
        value={note}
        onChange={handleChange}
      />
      <div className="py-8">
        <AtButton
          label="Update"
          variant={AtButtonVariant.SECONDARY}
          size={AtButtonSize.COMPACT}
          onClick={() => {
            dispatch({
              type: RugBuilderActionTypes.SET_NOTES_FIELD,
              fieldValuePayload: note.trim(),
            })
            onEdit()
          }}
        />
      </div>
    </>
  )
})

export const NotesEditable = memo(() => {
  const { state, dispatch } = useRugBuilder()
  const { notes, previewConfirm } = state

  const handleNotesCollapse = useCallback(
    () =>
      dispatch({
        type: RugBuilderActionTypes.HANDLE_PREVIEW_CONFIRM_COLLAPSE,
        keyPreviewConfirmPayload: 'notes',
        valuePreviewConfirmPayload: !previewConfirm.notes,
      }),
    [dispatch, previewConfirm.notes],
  )

  return (
    <div className="border-b border-b-outlined-gray w-full">
      <div className="py-4">
        <div className="flex flex-row justify-between items-center">
          <p className="uppercase text-sm text-secondary">Notes</p>
          <EditButton edit={previewConfirm.notes} handleEdit={handleNotesCollapse} />
        </div>
        <span>{notes.optionalNotes}</span>
      </div>

      {previewConfirm.notes && <Note initialNote={notes.optionalNotes} onEdit={handleNotesCollapse} />}
    </div>
  )
})
