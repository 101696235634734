import { BLOCKS, Document, TopLevelBlock } from '@contentful/rich-text-types'

import { CloudinaryAsset } from 'types'

export const getBorderMaterialRichText = (description?: Document, images?: CloudinaryAsset[]): Document => {
  const objDesc = description
    ? description.content
    : [{ nodeType: BLOCKS.PARAGRAPH, data: {}, content: [] } as TopLevelBlock]

  return {
    nodeType: BLOCKS.DOCUMENT,
    data: {},
    content: [
      {
        nodeType: BLOCKS.EMBEDDED_ENTRY,
        data: {
          target: {
            sys: {
              contentType: {
                sys: {
                  id: 'atCloudinaryAsset',
                },
              },
            },
            fields: {
              name: images,
              image: images,
            },
          },
        },
        content: [],
      },
      ...objDesc,
    ],
  }
}
