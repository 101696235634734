import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { MlFormField } from '@curran-catalog/curran-atomic-library'

import { RugBuilderActionTypes } from '@context/rug-builder'
import { useRugBuilder } from '@hooks/use-rug-builder'
import { useRugBuilderTopScroll } from '@hooks/use-rug-builder-top-scroll'
import { useDebouncedCallback } from '@hooks/use-debounced-callback'
import { CustomizeType, OrderRugStepperIndexes } from 'types'
import { WeaveParametersSuccess } from '@services/prices'
import { getFlooringSupplies } from '@utils/get-flooring-supplies'
import { OrderRugControls } from '../order-rug-controls'
import { hideOrderRugControls } from '../order-rug-collapse'

export const OrderRugStepNotes = () => {
  const { state, dispatch } = useRugBuilder()
  const { activeStep, notes, subTotalPrice } = state

  const hasRushPrice = !(
    (state.product?.parameters as WeaveParametersSuccess)?.rush_price === 0 ||
    (state.product?.parameters as WeaveParametersSuccess)?.rush_price === null
  )

  const notesRef = useRef<HTMLDivElement>(null)

  useRugBuilderTopScroll({ ref: notesRef, stepIndex: OrderRugStepperIndexes.NOTES })

  const debouncedHandleNoteChange = useDebouncedCallback((value: string) => {
    dispatch({
      type: RugBuilderActionTypes.SET_NOTES_FIELD,
      fieldValuePayload: value.trim(),
    })
  }, 200)

  useEffect(() => {
    if (!notes.optionalNotes) {
      setNote('')
    }
  }, [notes])

  const [note, setNote] = useState(notes.optionalNotes ?? '')

  const supplies =
    useMemo(
      () => state.product && getFlooringSupplies(state.product, state.customize),
      [state.product, state.customize],
    ) ?? []

  const handleChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      const { value } = target

      if (notes.isComplete && activeStep === OrderRugStepperIndexes.NOTES) {
        dispatch({
          type: RugBuilderActionTypes.HANDLE_NOTES_IS_COMPLETE,
          isCompletePayload: false,
        })
      }

      setNote(value)
      debouncedHandleNoteChange(value)
    },
    [activeStep, debouncedHandleNoteChange, dispatch, notes.isComplete],
  )

  const handlePrevStep = useCallback(() => {
    setNote('')

    dispatch({
      type: RugBuilderActionTypes.RESET_NOTES_STEP,
    })

    if (state.customize.type === CustomizeType.WALL_TO_WALL) {
      if (!hasRushPrice && supplies.length === 0) {
        dispatch({
          type: RugBuilderActionTypes.RESET_BORDER_STEP,
        })

        dispatch({
          type: RugBuilderActionTypes.RESET_ADDONS_STEP,
        })

        return dispatch({
          type: RugBuilderActionTypes.HANDLE_PREV_STEP,
          stepPayload: 3,
        })
      }

      return dispatch({
        type: RugBuilderActionTypes.HANDLE_PREV_STEP,
      })
    }

    dispatch({
      type: RugBuilderActionTypes.HANDLE_PREV_STEP,
    })
  }, [dispatch, hasRushPrice, supplies.length, state.customize.type])

  const handleNextStep = useCallback(() => {
    dispatch({
      type: RugBuilderActionTypes.HANDLE_NOTES_IS_COMPLETE,
      isCompletePayload: true,
    })
    dispatch({
      type: RugBuilderActionTypes.HANDLE_NEXT_STEP,
    })
  }, [dispatch])

  return (
    <div ref={notesRef} className="py-6 flex flex-col gap-5">
      <div>
        <h3 className="text-lg font-medium pb-4">Enter Optional Notes</h3>
        <p className="text-sm">
          Let us know if you have any special instructions for your rug, or click the review button to see your final
          rug selection.
        </p>
      </div>

      <div className="flex flex-row gap-6 w-full">
        <MlFormField
          placeholder="e.g: weave direction, side marks, etc."
          name="notes"
          label=""
          maxLength={128}
          type="textarea"
          value={note}
          onChange={handleChange}
          containerClassName="w-full"
        />
      </div>

      {!hideOrderRugControls && (
        <OrderRugControls
          subtotal={subTotalPrice}
          handlePrevStep={handlePrevStep}
          handleNextStep={handleNextStep}
          isNextStepEnabled={true}
        />
      )}
    </div>
  )
}
