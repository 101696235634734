import { ChangeEvent, useCallback, useEffect, useRef, useState, useMemo } from 'react'
import {
  AtButton,
  AtButtonSize,
  AtButtonState,
  AtButtonVariant,
  AtImageProps,
  AtInfoItem,
  AtInfoItemVariant,
  MlBreadcrumb,
  OrModal,
  OrModalProductProps,
  useSessionStorage,
} from '@curran-catalog/curran-atomic-library'

import { FlooringSampleProvider, FlooringWeaveActionTypes, ObjAddonState } from 'context'
import { CollapseSection, ProductDescription, SizeField } from '@components/flooring-product-detail'
import { ColorOptions } from '@components/flooring-product-detail/color-options'
import { CheckboxOptions, MlProductOptionsCollapse, Quantity } from '@components/product-detail-options'
import { ShoppingInfo } from '@components/common-product-detail'
import {
  ObjFlooringWeave,
  WeaveParametersResponse,
  WeaveParametersSuccess,
  WeaveStartingAtPriceResponse,
} from '@services/prices'
import { useCart, useFlooringWeave, useModal } from 'hooks'
import { ContainerWrapper } from '@utils/container-wrapper'
import { formatPrice } from '@utils/price'
import { normalizeAsset, normalizeInfoItem } from '@utils/normalize'
import { RugBuilderProvider } from '@context/rug-builder'
import {
  ADDONS_DEFAULT_TITLE,
  ContentfulObjFlooringBorderGroup,
  ContentfulObjFlooringWeaveColorGroup,
  FlooringSectionsIDs,
} from 'types'
import { getOrderButtonLabel } from './utils'
import {
  getTilesAddToCartData,
  getWallCoveringAddToCartData,
  getWallToWallAddToCartData,
  WeaveTypes,
} from '@utils/cart/add-to-cart'
import { AxiosError } from 'axios'
import { ErrorResponse } from '@services/cart'
import { useRouter } from 'next/router'
import { getErrorModalProps } from '@config/modal-error'
import { getFullConversionDetail, MeasureUnitKey, validateCurrentMeasureUnit } from '@utils/measures'
import { ImageGallery } from '@components/flooring-product-detail/image-gallery'

import { RugBuilder } from '@components/rug-builder'
import { OrderSamples } from '@components/rug-builder/order-samples'
import { sendProductMeasureAnalyticsEvent } from '@utils/analytics/events/measuring-product/send-event'
import { CartEventTypes, ProductMeasureEventTypes } from '../../types/analytics/analytics-data'
import { sendCartAnalyticsEvent } from '@utils/analytics/events/cart/send-event'
import { renderContentfulMlVideo } from '@utils/render'

interface TmFlooringProductDetailProps {
  product: ObjFlooringWeave & {
    parameters: WeaveParametersResponse
    startingAtPrice: WeaveStartingAtPriceResponse
  }
}

export const TmFlooringProductDetail = ({ product }: TmFlooringProductDetailProps) => {
  const router = useRouter()
  const { state, dispatch } = useFlooringWeave()
  const { isModalOpen, setIsModalOpen, modalInfo, handlerClick, isModalErrorOpen, setIsModalErrorOpen } = useModal()
  const { addTilesToCart, addWallToWallToCart } = useCart()
  const [isCartPageLoading, setCartPageLoading] = useState(false)
  const [previousRoute] = useSessionStorage('x-previousTitleRoute', '')
  const [productIndexId] = useSessionStorage<string | null>('productIndexID', null)

  useEffect(() => {
    const handleRouteChange = (queryID: string) => {
      if (!queryID) return

      dispatch({ type: FlooringWeaveActionTypes.SET_CURRENT_QUERY_ID, algoliaQueryIdPayload: queryID })
    }
    const algoliaQueryIdHistory = localStorage.getItem('AlgoliaQueryIdHistory')
    const algoliaQueryIdHistoryObj = algoliaQueryIdHistory ? JSON.parse(algoliaQueryIdHistory) : null
    // Initial setup
    if (algoliaQueryIdHistoryObj && algoliaQueryIdHistoryObj[product.CONTENTFUL_ID]) {
      handleRouteChange(algoliaQueryIdHistoryObj[product.CONTENTFUL_ID])
    }

    // Listen for route changes

    const routeChangeComplete = () =>
      handleRouteChange(
        algoliaQueryIdHistoryObj && algoliaQueryIdHistoryObj[product.CONTENTFUL_ID]
          ? algoliaQueryIdHistoryObj[product.CONTENTFUL_ID]
          : '',
      )
    router.events.on('routeChangeComplete', routeChangeComplete)

    return () => {
      // Clean up the event listener to prevent memory leaks
      router.events.off('routeChangeComplete', routeChangeComplete)
    }
  }, [dispatch, router.events, router.asPath, product.CONTENTFUL_ID])

  const renderedVideoBlock = useMemo(() => {
    if (!product?.video) {
      return
    }
    return renderContentfulMlVideo({ block: product?.video })
  }, [product?.video])

  const hasColors = Boolean(
    product.colorGroup && Array.isArray(product.colorGroup.weaveColors) && product.colorGroup.weaveColors.length > 0,
  )
  const hasAddons = Array.isArray(product.addons) && product.addons.length > 0
  const hasOptions = hasColors || hasAddons
  const isWallCovering = !product.supportsRugBuilder && !product.supportsTiles && product.supportsWallCovering
  const isWallToWall = !product.supportsRugBuilder && !product.supportsTiles && !product.supportsWallCovering
  const showProductOptions = product.supportsRugBuilder && !isWallToWall

  const isDisabled =
    !product.callForQuote &&
    ((!state.selectedColor && product.supportsTiles) ||
      (isWallToWall &&
        (!validateCurrentMeasureUnit({
          unitMeasure: state.size.unitMeasure?.value,
          sqft: state.size.sqft,
          lft: state.size.lft,
          lm: state.size.lm,
          sqm: state.size.sqm,
          sqyd: state.size.sqyd,
        }) ||
          !state.selectedColor ||
          state.size.isError)) ||
      (!(state.selectedColor && state.sizeWallCovering) && product.supportsWallCovering))

  const rollWidth =
    !!product && !!product.parameters && (product.parameters as WeaveParametersSuccess)['roll_width']
      ? Number((product.parameters as WeaveParametersSuccess)['roll_width']) ?? 0
      : 0

  const blMinLength =
    !!product && !!product.parameters && (product.parameters as WeaveParametersSuccess)['bl_min_length']
      ? (product.parameters as WeaveParametersSuccess)['bl_min_length'] ?? 0
      : 0

  const handleChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      dispatch({ type: FlooringWeaveActionTypes.UPDATE_QUANTITY, quantityPayload: Number(value) })
      dispatch({ type: FlooringWeaveActionTypes.SET_TOTAL_PRICE })
    },
    [dispatch],
  )

  const handleCheckboxOption = useCallback(
    (updatedState: ObjAddonState[]) => {
      dispatch({
        type: FlooringWeaveActionTypes.SELECT_ADDONS,
        addonsPayload: updatedState,
      })
      dispatch({
        type: FlooringWeaveActionTypes.SET_TOTAL_PRICE,
      })
    },
    [dispatch],
  )

  const handleClickButton = ({ tab, url }: { tab?: FlooringSectionsIDs; url?: string }) => {
    if (tab) {
      dispatch({
        type: FlooringWeaveActionTypes.SET_TAB_ACTIVE,
        tabActivePayload: tab,
      })
      window.location.href = tab
    }
    if (url) window.location.href = url
  }

  const getWeaveType = (): WeaveTypes | undefined => {
    if (isWallCovering) return WeaveTypes.WALL_COVERING
    if (isWallToWall) return WeaveTypes.WALL_TO_WALL
    if (product.supportsTiles) return WeaveTypes.TILES
    return undefined
  }

  const handleClickOrder = () => {
    let response = null
    switch (getWeaveType()) {
      case WeaveTypes.WALL_TO_WALL:
        response = addWallToWallToCart(getWallToWallAddToCartData(product, state, previousRoute, productIndexId))
        break
      case WeaveTypes.TILES:
        response = addTilesToCart(getTilesAddToCartData(product, state, previousRoute, productIndexId))
        break
      case WeaveTypes.WALL_COVERING:
        response = addWallToWallToCart(
          getWallCoveringAddToCartData(product, state, rollWidth, previousRoute, productIndexId),
        )
        break
      default:
        return
    }

    response
      .then((cartWeaveResponse) => {
        const addons = state.selectedAddons
          ? state.selectedAddons.map((addon) => ({
              title: addon.addonName,
              text: `${addon.quantity} x ${formatPrice(Number(addon.price))}`,
            }))
          : []

        const extraInfo = [{ title: 'Color', text: state.selectedColor?.weaveColorName }]
        if (state.sizeWallCovering)
          extraInfo.push({ title: 'Size', text: `${Number(state.sizeWallCovering.value) * rollWidth} sqft.` })
        if (
          validateCurrentMeasureUnit({
            unitMeasure: state.size.unitMeasure?.value,
            sqft: state.size.sqft,
            lft: state.size.lft,
            lm: state.size.lm,
            sqm: state.size.sqm,
            sqyd: state.size.sqyd,
          })
        )
          extraInfo.push({
            title: 'Size',
            text: getFullConversionDetail({
              isMetric: state.size.isMetric,
              rollWidth,
              unitMeasure: state.size.unitMeasure?.value as MeasureUnitKey,
              lm: state.size.lm,
              sqft: state.size.sqft,
              sqm: state.size.sqm,
              sqyd: state.size.sqyd,
              lft: state.size.lft,
              lftIn: state.size.lftIn,
            }),
          })
        extraInfo.push(...addons)

        const infoOrderModal = {
          price: `${formatPrice(Number(state.subTotalPrice))}`,
          quantity: state.quantity,
          name: cartWeaveResponse[0].description,
          image: state.selectedColor?.images
            ? (normalizeAsset({ asset: state.selectedColor?.images[0] }) as AtImageProps)
            : ({ src: '/images/image-coming-soon.jpg', alt: '' } as AtImageProps),
          extraInfo,
        } as OrModalProductProps

        sendCartAnalyticsEvent(cartWeaveResponse, CartEventTypes.ADD_TO_CART)
        handlerClick(infoOrderModal)
      })
      .catch((e: AxiosError<ErrorResponse>) => {
        if (e.response) {
          if (e.response.status === 422 && !e.response.data.errors) {
            // eslint-disable-next-line no-console
            console.log(e)
            dispatch({
              type: FlooringWeaveActionTypes.SET_SIZE_ERROR,
              errorSizePayload: { isError: true, errorMessage: e.response.data.message },
            })
          } else {
            handlerClick(undefined, true)
          }
        }
      })
  }

  const startingAtPrice = product.startingAtPrice?.area_rug_price ?? undefined
  const price = product.startingAtPrice?.bl_w2w_sqyd_price ?? product.startingAtPrice?.tile_box_price ?? undefined

  const handleIncreaseQuantity = useCallback(() => {
    dispatch({ type: FlooringWeaveActionTypes.INCREASE_QUANTITY })
    dispatch({ type: FlooringWeaveActionTypes.SET_TOTAL_PRICE })
  }, [dispatch])
  const handleDecreaseQuantity = useCallback(() => {
    dispatch({ type: FlooringWeaveActionTypes.DECREASE_QUANTITY })
    dispatch({ type: FlooringWeaveActionTypes.SET_TOTAL_PRICE })
  }, [dispatch])
  const handleClearOptions = useCallback(() => {
    dispatch({ type: FlooringWeaveActionTypes.CLEAR_ALL_OPTIONS })
    dispatch({ type: FlooringWeaveActionTypes.SET_BASE_PRICE, basePricePayload: Number(price) })
    dispatch({ type: FlooringWeaveActionTypes.SET_WEAVE_ID, weaveIdPayload: product.CONTENTFUL_ID })
    if (product.supportsTiles && !!product.minQuantity) {
      dispatch({ type: FlooringWeaveActionTypes.UPDATE_QUANTITY, quantityPayload: Number(product.minQuantity) })
      dispatch({ type: FlooringWeaveActionTypes.SET_TOTAL_PRICE })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useEffect(() => {
    if (router.asPath.includes(FlooringSectionsIDs.SAMPLES)) {
      dispatch({ type: FlooringWeaveActionTypes.SET_TAB_ACTIVE, tabActivePayload: FlooringSectionsIDs.SAMPLES })
    }

    dispatch({ type: FlooringWeaveActionTypes.SET_BASE_PRICE, basePricePayload: Number(price) })
    dispatch({ type: FlooringWeaveActionTypes.SET_WEAVE_ID, weaveIdPayload: product.CONTENTFUL_ID })
    if (product.supportsTiles && !!product.minQuantity) {
      dispatch({ type: FlooringWeaveActionTypes.UPDATE_QUANTITY, quantityPayload: Number(product.minQuantity) })
      dispatch({ type: FlooringWeaveActionTypes.SET_TOTAL_PRICE })
    }
  }, [price, dispatch, product, product.minQuantity, product.supportsTiles, router.asPath])

  const errorModalProps = getErrorModalProps(
    () => {
      setIsModalErrorOpen(false)
      router.push('/customer-service')
    },
    () => {
      setIsModalErrorOpen(false)
    },
  )
  const form = useRef<HTMLFormElement>(null)

  useEffect(() => {
    form?.current?.reset()
  }, [])

  useEffect(() => {
    sendProductMeasureAnalyticsEvent(
      product,
      ProductMeasureEventTypes.VIEW_ITEM,
      productIndexId,
      startingAtPrice || price || 0,
      previousRoute,
    )
  }, [previousRoute, price, product, productIndexId, startingAtPrice])

  return (
    <div className="flex flex-col gap-0">
      <ContainerWrapper className="gap-3 flex-1 flex flex-col">
        <MlBreadcrumb
          productDetailBreadcrumb={{
            categoryName: 'All weaves',
            categorySlug: '#',
            name: product.weaveName,
            nameSlug: product.weaveNameSlug,
          }}
        />
        <RugBuilderProvider>
          {/* Description Mobile/Tablet */}
          <h1 className="sr-only">{product.weaveName}</h1>
          <ProductDescription
            supportRugBuilder={product.supportsRugBuilder || product.rugBuilderWallToWallCheck}
            callForQuote={product.callForQuote}
            isTile={product.supportsTiles}
            isWallToWall={isWallToWall}
            isWallCovering={product.supportsWallCovering}
            product={product}
            areaRugStartingAtPrice={startingAtPrice}
            startingAtPrice={price}
            showColorPicker={hasColors}
            className={`${
              showProductOptions ? 'block md:hidden' : 'xl:hidden'
            } flex flex-col gap-4 md:sr-only not-sr-only`}
          />

          <div className="relative flex flex-col md:flex-row w-full md:justify-start sm:gap-y-8 sm:gap-x-8 ">
            <div className="!h-auto">
              <div className="tw-gallery-size tw-gallery-container md:sticky md:top-24 lg:top-40 xl:w-[732px]">
                <ImageGallery product={product} />
              </div>
            </div>

            <form
              className="text-secondary flex flex-col gap-4 md:w-5/12 xl:basis-5/12 w-full"
              onSubmit={(e) => e.preventDefault()}
            >
              {/* Description Desktop */}
              <ProductDescription
                supportRugBuilder={product.supportsRugBuilder || product.rugBuilderWallToWallCheck}
                callForQuote={product.callForQuote}
                isTile={product.supportsTiles}
                isWallToWall={isWallToWall}
                isWallCovering={product.supportsWallCovering}
                product={product}
                showColorPicker={product.supportsRugBuilder}
                areaRugStartingAtPrice={startingAtPrice}
                startingAtPrice={price}
                className="hidden md:block xl:flex xl:flex-col xl:gap-4 sr-only md:not-sr-only"
              />

              {/* Option header */}
              {!product.supportsRugBuilder && !showProductOptions && !product.callForQuote && (
                <>
                  {hasOptions && (
                    <div className="flex flex-row justify-between items-center">
                      <div className="flex flex-col gap-1">
                        <p className="font-medium">Select your options</p>
                        <span className="text-sm flex flex-row gap-1">
                          Required <span className="text-error text-xl leading-6 font-medium">*</span>
                        </span>
                      </div>
                      {(hasColors || hasAddons) && (
                        <AtButton
                          label="Clear Selection"
                          variant={AtButtonVariant.TERTIARY}
                          size={AtButtonSize.COMPACT}
                          onClick={handleClearOptions}
                          type="reset"
                        />
                      )}
                    </div>
                  )}

                  <div className="flex flex-col gap-4 w-full">
                    {hasColors && product.colorGroup?.weaveColors && (
                      <MlProductOptionsCollapse
                        index={1}
                        isRequired
                        initialToggleCollapse
                        name={product.colorGroup?.weaveColorGroupName}
                        optionSelected={
                          state.selectedColor
                            ? product.colorGroup?.weaveColors.find(
                                (item) => item?.CONTENTFUL_ID === state.selectedColor?.CONTENTFUL_ID,
                              )?.weaveColorName
                            : ''
                        }
                      >
                        <ColorOptions
                          options={product.colorGroup?.weaveColors.filter((item) => item?.status === 'Active')}
                        />
                      </MlProductOptionsCollapse>
                    )}

                    {/* hasSize */}
                    {(isWallToWall || product.supportsWallCovering) && (
                      <MlProductOptionsCollapse
                        index={2}
                        initialToggleCollapse={!hasColors && !product.colorGroup?.weaveColors}
                        isRequired={true}
                        name="Size"
                        isEmpty={
                          !state.sizeWallCovering &&
                          !validateCurrentMeasureUnit({
                            unitMeasure: state.size.unitMeasure?.value,
                            sqft: state.size.sqft,
                            lft: state.size.lft,
                            lm: state.size.lm,
                            sqm: state.size.sqm,
                            sqyd: state.size.sqyd,
                          })
                        }
                      >
                        <SizeField
                          isWallToWall={isWallToWall}
                          sizeDescription={product.wallToWallDescription}
                          blMinLength={blMinLength}
                          rollWidth={rollWidth}
                        />
                      </MlProductOptionsCollapse>
                    )}

                    {/* hasAddons */}
                    {hasAddons && product?.addons && (
                      <MlProductOptionsCollapse
                        index={isWallToWall || product.supportsWallCovering ? 3 : 2}
                        isRequired={false}
                        isMultiValue
                        name={product.addonsTitle ?? ADDONS_DEFAULT_TITLE}
                        optionSelected={
                          state.selectedAddons
                            ? product?.addons.find(
                                (addon) =>
                                  state.selectedAddons?.find(
                                    (selectedAddon) => selectedAddon.CONTENTFUL_ID === addon.CONTENTFUL_ID,
                                  )?.addonName,
                              )?.addonName
                            : ''
                        }
                      >
                        <CheckboxOptions options={product.addons} handleContextAction={handleCheckboxOption} />
                      </MlProductOptionsCollapse>
                    )}
                  </div>

                  {/* Quantity */}
                  <div className="flex flex-row justify-between items-center mt-4">
                    {product.supportsTiles && (
                      <>
                        <Quantity
                          isTile={product.supportsTiles}
                          min={product.minQuantity ? product.minQuantity : 1}
                          max={99}
                          value={state.quantity}
                          helperText={product.quantityHelperText}
                          handleChange={handleChange}
                          increase={handleIncreaseQuantity}
                          decrease={handleDecreaseQuantity}
                        />
                        <span className="font-medium text-base text-dark">{formatPrice(state.subTotalPrice)}</span>
                      </>
                    )}
                    {(isWallToWall || product.supportsWallCovering) && (
                      <span className="w-full font-medium text-base text-dark text-right">
                        Subtotal: {formatPrice(state.sizeWallCovering || !state.size.isError ? state.subTotalPrice : 0)}
                      </span>
                    )}
                  </div>
                </>
              )}
              {/* Order Button */}
              <AtButton
                type="button"
                disabled={isDisabled}
                label={getOrderButtonLabel(product)}
                variant={AtButtonVariant.SECONDARY}
                state={isDisabled ? AtButtonState.DISABLED : AtButtonState.ENABLED}
                onClick={() => {
                  ;(isWallToWall || product.supportsTiles || product.supportsWallCovering) && !product.callForQuote
                    ? handleClickOrder()
                    : handleClickButton({
                        ...(product.callForQuote && { url: '/customer-service' }),
                        ...(!product.callForQuote && { tab: FlooringSectionsIDs.PRICE_ORDER }),
                      })
                }}
              />
              <AtButton
                type="button"
                label="Order Samples"
                variant={AtButtonVariant.TERTIARY}
                onClick={() => handleClickButton({ tab: FlooringSectionsIDs.SAMPLES })}
              />

              <ShoppingInfo />

              {/* Shopping info text*/}
              {Array.isArray(product.additionalInformation) &&
                product.additionalInformation.length > 0 &&
                product.additionalInformation.map((infoItem, index) => (
                  <AtInfoItem
                    key={`add-info-${infoItem.CONTENTFUL_ID}-${index}`}
                    {...normalizeInfoItem(infoItem)}
                    variant={AtInfoItemVariant.SHIPPING}
                  />
                ))}
            </form>
          </div>

          <div>
            {!product.callForQuote && <span id="price-order" className="relative -top-28" />}
            <span id="samples" className="relative -top-28" />
          </div>

          {(product.supportsTiles || product.supportsWallCovering) && renderedVideoBlock}

          <FlooringSampleProvider>
            {product.supportsRugBuilder && !product.callForQuote && <RugBuilder product={product} />}
            {(product.supportsTiles ||
              product.supportsWallCovering ||
              isWallToWall ||
              (product.supportsRugBuilder && product.callForQuote)) &&
              hasColors && (
                <OrderSamples
                  titleColors={product.colorSamplesTitle}
                  colors={product.colorGroup as ContentfulObjFlooringWeaveColorGroup}
                  hasColorSamples={product.hasSamples}
                  priceSample={Number((product?.parameters as WeaveParametersSuccess)?.swatch_price)}
                  titleBorders={product.borderSamplesTitle}
                  borders={product.borderGroup as ContentfulObjFlooringBorderGroup}
                  priceBorderSample={Number((product?.parameters as WeaveParametersSuccess)?.border_swatch_price)}
                  weave={product}
                />
              )}
          </FlooringSampleProvider>
        </RugBuilderProvider>
        <CollapseSection product={product} />
      </ContainerWrapper>

      {/*Modal order*/}
      <OrModal
        productContent={modalInfo?.productContent}
        isOpen={isModalOpen}
        icon="circle-check"
        title="Added to Shopping Cart"
        leftButton={{
          label: 'Continue Shopping',
          onClick: () => {
            setIsModalOpen(false)
          },
          variant: AtButtonVariant.TERTIARY,
        }}
        rightButton={{
          label: 'View Cart',
          onClick: () => {
            setCartPageLoading(true)
            router.push('/cart')
          },
          state: isCartPageLoading ? AtButtonState.LOADING : AtButtonState.ENABLED,
          icon: isCartPageLoading ? { type: 'spinner' } : undefined,
          disabled: isCartPageLoading,
        }}
        handleCloseModal={() => setIsModalOpen(false)}
      />

      <OrModal
        isOpen={isModalErrorOpen}
        title="Error"
        description={errorModalProps.description}
        leftButton={errorModalProps.leftButton}
        rightButton={errorModalProps.rightButton}
        handleCloseModal={() => setIsModalErrorOpen(false)}
      />
    </div>
  )
}
