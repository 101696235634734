import { AtImageProps, MlGallery } from '@curran-catalog/curran-atomic-library'
import { useRugBuilder } from '@hooks/use-rug-builder'
import { ObjFlooringWeave, WeaveParametersResponse, WeaveStartingAtPriceResponse } from '@services/prices'
import { normalizeAsset } from '@utils/index'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { normalizeColorsToImageProps } from './color-slider'
import { ContentfulObjFlooringWeaveColor } from 'types'
import { useFlooringWeave } from '@hooks/use-flooring-weave'
import { FlooringWeaveActionTypes } from '@context/flooring-product'
import { ContentfulComponentWithImages } from '@utils/common'

export interface ImageGalleryProps {
  product: ObjFlooringWeave & {
    parameters: WeaveParametersResponse
    startingAtPrice: WeaveStartingAtPriceResponse
  }
}

export const ImageGallery = ({ product }: ImageGalleryProps) => {
  const { state } = useRugBuilder()
  const [showing, setShowing] = useState(-1)
  const { state: flooringState, dispatch } = useFlooringWeave()
  const selectedColor = useMemo(() => flooringState.selectedColor, [flooringState])
  const displayWeaveColor = useMemo(() => flooringState.displayWeaveColor, [flooringState])
  const weaveColors: AtImageProps[] = useMemo(() => {
    if (product.colorGroup && product.colorGroup.weaveColors) {
      return product.colorGroup.weaveColors
        .filter((color: ContentfulObjFlooringWeaveColor) => color.status === 'Active')
        .map((color: ContentfulObjFlooringWeaveColor) => normalizeColorsToImageProps(color))
    }
    return []
  }, [product])

  const thumbnails: AtImageProps[] = useMemo(
    () =>
      Array.isArray(product.images) && product.images.length > 0
        ? product.images.map((image, index) =>
            normalizeAsset({
              asset: { ...image, priority: index <= 1 },
              contentType: ContentfulComponentWithImages.imageGallery,
            }),
          )
        : [{ src: '/images/image-coming-soon.jpg', alt: '' }],
    [product],
  )

  const mainImages: AtImageProps[] = useMemo(() => {
    return [...thumbnails, ...weaveColors]
  }, [thumbnails, weaveColors])

  const weaveColor = useMemo(() => state.customize.weaveColor, [state])

  const setCurrentColor = useCallback(
    (color: ContentfulObjFlooringWeaveColor) => {
      const colorImage = normalizeColorsToImageProps(color)
      const index = mainImages.findIndex((color) => color.alt == colorImage.alt)
      setShowing(index)
    },
    [mainImages],
  )

  useEffect(() => {
    if (weaveColor && displayWeaveColor) {
      setCurrentColor(weaveColor)
    }
  }, [weaveColor, setCurrentColor, displayWeaveColor])

  useEffect(() => {
    if (selectedColor && displayWeaveColor) {
      setCurrentColor(selectedColor)
    }
  }, [selectedColor, setCurrentColor, displayWeaveColor])

  const handleOnImageChange = useCallback(
    ({ alt }: AtImageProps) => {
      const currentColorImage = weaveColors.findIndex((X) => X.alt == alt)
      dispatch({
        type: FlooringWeaveActionTypes.SET_CURRENT_COLOR_INDEX,
        currentColorIndexPayload: currentColorImage,
      })
    },
    [weaveColors, dispatch],
  )

  return (
    <MlGallery
      onActiveImageChange={handleOnImageChange}
      images={mainImages}
      showing={showing}
      thumbnailImages={thumbnails}
    />
  )
}
