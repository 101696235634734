import { useRugBuilder } from '@hooks/use-rug-builder'
import { OrderRugTab } from './order-rug-tab'
import { OrderRugDesktopPreview } from './order-rug-preview'

const PREVIEW_CONFIRM_STEP_INDEX = 4

export const OrderRug = () => {
  const { state } = useRugBuilder()
  const { activeStep } = state

  return (
    <div className="flex flex-row border-transparent xl:border xl:border-outlined-gray ">
      {activeStep !== PREVIEW_CONFIRM_STEP_INDEX && <OrderRugDesktopPreview />}
      <OrderRugTab />
    </div>
  )
}
