import { memo, useEffect, useMemo, useState } from 'react'
import { AtIcon, AtLink, BgColor, IconType, OrCollapse, OrCollapseProps } from '@curran-catalog/curran-atomic-library'

import { useRugBuilder } from '@hooks/use-rug-builder'
import { OrderRugMobilePreview } from './order-rug-preview'
import {
  OrderRugStepAddons,
  OrderRugStepBorder,
  OrderRugStepCustomize,
  OrderRugStepNotes,
  validateBorder,
} from './steps'
import { CustomizeType, OrderRugStepperIndexes } from 'types'
import { OrderRugStepperError } from './order-rug-stepper-error'
import { RugBuilderActionTypes } from '@context/rug-builder'
import { AddToCartRug } from './steps/button-add-to-cart'
import { formatPrice } from '@utils/price'
import { useAuth } from '@hooks/use-auth'

export type Stepper = {
  title: string
  component: JSX.Element
  isComplete: boolean
}

export const hideOrderRugControls = true

type ErrorAlertProps = {
  message?: string
}

const ErrorAlert = ({ message }: ErrorAlertProps) => {
  return (
    <div className="bg-[#DC26261A] my-6 py-2 px-4 gap-2 inline-flex items-center w-fit rounded-full">
      <AtIcon type="info" color="error" />
      <span className="text-error text-sm">
        {message ?? 'To continue to the next step, please fill in the missing information.'}
      </span>
    </div>
  )
}

export const OrderRugCollapse = memo(() => {
  const { state, dispatch } = useRugBuilder()
  const {
    addons,
    hasDimensionError,
    hasApiErrors,
    activeStep,
    customize,
    border,
    product,
    subTotalPrice,
    discountPrice,
  } = state
  const [showError, setShowError] = useState({
    customize: false,
    border: false,
    customizerMessage: '',
    borderMessage: '',
  })

  const validateDimension = () => {
    if (customize.type == CustomizeType.RECTANGULAR && customize.isMetric && customize.lengthCm && customize.widthCm) {
      if (Number(customize.lengthCm) == 0 || Number(customize.lengthCm) == 0) {
        return false
      }
      return true
    }

    if (customize.type == CustomizeType.RECTANGULAR && !customize.isMetric && customize.lengthFt && customize.widthFt) {
      if (Number(customize.lengthFt) == 0 || Number(customize.widthFt) == 0) {
        return false
      }
      return true
    }

    if (customize.type == CustomizeType.CIRCULAR && customize.isMetric && customize.diameterCm) {
      if (Number(customize.diameterCm) == 0) {
        return false
      }
      return true
    }

    if (customize.type == CustomizeType.CIRCULAR && !customize.isMetric && customize.diameterFt) {
      if (Number(customize.diameterFt) == 0) {
        return false
      }
      return true
    }
    if (customize.type == CustomizeType.WALL_TO_WALL) {
      if (customize.lft || customize.lm || customize.sqm || customize.sqyd || customize.sqft) {
        return true
      }
    }
    return false
  }

  useEffect(() => {
    if (showError.customize) {
      setTimeout(() => {
        setShowError({ ...showError, customize: false, customizerMessage: '' })
      }, 5000)
    }
    if (showError.border) {
      setTimeout(() => {
        setShowError({ ...showError, border: false, borderMessage: '' })
      }, 5000)
    }
  }, [showError])

  const handlerCollapse = (id: number) => {
    //?Active Step 0: Customize Rug
    //?Active Step 1: Border Options
    //?Active Step 2, 3: Add-Ons & Notes
    //Add-Ons & Notes go back to Customize Rug
    if (activeStep === 3 && id === 1) {
      dispatch({
        type: RugBuilderActionTypes.HANDLE_PREV_STEP,
        stepPayload: 3,
      })

      dispatch({
        type: RugBuilderActionTypes.CUSTOME_STEP_FILLED,
        isCustomeStepFilled: false,
      })

      return
    }

    if (activeStep == 1 && id == 1) {
      dispatch({
        type: RugBuilderActionTypes.CUSTOME_STEP_FILLED,
        isCustomeStepFilled: false,
      })
      dispatch({
        type: RugBuilderActionTypes.HANDLE_PREV_STEP,
      })
      return
    }

    //Add ons go back to Border Rug
    if (activeStep == 2 && id <= 2) {
      if (id == 1) {
        dispatch({
          type: RugBuilderActionTypes.HANDLE_PREV_STEP,
          stepPayload: 2,
        })
      } else {
        dispatch({
          type: RugBuilderActionTypes.HANDLE_PREV_STEP,
        })
      }

      return
    }

    //Step 0: Customize Rug - Border Option
    if (activeStep === 0 && id === 2) {
      if (!customize.weaveColor) {
        setShowError({
          ...showError,
          customize: true,
          customizerMessage: 'To continue to the next step, please select a weave color.',
        })
        return
      }
      dispatch({
        type: RugBuilderActionTypes.CUSTOME_STEP_FILLED,
        isCustomeStepFilled: true,
      })
      setTimeout(() => {
        dispatch({
          type: RugBuilderActionTypes.CUSTOME_STEP_FILLED,
          isCustomeStepFilled: false,
        })
      }, 2000)
      return
    }

    //Step 0: Customize Rug - Add Ons
    if (activeStep === 0 && id === 3) {
      if (customize.weaveColor && customize.type === CustomizeType.WALL_TO_WALL) {
        dispatch({
          type: RugBuilderActionTypes.CUSTOME_STEP_FILLED,
          isCustomeStepFilled: true,
        })
        setTimeout(() => {
          dispatch({
            type: RugBuilderActionTypes.CUSTOME_STEP_FILLED,
            isCustomeStepFilled: false,
          })
        }, 2000)
        return
      } else if (!customize.weaveColor) {
        setShowError({
          ...showError,
          customize: true,
          customizerMessage: 'To continue to the next step, please select a weave color.',
        })
      } else if (validateBorder(customize, border, product?.borderGroup)) {
        dispatch({
          type: RugBuilderActionTypes.HANDLE_NEXT_STEP,
          stepPayload: 2,
        })
      }
      return
    }

    //Step 2: Border Options
    if (activeStep === 1 && id === 3) {
      if (!validateBorder(customize, border, product?.borderGroup)) {
        let borderMessage = 'To continue to the next step, please select a border options.'

        if (!border.borderMaterial) {
          borderMessage = 'To continue to the next step, please select a border material option.'
        } else if (!border.borderColor) {
          borderMessage = 'To continue to the next step, please select a border color option.'
        } else if (!border.borderWidth) {
          borderMessage = 'To continue to the next step, please select a border width option.'
        } else if (!border.cornerStyle) {
          borderMessage = 'To continue to the next step, please select a corner style option.'
        }

        setShowError({
          ...showError,
          border: true,
          borderMessage,
        })
        return
      }
      dispatch({
        type: RugBuilderActionTypes.HANDLE_NEXT_STEP,
      })
      dispatch({
        type: RugBuilderActionTypes.BORDER_STEP_FILLED,
        isBorderStepFilled: true,
      })
    }
  }
  const collapseItems: OrCollapseProps[] = useMemo(
    () => {
      const step1 = {
        id: 1,
        title: '1. Customize Rug',
        content: (
          <>
            {' '}
            <OrderRugStepCustomize />
            {showError.customize && <ErrorAlert message={showError.customizerMessage} />}
          </>
        ),
        isOpen: true,
        onAction: () => handlerCollapse(1),
        bgColor: BgColor.SLATE_200,
        icon: customize.isComplete ? ('circle-check' as IconType) : undefined,
      }
      const step2 = {
        id: 2,
        title: '2. Select Border',
        content: (
          <>
            <OrderRugStepBorder />
            {showError.border && <ErrorAlert message={showError.borderMessage} />}
          </>
        ),
        isOpen: false,
        onAction: () => handlerCollapse(2),
        isDisabled: hasDimensionError || hasApiErrors || !validateDimension(),
        bgColor: border.isComplete ? BgColor.SLATE_200 : BgColor.SLATE_50,
        icon: addons.isComplete ? ('circle-check' as IconType) : undefined,
        angleIconClassName: !hasDimensionError && !border.isComplete && validateDimension() ? 'animate-bounce' : '',
      }
      const step3 = {
        id: 3,
        title: '3. Finalize Order',
        content: (
          <>
            <OrderRugStepAddons />
            <hr className="tw-text-outlined-gray mt-4 " />
            <OrderRugStepNotes />
          </>
        ),
        isOpen: false,
        onAction: () => handlerCollapse(3),
        isDisabled: !border.isComplete,
        bgColor: addons.isComplete ? BgColor.SLATE_200 : BgColor.SLATE_50,
        icon: addons.isComplete ? ('circle-check' as IconType) : undefined,
        angleIconClassName: border.isComplete && !addons.isComplete ? 'animate-bounce' : '',
      }
      if (customize.type === CustomizeType.WALL_TO_WALL) {
        step3['title'] = '2. Finalize Order'
        step3['isDisabled'] = hasDimensionError || hasApiErrors || !validateDimension()
        step3['bgColor'] = customize.isComplete ? BgColor.SLATE_200 : BgColor.SLATE_50
        step3['icon'] = customize.isComplete ? ('circle-check' as IconType) : undefined
        step3['angleIconClassName'] =
          !addons.isComplete && !hasDimensionError && validateDimension() ? 'animate-bounce' : ''
        return [step1, step3]
      }

      return [step1, step2, step3]
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      customize,
      hasDimensionError,
      addons.isComplete,
      customize.isMetric,
      border.isComplete,
      showError,
      customize.type,
      activeStep,
    ],
  )

  const scrollShipSpec = () => {
    const section = document.querySelector('#ShippingSpecifications')
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }
  const { user } = useAuth({})

  return (
    <div className="relative w-full flex flex-col px-0 xl:px-6 gap-6">
      <div className={activeStep !== OrderRugStepperIndexes.PREVIEW_CONFIRM ? 'border  xl:border-none' : ''}>
        {collapseItems.map((item) => (
          <OrCollapse
            key={`collapse-rug-builder-${item.id}`}
            id={item.id}
            title={item.title}
            content={item.content}
            isOpen={activeStep === 3 ? activeStep === item.id : activeStep + 1 == item.id}
            onAction={() => handlerCollapse(item.id)}
            isDisabled={item.isDisabled}
            className="tw-border-0 tw-border-secondary"
            showOpenBorder
            bgColor={item.bgColor}
            textColor={item.textColor}
            icon={item.icon}
            angleIconClassName={item.angleIconClassName}
          />
        ))}
      </div>

      {hasApiErrors && <OrderRugStepperError />}

      <div />
      <div className="bg-subdued p-3 xl:p-6">
        <div className="flex flex-col xl:flex-row justify-between items-center pb-6 gap-y-4">
          <div className="flex flex-col gap-2 w-full xl:w-fit">
            {!user || !user.trade_level || user.trade_level == '' || subTotalPrice <= 0 ? (
              <div className="text-lg font-medium ">
                Subtotal: {subTotalPrice > 0 ? ' ' + formatPrice(subTotalPrice) + '' : '-'}
              </div>
            ) : (
              <div>
                <div className="flex flex-row gap-2 text-lg font-medium items-center">
                  <div className="text-md font-small">
                    <span>Net Price:</span>
                  </div>
                  <h1>{subTotalPrice > 0 ? formatPrice(subTotalPrice - discountPrice) : '-'}</h1>
                  <div className="items-center	rounded-full bg-gray py-1 px-1 text-xs tracking-wide text-subdued text-center">
                    {Math.round(Number(discountPrice / subTotalPrice) * 100)}%
                  </div>
                </div>

                <div className="flex flex-row gap-2 text-md font-small items-center line-through">
                  <span>List price: {subTotalPrice > 0 ? formatPrice(subTotalPrice) + '.00' : '-'}</span>
                </div>
              </div>
            )}
            <div className="xl:hidden">
              <OrderRugMobilePreview />
            </div>
          </div>
          <AddToCartRug />
        </div>
        <hr />
        <div className="mt-4">
          <div className="flex gap-2 items-center mb-2">
            <AtIcon type="user-headset" />
            <p>
              Need help? Ask a design assistant{' '}
              <AtLink href="/customer-service" className="font-bold" label="Contact Us" />
            </p>
          </div>
          <div className="flex gap-2 items-center mb-2">
            <AtIcon type="truck-clock" className="tw-animate-bounce" />
            <p>
              Questions about shipping?{' '}
              <AtLink onClick={() => scrollShipSpec()} className="font-bold" label="Delivery Times" />
            </p>
          </div>
        </div>
      </div>
    </div>
  )
})
