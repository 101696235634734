import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { AtImageProps, MlColorSwatch } from '@curran-catalog/curran-atomic-library'

import { RugBuilderActionTypes } from '@context/rug-builder'
import { useRugBuilder } from '@hooks/use-rug-builder'
import { normalizeAsset } from '@utils/normalize'
import { ContentfulObjFlooringBorderColor, OrderRugStepperIndexes } from 'types'
import { ContentfulComponentWithImages } from '@utils/common'

interface BorderColorOptionsProps {
  options: ContentfulObjFlooringBorderColor[]
}

const normalizeBorderColorsToImageProps = (color: ContentfulObjFlooringBorderColor): AtImageProps => {
  return color.images && color.images.length > 0
    ? {
        ...(normalizeAsset({
          asset: color.images[0],
          contentType: ContentfulComponentWithImages.imageGallery,
        }) as AtImageProps),
        alt: color.borderColorName,
        caption: '',
      }
    : { src: '/images/image-coming-soon.jpg', alt: '' }
}

export const BorderColorSwatch = memo(({ options = [] }: BorderColorOptionsProps) => {
  const { state, dispatch } = useRugBuilder()
  const { activeStep, border } = state
  const [color, setColor] = useState('none')

  const colors = useMemo(() => {
    if (options) {
      return options
        .filter((color) => color.status === 'Active')
        .map((color) => normalizeBorderColorsToImageProps(color))
    }
    return []
  }, [options])

  const onColorSelected = useCallback(
    (caption: string) => {
      const image = colors.find((x) => x.alt == caption)
      if (!image) return
      const foundBorderColor = options.find((x) => x.borderColorName == image.alt)
      if (foundBorderColor) {
        setColor(foundBorderColor.borderColorName)
        dispatch({
          type: RugBuilderActionTypes.SET_BORDER_COLOR,
          borderColorPayload: foundBorderColor,
        })
      }
    },
    [colors, options, dispatch],
  )

  useEffect(() => {
    if (border && !border.borderColor) {
      const image = colors[0]
      if (!image) return
      const foundBorderColor = options.find((x) => x.borderColorName == image.alt)
      if (foundBorderColor) {
        setColor(foundBorderColor.borderColorName)
        dispatch({
          type: RugBuilderActionTypes.SET_BORDER_COLOR,
          borderColorPayload: foundBorderColor,
        })
      }
    }
  }, [border, dispatch, options, colors])

  useEffect(() => {
    if (border.borderColor?.borderColorName) {
      const selected = options.find((x) => x.borderColorName === border.borderColor?.borderColorName)
      setColor(selected?.borderColorName ?? 'none')
    }
  }, [border.borderColor, options])

  return (
    <div
      className={`w-full ${
        activeStep === OrderRugStepperIndexes.PREVIEW_CONFIRM
          ? 'md:max-w-md lg:max-w-2xl xl:max-w-3xl'
          : 'max-w-3xl xl:max-w-4xl'
      }`}
    >
      <MlColorSwatch
        colorImages={colors}
        selectedColor={color}
        onImageClick={(i) => {
          onColorSelected(i)
        }}
      />
    </div>
  )
})
