import { Addons, Customize } from '@context/rug-builder'
import { useRugBuilder } from '@hooks/use-rug-builder'
import { ProductionRush, RugPad, Supplies } from './addons-sections'
import { ContentfulObjFlooringRugpadGroup, CustomizeType, OrderRugStepperIndexes } from 'types'
import { WeaveParametersSuccess } from '@services/prices'

export const validateAddons = (
  type: Customize['type'],
  addons: Addons,
  rugpadGroup?: ContentfulObjFlooringRugpadGroup,
  hasRushPrice?: boolean,
) => {
  //Validate that rugpadGroup exists from Contentful
  if (rugpadGroup && Array.isArray(rugpadGroup.rugpads) && rugpadGroup.rugpads.length > 0) {
    if (type === CustomizeType.WALL_TO_WALL) {
      // If the rush-price from api/parameters is false ( zero or null ) and the rush-component is hidden so we should not validate
      // just go to the next step
      return hasRushPrice ? !!addons.productionRush : true
    } else {
      return hasRushPrice ? !!addons.rugpad && !!addons.productionRush : !!addons.rugpad
    }
  }

  return !addons.rugpad
}

export const OrderRugStepAddons = () => {
  const { state } = useRugBuilder()
  const { product, customize, activeStep } = state

  const hasRushPrice = !(
    (product?.parameters as WeaveParametersSuccess)?.rush_price === 0 ||
    (product?.parameters as WeaveParametersSuccess)?.rush_price === null
  )

  return (
    <div className="flex flex-col gap-5">
      {(activeStep === OrderRugStepperIndexes.ADDONS || activeStep === OrderRugStepperIndexes.NOTES) && (
        <>
          {customize.type === CustomizeType.WALL_TO_WALL || !product?.rugpadGroup ? null : <RugPad />}
          <Supplies />
          {hasRushPrice && <ProductionRush />}
        </>
      )}
    </div>
  )
}
