import { useRef, useState } from 'react'
import { useRouter } from 'next/router'
import { AxiosError } from 'axios'
import { AtButtonVariant, OrModal, useSessionStorage } from '@curran-catalog/curran-atomic-library'

import { RugBuilderActionTypes } from '@context/rug-builder'
import { useRugBuilder } from '@hooks/use-rug-builder'
import { useRugBuilderTopScroll } from '@hooks/use-rug-builder-top-scroll'
import { useCart } from '@hooks/use-cart'
import { useModal } from '@hooks/use-modal'
import { getSizeValue, OrderPreviewImageCart } from '../order-rug-preview'
import { validateBorder } from './border'
import { validateAddons } from './addons'
import { OrderRugControls } from '../order-rug-controls'
import { NoRugpadOptionID } from './addons-sections'
import { getErrorModalProps } from '@config/modal-error'
import { Unit, WeaveParametersSuccess } from '@services/prices'
import { normalizeAsset, sendMeasureUnitObject, validateCurrentMeasureUnit } from 'utils'
import { formatPrice } from '@utils/price'
import { CornerStyleType, CustomizeType, FurniturePrices, OrderRugStepperIndexes, ProductionRushState } from 'types'
import aa from 'search-insights'
import { environment } from '@config/environment'
import { useFlooringWeave } from '@hooks/use-flooring-weave'
import { sendCartAnalyticsEvent } from '@utils/analytics/events/cart/send-event'
import { CartEventTypes } from '../../../../types/analytics/analytics-data'

export const AddToCartRug = () => {
  const router = useRouter()
  const [previousRoute] = useSessionStorage('x-previousTitleRoute', '')
  const [productIndexId] = useSessionStorage<string | null>('productIndexID', null)

  const { state, dispatch } = useRugBuilder()
  const { state: flooringState } = useFlooringWeave()

  const { algoliaQueryId } = flooringState

  const { subTotalPrice, product, customize, border, addons, notes, discountPrice } = state

  const { addAreaRugToCart, addWallToWallToCart } = useCart()

  const { isModalOpen, setIsModalOpen, modalInfo, handlerClick, isModalErrorOpen, setIsModalErrorOpen } = useModal()

  const previewConfirmRef = useRef<HTMLDivElement>(null)

  useRugBuilderTopScroll({ ref: previewConfirmRef, stepIndex: OrderRugStepperIndexes.PREVIEW_CONFIRM })

  const [isSubmitting, setIsSubmitting] = useState(false)

  const hasRushPrice = !(
    (state.product?.parameters as WeaveParametersSuccess)?.rush_price === 0 ||
    (state.product?.parameters as WeaveParametersSuccess)?.rush_price === null
  )

  const rollWidth =
    !!product && !!product.parameters && (product.parameters as WeaveParametersSuccess)['roll_width']
      ? Number((product.parameters as WeaveParametersSuccess)['roll_width']) ?? 0
      : 0

  const handleErrors = (e: AxiosError) => {
    if (e.response) {
      if (e?.response?.status === 422) {
        handlerClick(undefined, true)
      } else {
        dispatch({
          type: RugBuilderActionTypes.HANDLE_API_ERRORS,
          hasApiErrorPayload: true,
        })
      }
    }
  }

  const hasRequiredFieldFilled = () => {
    switch (customize.type) {
      case CustomizeType.RECTANGULAR:
        if (customize.isMetric) {
          return (
            !!customize.widthCm &&
            !!customize.lengthCm &&
            !!customize.weaveColor &&
            validateBorder(customize, border, product?.borderGroup) &&
            validateAddons(customize.type, addons, product?.rugpadGroup, hasRushPrice)
          )
        }
        return (
          !!customize.widthFt &&
          !!customize.lengthFt &&
          !!customize.weaveColor &&
          validateBorder(customize, border, product?.borderGroup) &&
          validateAddons(customize.type, addons, product?.rugpadGroup, hasRushPrice)
        )

      case CustomizeType.CIRCULAR:
        if (customize.isMetric) {
          return (
            !!customize.diameterCm &&
            !!customize.weaveColor &&
            validateBorder(customize, border, product?.borderGroup) &&
            validateAddons(customize.type, addons, product?.rugpadGroup, hasRushPrice)
          )
        }

        return (
          !!customize.diameterFt &&
          !!customize.weaveColor &&
          validateBorder(customize, border, product?.borderGroup) &&
          validateAddons(customize.type, addons, product?.rugpadGroup, hasRushPrice)
        )

      case CustomizeType.WALL_TO_WALL:
        if (customize.isMetric) {
          return (
            !!validateCurrentMeasureUnit({
              unitMeasure: customize.unitMeasure?.value,
              lm: customize.lm,
              sqm: customize.sqm,
            }) &&
            !!customize.weaveColor &&
            validateBorder(customize, border, product?.borderGroup) &&
            validateAddons(customize.type, addons, product?.rugpadGroup, hasRushPrice) &&
            !!subTotalPrice
          )
        }

        return (
          !!validateCurrentMeasureUnit({
            unitMeasure: customize.unitMeasure?.value,
            sqft: customize.sqft,
            lft: customize.lft,
            sqyd: customize.sqyd,
          }) &&
          !!customize.weaveColor &&
          validateBorder(customize, border, product?.borderGroup) &&
          validateAddons(customize.type, addons, product?.rugpadGroup, hasRushPrice) &&
          !!subTotalPrice
        )

      default:
        return false
    }
  }

  const handleAddAreaRugCart = async () => {
    setIsSubmitting(true)

    aa('convertedObjectIDsAfterSearch', {
      index: environment.algolia.recordsIndex,
      eventName: 'Product Added to Cart',
      objectIDs: [product?.CONTENTFUL_ID ?? ''],
      queryID: algoliaQueryId ?? '',
    })

    if (customize.type === CustomizeType.WALL_TO_WALL) {
      return addWallToWallToCart({
        weaveReference: product?.CONTENTFUL_ID ?? '',
        isWallcovering: false,
        ...(customize.weaveColor?.images &&
          Array.isArray(customize.weaveColor?.images) &&
          customize.weaveColor?.images.length > 0 && {
            imageSrc: normalizeAsset({ asset: customize.weaveColor?.images[0] }).src,
            imageAlt: normalizeAsset({ asset: customize.weaveColor?.images[0] }).alt,
          }),
        color: customize.weaveColor?.weaveColorName ?? '',
        unit: customize.isMetric ? Unit.METRIC : Unit.IMPERIAL,
        ...sendMeasureUnitObject({
          unitMeasure: customize.unitMeasure?.value,
          lft: customize.lft,
          lftIn: customize.lftIn,
          lm: customize.lm,
          sqft: customize.sqft,
          sqm: customize.sqm,
          sqyd: customize.sqyd,
        }),
        productionRush: addons.productionRush?.state === ProductionRushState.YES ? true : false,
        supplies: addons.supplies.map((supply) => {
          const supplyPrice =
            Array.isArray((supply.addonPrices as FurniturePrices).products) &&
            (supply.addonPrices as FurniturePrices).products.length > 0
              ? (supply.addonPrices as FurniturePrices).products[0]
              : undefined
          return { sku: supplyPrice?.sku ?? '', quantity: 1 }
        }),
        quantity: 1,
        metadata: {
          link: window.location.href,
          notes: notes.optionalNotes ?? '',
          contentful_id: product?.CONTENTFUL_ID ?? '',
          category: product?.materialFamily || 'All weaves', // category of item shop by item, shop by type,etc
          category2: 'wall-to-wall', // Choose rug type or carpet
          ...(addons.rugpad?.CONTENTFUL_ID !== NoRugpadOptionID && {
            category4: addons.rugpad?.rugpadName ?? '', // type of rug pad user choose
          }),
          category5: customize.weaveColor?.weaveColorName ?? '', // choose weave color
          brand: product?.vendor?.vendorName ?? '',
          itemListName: previousRoute,
          index: productIndexId,
          query_id: algoliaQueryId ?? '',
        },
      })
        .then((cartRes) => {
          handlerClick({
            customImageComponent: <OrderPreviewImageCart />,
            name: product?.weaveName ?? '',
            price: formatPrice(subTotalPrice - discountPrice),
            quantity: 1,
            extraInfo: [
              { title: 'Type', text: customize.type },
              { title: 'Size', text: getSizeValue({ customize, rollWidth }) },
              { title: 'Weave Color', text: customize.weaveColor?.weaveColorName ?? '' },
              {
                title: 'Supplies',
                text:
                  Array.isArray(addons.supplies) && addons.supplies.length > 0
                    ? addons.supplies.map((supply) => supply.addonName).toString()
                    : '-',
              },
              { title: 'Production Rush', text: addons.productionRush?.state ?? ProductionRushState.NO },
            ],
          })
          sendCartAnalyticsEvent(cartRes, CartEventTypes.ADD_TO_CART)
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.log(e)
          handleErrors(e)
        })
        .finally(() => {
          setIsSubmitting(false)
        })
    }

    return addAreaRugToCart({
      weaveReference: product?.CONTENTFUL_ID ?? '',
      ...(customize.weaveColor?.images &&
        Array.isArray(customize.weaveColor?.images) &&
        customize.weaveColor?.images.length > 0 && {
          imageSrc: normalizeAsset({ asset: customize.weaveColor?.images[0] }).src,
          imageAlt: normalizeAsset({ asset: customize.weaveColor?.images[0] }).alt,
        }),
      color: customize.weaveColor?.weaveColorName ?? '',
      shape: customize.type === CustomizeType.RECTANGULAR ? 'rectangle' : 'circle',
      ...(customize.widthFt && { widthFt: Number(customize.widthFt) }),
      widthIn: Number(customize.widthIn ?? 0),
      ...(customize.widthCm && { widthCm: Number(customize.widthCm) }),
      ...(customize.lengthFt && { lengthFt: Number(customize.lengthFt) }),
      lengthIn: Number(customize.lengthIn ?? 0),
      ...(customize.lengthCm && { lengthCm: Number(customize.lengthCm) }),
      ...(customize.diameterFt && { diameterFt: Number(customize.diameterFt) }),
      diameterIn: Number(customize.diameterIn ?? 0),
      ...(customize.diameterCm && { diameterCm: Number(customize.diameterCm) }),
      borderReference: border.borderMaterial?.CONTENTFUL_ID ?? '',
      borderName: border.borderMaterial?.borderName ?? '',
      borderColor: border.borderColor?.borderColorName ?? '',
      borderCornerStyle: border.cornerStyle?.name ?? '',
      ...(border.borderWidth && { borderWidth: border.borderWidth }),
      unit: customize.isMetric ? Unit.METRIC : Unit.IMPERIAL,
      miteredCorners: border.cornerStyle?.name === CornerStyleType.MITERED && border.cornerStyle?.price ? true : false,
      ...(addons.rugpad?.CONTENTFUL_ID !== NoRugpadOptionID && {
        rugPadReference: addons.rugpad?.CONTENTFUL_ID ?? '',
      }),
      productionRush: addons.productionRush?.state === ProductionRushState.YES ? true : false,
      supplies: addons.supplies.map((supply) => {
        const supplyPrice =
          Array.isArray((supply.addonPrices as FurniturePrices).products) &&
          (supply.addonPrices as FurniturePrices).products.length > 0
            ? (supply.addonPrices as FurniturePrices).products[0]
            : undefined
        return { sku: supplyPrice?.sku ?? '', quantity: 1 }
      }),
      quantity: 1,
      metadata: {
        link: window.location.href,
        notes: notes.optionalNotes ?? '',
        contentful_id: product?.CONTENTFUL_ID ?? '',
        category: product?.materialFamily || 'All weaves', // category of item shop by item, shop by type,etc
        category2: customize.type === CustomizeType.RECTANGULAR ? 'rectangle' : 'circle', // Choose rug type or carpet
        category3: border.borderMaterial?.borderName ?? '', // choose border material
        ...(addons.rugpad?.CONTENTFUL_ID !== NoRugpadOptionID && {
          category4: addons.rugpad?.rugpadName ?? '', // type of rug pad user choose
        }),
        category5: customize.weaveColor?.weaveColorName ?? '', // choose weave color
        brand: product?.vendor?.vendorName ?? '',
        itemListName: previousRoute,
        index: productIndexId,
        query_id: algoliaQueryId ?? '',
      },
    })
      .then((cartRes) => {
        handlerClick({
          customImageComponent: <OrderPreviewImageCart />,
          name: product?.weaveName ?? '',
          price: formatPrice(subTotalPrice - discountPrice),
          quantity: 1,
          extraInfo: [
            { title: 'Type', text: customize.type },
            { title: 'Size', text: getSizeValue({ customize, rollWidth }) },
            {
              title: 'Weave Color',
              text:
                `${customize.weaveColor?.weaveColorName} ${
                  customize.weaveColor?.subheaderRugBuilder ? `(${customize.weaveColor.subheaderRugBuilder})` : ''
                }` ?? '',
            },
            { title: 'Border Material', text: border.borderMaterial?.borderName ?? '' },
            { title: 'Border Color', text: border.borderColor?.borderColorName ?? '-' },
            { title: 'Border Width', text: border.borderWidth ? `${border.borderWidth}''` : '-' },
            { title: 'Corner Style', text: border.cornerStyle?.name ?? 'N/A' },
            { title: 'Rugpad', text: addons.rugpad?.rugpadName ?? '-' },
            {
              title: 'Supplies',
              text:
                Array.isArray(addons.supplies) && addons.supplies.length > 0
                  ? addons.supplies.map((supply) => supply.addonName).toString()
                  : '-',
            },
            { title: 'Production Rush', text: addons.productionRush?.state ?? ProductionRushState.NO },
          ],
        })
        sendCartAnalyticsEvent(cartRes, CartEventTypes.ADD_TO_CART)
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e)
        handleErrors(e)
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  const errorModalProps = getErrorModalProps(
    () => {
      setIsModalErrorOpen(false)
      router.push('/customer-service')
    },
    () => {
      setIsModalErrorOpen(false)
    },
  )

  return (
    <div className="flex flex-col md:flex-row w-full xl:!w-fit">
      <OrderRugControls
        subtotal={subTotalPrice}
        handleNextStep={handleAddAreaRugCart}
        isPrevStepEnabled={(hasRequiredFieldFilled() ?? false) && !isSubmitting}
        isNextStepEnabled={(hasRequiredFieldFilled() ?? false) && !isSubmitting}
        isSubmitting={isSubmitting}
        nextStepLabel={isSubmitting ? 'Loading' : 'Add to cart'}
        buttonsBetween
        className="!p-0"
      />

      {/*Cart Modal*/}
      <OrModal
        productLabel="Weave: "
        productContent={modalInfo?.productContent}
        isOpen={isModalOpen}
        icon="circle-check"
        title="Added to Shopping Cart"
        leftButton={{
          label: 'Continue Shopping',
          onClick: () => {
            setIsModalOpen(false), window.location.reload()
          },
          variant: AtButtonVariant.TERTIARY,
        }}
        rightButton={{
          label: 'View Cart',
          onClick: () => {
            setIsModalOpen(false)
            router.push('/cart')
          },
        }}
        handleCloseModal={() => setIsModalOpen(false)}
      />
      {/* Error modal */}
      <OrModal
        isOpen={isModalErrorOpen}
        title="Error"
        description={errorModalProps.description}
        leftButton={errorModalProps.leftButton}
        rightButton={errorModalProps.rightButton}
        handleCloseModal={() => setIsModalErrorOpen(false)}
      />
    </div>
  )
}
