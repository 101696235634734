import { useCallback, useEffect, useState } from 'react'
import { AtButton, AtButtonVariant, AtIcon, AtImage, MlFormField, OrModal } from '@curran-catalog/curran-atomic-library'

import { RugBuilderActionTypes } from '@context/rug-builder'
import { useRugBuilder } from '@hooks/use-rug-builder'

interface BorderWidthProps {
  isPreviewConfirm?: boolean
}

export const BorderWidth = ({ isPreviewConfirm = false }: BorderWidthProps) => {
  const { state, dispatch } = useRugBuilder()
  const { areaPrices, border, product } = state
  const { borderWidth, borderMaterial } = border

  const [checked, setChecked] = useState(false)
  const [isOpenModal, setIsOpenModal] = useState(false)

  useEffect(() => {
    if (borderMaterial && borderMaterial.widths && !borderWidth) {
      dispatch({
        type: RugBuilderActionTypes.HANDLE_BORDER_IS_COMPLETE,
        isCompletePayload: true,
      })

      dispatch({
        type: RugBuilderActionTypes.SET_BORDER_WIDTH,
        borderWidthPayload: Number(borderMaterial.widths[0]),
      })
      setChecked(true)
    }
  }, [borderMaterial, borderWidth, dispatch])

  useEffect(() => {
    if (checked && borderMaterial) {
      const borderMaterialPrice =
        areaPrices && product && product.CONTENTFUL_ID === areaPrices.reference && borderWidth
          ? areaPrices?.borders?.find(
              (border) => border?.reference === borderMaterial.CONTENTFUL_ID && border.width === String(borderWidth),
            ) ?? undefined
          : undefined

      dispatch({
        type: RugBuilderActionTypes.SET_BORDER_MATERIAL,
        borderMaterialPayload: { ...borderMaterial, borderMaterialPrice },
      })
      dispatch({
        type: RugBuilderActionTypes.SET_SUB_TOTAL_PRICE,
      })
      setChecked(false)
    }
  }, [areaPrices, borderMaterial, borderWidth, checked, dispatch, product])

  const handleChange = useCallback(
    (borderWidth: number) => {
      dispatch({
        type: RugBuilderActionTypes.HANDLE_BORDER_IS_COMPLETE,
        isCompletePayload: true,
      })

      dispatch({
        type: RugBuilderActionTypes.SET_BORDER_WIDTH,
        borderWidthPayload: borderWidth,
      })

      setChecked(true)
    },
    [dispatch],
  )

  const hasBorderColors =
    !!border.borderMaterial &&
    !!border.borderMaterial.borderColorGroup &&
    Array.isArray(border.borderMaterial.borderColorGroup.borderColors) &&
    border.borderMaterial.borderColorGroup.borderColors.length > 0

  return hasBorderColors &&
    border.borderMaterial &&
    Array.isArray(border.borderMaterial.widths) &&
    border.borderMaterial.widths.length > 0 ? (
    <div className="pb-2 flex flex-col gap-6">
      <OrModal isOpen={isOpenModal} title="Border Width" handleCloseModal={() => setIsOpenModal(false)}>
        <div className="mt-4 w-full gap-4 flex flex-col">
          <div className="flex flex-row gap-x-4">
            <div className="w-1/2">
              <AtImage src="/images/flooring/border_15.jpg" alt="border with 1.5 width" />
            </div>

            <div className="w-1/2">
              <AtImage src="/images/flooring/border_20.jpg" alt="border with 2.0 width" />
            </div>
          </div>
          <div className="px-2">
            <ul className="list-disc">
              <li>The border width refers to the visible width of your selected cloth border.</li>

              <li>
                The border material is stitched onto the face of the rug and does not change the overall dimensions of
                the rug.
              </li>
              <li>The larger the border width, the more visibly prominent the binding will be on the rug.</li>
            </ul>
          </div>
          <AtButton
            label="Close"
            className="w-full"
            variant={AtButtonVariant.SECONDARY}
            onClick={() => setIsOpenModal(false)}
          />
        </div>
      </OrModal>
      <div className="inline-flex items-center gap-x-2">
        <h3 className={isPreviewConfirm ? 'uppercase text-sm' : 'text-lg font-medium'}>Choose Border Width</h3>
        <button type="button" onClick={() => setIsOpenModal(true)}>
          <AtIcon type="info" color="link" />
        </button>
      </div>

      <div className="flex flex-col md:flex-row gap-6 ">
        {border.borderMaterial.widths.map((width, index) => (
          <MlFormField
            key={`border-width-${width}-${index}`}
            name={`border-width-${width}-${index}`}
            label={`${width}"`}
            disabled={!border.borderColor}
            checked={Number(width) === border.borderWidth || (index == 0 && !borderWidth)}
            type="radio"
            onChange={() => handleChange(Number(width))}
          />
        ))}
      </div>
      {!isPreviewConfirm && <p className="text-xs">The border width will not change the dimensions of your rug.</p>}

      <hr className="border-outlined-gray" />
    </div>
  ) : null
}
